import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import ButtonAceptar from "../../../../components/ait-reusable/Button/ButtonAceptar";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "600px",
      maxWidth: "600px",
    },
  },
  paddingTextField: {
    paddingTop: "0.3em",
  },
  paddingButton: {
    paddingTop: "1em",
  },
  containerGrid: {
    maxHeight: 250,
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

export default function ModalObservacionarticulo({
  open,
  obsArticulo,
  handleClose,
  onSubmitObservaciones,
}) {
  const [observaciones, setObservaciones] = useState([]);
  const [observacion, setObservacion] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    obsArticulo && setObservaciones(obsArticulo);
  }, [obsArticulo]);

  const classes = useStyles();

  const handleAddObservacion = () => {
    observacion !== "" && setObservaciones([observacion, ...observaciones]);
    setObservacion("");
  };

  const handleDeleteObservacion = async (item) =>
    setObservaciones(observaciones.filter((obs) => obs !== item));

  const onSubmit = async () => {
    setLoading(true);
    await onSubmitObservaciones(observaciones);
    setLoading(false);
  };

  const handleOnchange = (e) => {
    let value = e.target.value;

    if (value.includes("&")) {
      return null;
    }
    setObservacion(value);
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Agregar observaciones</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container style={{ maxHeight: "8rem", overflowY: "scroll" }}>
          {observaciones.length ? (
            observaciones.map((item) => (
              <>
                <Grid key={item} item xs={10} style={{ paddingTop: "1em" }}>
                  <Typography>
                    {item.substr(0, 50).trim() +
                      (item.length > 50 ? "..." : "")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => handleDeleteObservacion(item)}
                    title="Eliminar observación"
                  >
                    <SvgIcon>
                      <DeleteIcon fontSize="small" color="error" />
                    </SvgIcon>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            ))
          ) : (
            <Grid container fullWidth>
              <Grid item xs={12}>
                <DialogTitle style={{ textAlign: "center" }}>
                  El artículo no posee observaciones
                </DialogTitle>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ paddingTop: "2em" }}>
            <Typography color="textSecondary" style={{ fontSize: 14 }}>
              Para agregar múltiples códigos en una misma <br /> observación,
              separelas por ";". Por ej: "ED-34; ED-35..."
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.paddingTextField}>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={2}
              placeholder="Observación"
              name="observacion"
              autoComplete="off"
              inputProps={{ maxLength: 300 }}
              value={observacion}
              onChange={(e) => handleOnchange(e)}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={12} className={classes.paddingButton}>
            <ButtonGeneral
              click={handleAddObservacion}
              fullwidth={true}
              message={"Agregar observación"}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
        <Box pt={1} pb={1}>
          <Divider />
        </Box>

        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            style={{
              outline: "none",
              backgroundColor: "#c4183c",
              color: "white",
            }}
            onClick={handleClose}
            disabled={isLoading}
          >
            CANCELAR
          </Button>
          <ButtonAceptar
            message="ACEPTAR"
            click={onSubmit}
            disabled={isLoading}
          />
        </CardActions>
      </DialogContent>
    </Dialog>
  );
}
