import React from "react";
import { Box, Checkbox, DialogContentText } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import MenuB from "../../../components/ait-reusable/Menu/MenuB";
import moment from "moment";
import { recrearCompraRapida } from "../../../Redux/Actions/compraRapidaActions";
import { recrearCompra } from "../../../Redux/Actions/compraActions";
import { useHistory } from "react-router-dom";
import { parseCurrency } from "../../../utils/parsers";
import { useDispatch } from "react-redux";
import { AlertReusable } from "../../../components/ait-reusable/Alerts/AlertReusable";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

const isChecked = (factura, selection) => {
  let exist = selection.find((x) => x.idSelection === factura.idSelection);
  if (exist) {
    return true;
  } else {
    return false;
  }
};

const isDisabledCheck = (factura, selection, proveedor) => {
  let value = false;
  if (selection.length > 0) {
    if (
      factura.tipo_factura &&
      (factura.tipo_factura.nombre === "A" ||
        factura.tipo_factura.nombre === "M")
    ) {
      if (
        selection.some(
          (s) =>
            !s.tipo_factura ||
            (s.tipo_factura &&
              (s.tipo_factura.nombre === "B" || s.tipo_factura.nombre === "C")),
        )
      ) {
        value = true;
      }
    } else {
      if (
        selection.some(
          (s) =>
            s.tipo_factura &&
            (s.tipo_factura.nombre === "A" || s.tipo_factura.nombre === "M"),
        )
      ) {
        value = true;
      }
    }
  }

  return value;
};

export function formatFacturas(
  facturas,
  selection,
  setSelection,
  toggleModalDetalles,
  toggleModalAnulacion,
  handleGetExcelCompra,
  proveedor,
  moduloComprasActivo,
  setNewFacturas,
  handleSelectOne,
) {
  return facturas.map((factura) => ({
    nombre: (
      <Checkbox
        color="primary"
        checked={isChecked(factura, selection)}
        onChange={() =>
          handleSelectOne(
            factura,
            facturas,
            selection,
            setSelection,
            setNewFacturas,
          )
        }
        className="fontBold"
        disabled={
          (factura.isPlazoPago && factura.disabled) ||
          (proveedor && proveedor.calcular_retencion
            ? isDisabledCheck(factura, selection, proveedor)
            : false)
        }
      />
    ),
    tipoDeComprobante: factura.isPlazoPago
      ? `${factura.indexPlazo}° plazo (${factura.plazo.dias} días)`
      : factura.tipo_comprobante.nombre +
        (factura.tipo_factura
          ? ` ${factura.tipo_factura.nombre}`
          : factura.tipo_comprobante.nombre === "Notas de Crédito"
            ? " X"
            : ""),
    numeroDeCompra: factura.formatted_number,
    fecha: moment(factura.fecha_facturacion).format("DD/MM/YYYY"),
    CantidadDias: factura.isPlazoPago ? factura.plazo.days : factura.days,
    totalDeCompra: (
      <span style={{ color: "black" }}>
        {parseCurrency(
          parseFloat(
            factura.isPlazoPago
              ? factura.plazo.monto
              : getTotalComprobante(factura).toFixed(2),
          ),
        )}
      </span>
    ),
    deudaRestante: (
      <span style={{ color: "red" }}>
        {factura.isPlazoPago
          ? parseCurrency(factura.plazo.monto)
          : factura.tipo_comprobante.nombre === "Notas de Crédito"
            ? "---"
            : factura.deuda_restante
              ? parseCurrency(factura.deuda_restante)
              : null}
      </span>
    ),
    saldoAbonado: (
      <span style={{ color: "green" }}>
        {factura.isPlazoPago
          ? parseCurrency(0)
          : factura.tipo_comprobante.nombre === "Notas de Crédito"
            ? "---"
            : parseCurrency(factura.saldo_abonado)}
      </span>
    ),
    opciones: factura.isPlazoPago
      ? "---"
      : ActionTable(
          factura,
          toggleModalDetalles,
          toggleModalAnulacion,
          handleGetExcelCompra,
          moduloComprasActivo,
        ),
  }));
}

const getTotalComprobante = (factura) => {
  let total =
    factura.tipo_comprobante.nombre === "Notas de Crédito"
      ? Number(factura.monto_total) * -1
      : Number(factura.monto_total);

  return total;
};

function ActionTable(
  factura,
  toggleModalDetalles,
  toggleModalAnulacion,
  handleGetExcelCompra,
  moduloComprasActivo,
) {
  const history = useHistory();
  const dispatch = useDispatch();
  const estaVencida =
    !factura.pagada && moment(factura.fecha_vencimiento) < moment(new Date());

  const handleRecrearCompra = () => {
    const esCompraRapida = factura.detalles.some(
      (d) => Number(d.precio_unitario) === 0,
    );

    if (esCompraRapida) {
      dispatch(recrearCompraRapida(factura));
      history.push("/compras/rapida/");
    } else {
      dispatch(recrearCompra(factura));
      history.push("/compras/normal/");
    }
  };

  const hanldeGenerarNoteCredito = () => {
    const esCompraRapida = factura.detalles.some(
      (d) => Number(d.precio_unitario) === 0,
    );
    if (esCompraRapida) {
      dispatch(recrearCompraRapida(factura, true));
      history.push("/compras/rapida/");
    } else {
      dispatch(recrearCompra(factura, true));
      history.push("/compras/normal/");
    }
  };

  return (
    <MenuB>
      <MenuItem onClick={() => toggleModalDetalles(factura)}>
        Ver detalle
      </MenuItem>
      {!factura.fecha_anulacion && (
        <MenuItem onClick={() => toggleModalAnulacion(factura)}>
          {factura.tipo_comprobante.nombre.includes("Notas de Crédito")
            ? "Anular Nota de Crédito"
            : "Anular compra"}
        </MenuItem>
      )}
      <MenuItem onClick={() => handleGetExcelCompra(factura)}>
        Descargar Excel
      </MenuItem>
      {(factura.fecha_anulacion || estaVencida) && moduloComprasActivo && (
        <MenuItem onClick={() => handleRecrearCompra(factura)}>
          Recrear compra
        </MenuItem>
      )}
      {!factura.tipo_comprobante.nombre.includes("Notas de Crédito") &&
        !factura.fecha_anulacion &&
        moduloComprasActivo && (
          <MenuItem onClick={() => hanldeGenerarNoteCredito(factura)}>
            Generar nota de crédito
          </MenuItem>
        )}
    </MenuB>
  );
}

const date = moment(new Date()).format("YYYY-MM-DD");

export const initialValues = (modalidadCheques) => ({
  observacion: "",
  responsable: null,
  descuento: "",
  montoTotal: "",
  sobrante: "",
  medios: [
    {
      monto: "",
      nroLote: "",
      nroAut: "",
      motivo: "",
      medioSelected: 2,
      tarjetaSelected: null,
      coeficienteSelected: null,
      referenciaTarjeta: null,
      porcentajeSelected: 0,
      nroCheque: null,
      tipoCheque: 1,
      modalidadCheque: modalidadCheques.length && modalidadCheques[0].id,
      referenciaCheque: null,
      fechaCobroCheque: date,
      fechaEmisionCheque: date,
      fechaVencimientoCheque: date,
      propioCheque: false,
      bancoTransf: null,
      fecha_transferencia: null,
      referenciaTransf: null,
    },
  ],
  fechaReal: moment(new Date())
    .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
    .format("YYYY-MM-DD HH:mm"),
});

const getIds = (selection) => {
  const arrayDeIds = selection.map((compra) => compra.id);
  return arrayDeIds;
};

const mediosPago = (medios) => {
  const arrayDeIds = medios.map((compra) => {
    return {
      id_medio_pago: compra.medioSelected,
      monto_medio_pago: Number(compra.monto),
      coeficiente: {
        id_coeficiente_interes:
          compra.medioSelected === 1 || compra.medioSelected === 3
            ? compra.coeficienteSelected
            : null,
        nroLote:
          compra.medioSelected === 1 || compra.medioSelected === 3
            ? compra.nroLote
            : null,
        referencia:
          compra.medioSelected === 1 || compra.medioSelected === 3
            ? compra.referenciaTarjeta
            : null,
        nroAut:
          compra.medioSelected === 1 || compra.medioSelected === 3
            ? compra.nroAut
            : null,
      },
      transferencia: {
        banco: compra.medioSelected === 7 ? compra.bancoTransf : null,
        referencia: compra.medioSelected === 7 ? compra.referenciaTransf : null,
        fecha_transferencia:
          compra.medioSelected === 7 ? compra.fecha_transferencia : null,
      },
      cheque: {
        numero_cheque: compra.medioSelected === 6 ? compra.nroCheque : null,
        tipo: compra.medioSelected === 6 ? compra.tipoCheque : null,
        emision: compra.medioSelected === 6 ? compra.fechaEmisionCheque : null,
        vencimiento:
          compra.medioSelected === 6 ? compra.fechaVencimientoCheque : null,
        cobro: compra.medioSelected === 6 ? compra.fechaCobroCheque : null,
        monto:
          compra.medioSelected === 6 ? Number(compra.monto).toFixed(2) : null,
        referencia: compra.medioSelected === 6 ? compra.referenciaCheque : null,
        propio: compra.medioSelected === 6 ? compra.propioCheque : false,
        modalidad: compra.medioSelected === 6 ? compra.modalidadCheque : null,
      },
    };
  });

  return arrayDeIds;
};

export function formatDataParaBack(
  compra,
  descuento,
  responsable,
  montoTotal,
  newPrecioTotal,
  proveedor,
  selection,
  precioTotal,
  retencion,
) {
  let pago = {
    compras_id: getIds(selection),
    medio_pago: mediosPago(compra.medios),
    proveedor: proveedor.idProveedor,
    responsable: responsable,
    observacion: compra.observacion,
    descuento:
      descuento === "" ? 0 : Number(Number(descuento / 100).toFixed(3)),
    monto_neto: descuento === "" ? null : precioTotal.toFixed(2),
    monto_pago: montoTotal.toFixed(2),
    es_parcial:
      Number(Number(montoTotal).toFixed(2)) <
      Number(Number(newPrecioTotal).toFixed(2))
        ? true
        : false,
    sobrante:
      Number(montoTotal) > newPrecioTotal
        ? (Number(montoTotal) - newPrecioTotal).toFixed(2)
        : null,
    retencion: retencion.active
      ? [
          {
            monto_retencion: retencion.monto,
            observacion: retencion.observacion,
          },
        ]
      : null,
    fecha_real_pago: moment(compra.fechaReal).format("YYYY-MM-DD HH:mm"),
  };
  return pago;
}

const handleGetLabelCompra = (compra) => {
  if (!compra.tipo_factura)
    return `${compra.tipo_comprobante.nombre}: ${compra.punto_venta
      .toString()
      .padStart(4, "0")}-${compra.nro_factura.toString().padStart(8, "0")}`;

  return `${compra.tipo_comprobante.nombre} ${
    compra.tipo_factura.nombre
  }: ${compra.punto_venta
    .toString()
    .padStart(4, "0")}-${compra.nro_factura.toString().padStart(8, "0")}`;
};

export const ContentModalAnulacion = ({ compraSelected }) => {
  return (
    <>
      {((compraSelected.nc && compraSelected.nc.asociadas.length !== 0) ||
        compraSelected.pagos_parcial.length !== 0) && (
        <Box pb={2}>
          <AlertReusable
            severity={"error"}
            text={
              "No se puede anular la compra, primero debe anular la/s NC asociada/s y los pagos asociados"
            }
          />
        </Box>
      )}

      {!compraSelected.nc && compraSelected.pago_de_nc.length !== 0 && (
        <Box pb={2}>
          <AlertReusable
            severity={"error"}
            text={
              "No se puede anular la Nota de crédito, primero debe anular los pagos asociados"
            }
          />
        </Box>
      )}

      <Box>
        ¿Está seguro que desea anular la compra nº
        {compraSelected.formatted_number} al proveedor{" "}
        {compraSelected.proveedor} realizada el{" "}
        {moment(compraSelected.fecha_facturacion).format("DD/MM/YY")}?
      </Box>

      {!compraSelected.tipo_comprobante.nombre.includes("Notas de Crédito") &&
        compraSelected.nc &&
        compraSelected.nc.asociadas.length !== 0 && (
          <React.Fragment>
            <DialogContentText styles={{ marginBottom: 16 }}>
              Esta compra tiene las siguientes notas de crédito asociadas:
            </DialogContentText>
            {compraSelected.nc.asociadas.map((c, index) => (
              <DialogContentText
                key={index}
                style={{ paddingLeft: 16, fontWeight: "bold" }}
              >
                - {handleGetLabelCompra(c)}
              </DialogContentText>
            ))}
          </React.Fragment>
        )}
      {compraSelected.pagos_parcial.length !== 0 && (
        <>
          <DialogContentText styles={{ marginBottom: 16 }}>
            Esta compra tiene los siguientes pagos asociados:
          </DialogContentText>
          {compraSelected.pagos_parcial.pagos.map((p, i) => (
            <DialogContentText
              key={i}
              style={{ paddingLeft: 16, fontWeight: "bold" }}
            >
              - Pago nro. {p.id_pagocompra}
            </DialogContentText>
          ))}
        </>
      )}

      {compraSelected.tipo_comprobante.nombre.includes("Notas de Crédito") &&
        compraSelected.pago_de_nc.length !== 0 && (
          <React.Fragment>
            <DialogContentText styles={{ marginBottom: 16 }}>
              Esta nota de crédito tiene los siguientes pagos asociados:
            </DialogContentText>
            {compraSelected.pago_de_nc.pagos.map((p, index) => (
              <DialogContentText
                key={index}
                style={{ paddingLeft: 16, fontWeight: "bold" }}
              >
                - Pago nro. {p.id_pagocompra}
              </DialogContentText>
            ))}
          </React.Fragment>
        )}
    </>
  );
};

/**
 * Comprueba si todos los elementos seleccionados y las facturas en la lista son del mismo tipo.
 *
 * @param {Array} selection - Lista de elementos seleccionados.
 * @param {Array} facturas - Lista de facturas.
 * @returns {boolean} - `true` si todos son del mismo tipo, `false` en caso contrario.
 */
export const areAllSameType = (selection, facturas, tieneRetencion) => {
  // Verificación de Facturas Vacías
  if (facturas.length === 0) {
    return false; // Si no hay facturas, el checkbox estará deshabilitado
  }

  // Obtención del Primer Comprobante y Seleccionado
  const primerComprobante = facturas[0];
  const primerSelected = selection[0];
  if (tieneRetencion) {
    // Condicionales basados en el Tipo de Factura o Comprobante Interno
    if (
      primerComprobante.tipo_factura &&
      (primerComprobante.tipo_factura.nombre === "A" ||
        primerComprobante.tipo_factura.nombre === "M")
    ) {
      // Verificación específica para Facturas A y M
      return (
        facturas.every(
          (x) =>
            x.tipo_factura &&
            (x.tipo_factura.nombre === "A" || x.tipo_factura.nombre === "M") &&
            (!primerSelected ||
              (primerSelected.tipo_factura &&
                (primerSelected.tipo_factura.nombre === "A" ||
                  primerSelected.tipo_factura.nombre === "M"))),
        ) &&
        (!primerSelected ||
          (primerSelected.tipo_factura &&
            (primerSelected.tipo_factura.nombre === "A" ||
              primerSelected.tipo_factura.nombre === "M")))
      );
    } else if (
      primerComprobante.tipo_factura &&
      (primerComprobante.tipo_factura.nombre === "B" ||
        primerComprobante.tipo_factura.nombre === "C")
    ) {
      // Verificación específica para Facturas B y C
      return (
        facturas.every(
          (x) =>
            x.tipo_factura &&
            (x.tipo_factura.nombre === "B" || x.tipo_factura.nombre === "C") &&
            (!primerSelected ||
              (primerSelected.tipo_factura &&
                (primerSelected.tipo_factura.nombre === "B" ||
                  primerSelected.tipo_factura.nombre === "C"))),
        ) &&
        (!primerSelected ||
          (primerSelected.tipo_factura &&
            (primerSelected.tipo_factura.nombre === "B" ||
              primerSelected.tipo_factura.nombre === "C")))
      );
    } else if (
      primerComprobante &&
      primerComprobante.tipo_comprobante &&
      !primerComprobante.tipo_factura
    ) {
      // Verificación específica para Comprobantes Internos
      return (
        facturas.every(
          (x) =>
            x.tipo_comprobante &&
            !x.tipo_factura &&
            (!primerSelected ||
              (primerSelected.tipo_comprobante &&
                !primerSelected.tipo_factura)),
        ) &&
        (!primerSelected ||
          (primerSelected.tipo_comprobante && !primerSelected.tipo_factura))
      );
    } else {
      return false;
    }
  } else {
    return true;
  }
};
