import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { AlertReusable } from "../Alerts/AlertReusable";
import ReusableTable from "../Report/ReusableTable";
import useConversionNumeroMiles from "../../../customHooks/useConversionNumeroMiles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
}));

export default function Content({
  plazos,
  setPlazosPago,
  sumaPagos,
  diferencia,
  total,
  loading,
  descripcion,
  setDescripcion,
  plazosSelected,
  setPlazosSelected,
}) {
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const columns = ["Plazo", "Monto de pago", "Quitar"];
  const { conversionNumero } = useConversionNumeroMiles();
  const classes = useStyles();

  const handleChangeMonto = (index, value) => {
    let newValue = conversionNumero(value);
    const newPlazos = [...plazosSelected];
    newPlazos[index].monto = newValue;
    setPlazosSelected(newPlazos);
  };

  const handleDeletePlazoSelected = (plazo) => {
    let copyPlazos = [...plazosSelected];
    let index = copyPlazos.findIndex((p) => p.id === plazo.id);
    copyPlazos.splice(index, 1);
    setPlazosSelected(copyPlazos);
  };

  const formatPlazos = () => {
    return plazosSelected.map((plazo, index) => {
      return {
        plazo: `${plazo.dias} días`,
        monto: (
          <Grid containser spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                size="small"
                style={{ backgroundColor: "white" }}
                onWheel={(e) => e.target.blur()}
                id="monto"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={plazo.monto}
                onChange={(e) => handleChangeMonto(index, e.target.value)}
                variant="outlined"
                placeholder="$0.00"
              />
            </Grid>
          </Grid>
        ),
        delete: (
          <DeleteIcon
            style={{ cursor: "pointer", color: "rgb(196, 24, 60)" }}
            onClick={() => handleDeletePlazoSelected(plazo)}
          />
        ),
      };
    });
  };

  const handleChangePlazos = (value) => {
    setAutocompleteValue(value);
    let copyPlazos = [...plazosSelected];
    value.monto = diferencia.toLocaleString("es-AR");
    copyPlazos.push(value);
    setPlazosSelected(copyPlazos);
    setDescripcion("");
    setAutocompleteValue(null);
  };

  const filterPlazosSelected = () => {
    const idSelected = plazosSelected.map((p) => p.id);
    return plazos.filter((p) => !idSelected.includes(p.id));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AlertReusable
          severity={"info"}
          text={
            "Dividí el monto total de tu compra en distintos plazos. Seleccioná cuánto querés pagar en cada plazo y asegurate de que la suma coincida con el total de la compra."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          id="autocomplete-plazos-pago"
          fullWidth
          autoHighlight
          loading={loading}
          options={filterPlazosSelected()}
          value={autocompleteValue}
          renderOption={(plazo) => `Plazo de ${plazo.dias} días`}
          getOptionLabel={(plazo) => `Plazo de ${plazo.dias} días`}
          inputValue={descripcion}
          onFocus={(event) => event.target.select()}
          noOptionsText={"No se encontraron resultados"}
          onInputChange={(event, value, reason) => setDescripcion(value)}
          name="plazosPago"
          onChange={(event, value) => {
            handleChangePlazos(value);
          }}
          disabled={false}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              size="small"
              className={classes.root}
              label="Búsqueda de plazos de pago por días"
              name="plazosPago"
              placeholder="Días de plazo"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ReusableTable
          columns={columns}
          items={formatPlazos()}
          loading={false}
          noResults={"Seleccioná uno o más plazos de pago"}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{ textAlign: "end", fontWeight: "bold" }}
              variant="h6"
            >{`Monto total de compra: $${parseFloat(total).toLocaleString("es-AR")}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ textAlign: "end", fontWeight: "bold" }}
              variant="h6"
            >{`Monto total de pagos: $${parseFloat(sumaPagos).toLocaleString("es-AR")}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ textAlign: "end", fontWeight: "bold" }}
              variant="h6"
            >{`Monto pendiente de pago: $${parseFloat(diferencia > 0 ? diferencia.toFixed(2) : 0).toLocaleString("es-AR")}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
