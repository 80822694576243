import React from "react";
import { LineA4Color } from "../../Components/raya";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";
import FooterA4 from "../../Components/FooterA4";
import { parseCurrency } from "../../../../../utils/parsers";
import { textAbbreviation } from "../../DeVentas/Components/utils";

export default function ResumenA4({ data, tipo, config }) {
  const tipoComprobante = data.tipoComprobante
    ? data.tipoComprobante.nombre
    : "";

  const getPorcentajeDescuento = () => {
    if (data.descuento) {
      return " (" + data.descuento.porcentaje * 100 + "%)";
    }
    return "";
  };

  return (
    <>
      <View
        style={
          tipo === "presupuestoA4"
            ? {
                position: "absolute",
                left: 0,
                right: 0,
                bottom: !config.ocultar_datos_ci ? 150 : 80,
                paddingRight: 20,
                color: "#1B71BA",
              }
            : { paddingRight: 20, color: "#1B71BA", marginBottom: 15 }
        }
      >
        <View style={styles.containerRowTableEnd}>
          <View style={(styles.containerColumn, { width: 200 })}>
            <Text style={styles.textTotalesFactura}>SUBTOTAL</Text>

            <View>
              <Text style={styles.textTotalesFactura}>
                {"DESCUENTO" + getPorcentajeDescuento()}
              </Text>

              <Text style={styles.textTotalesFactura}>{"RECARGO"}</Text>

              {(tipoComprobante === "A" ||
                tipoComprobante === "M" ||
                tipoComprobante === "FCEM A") && (
                <Text style={styles.textTotalesFactura}>{`NETO GRAVADO`}</Text>
              )}
              {(tipoComprobante === "A" ||
                tipoComprobante === "M" ||
                tipoComprobante === "FCEM A") && (
                <Text style={styles.textTotalesFactura}>{`IVA 21 %`}</Text>
              )}
              {data.impuestos_aplicados &&
                (tipoComprobante === "A" ||
                  tipoComprobante === "M" ||
                  tipoComprobante === "FCEM A") &&
                data.impuestos_aplicados.map((tax, index) => (
                  <Text key={index} style={styles.textTotalesFactura}>
                    {`${textAbbreviation(
                      tax.description,
                    ).toUpperCase()} - ${Number(tax.aliquot_percentage)}%`}
                  </Text>
                ))}
            </View>

            <Text style={styles.textTotalesFactura}>TOTAL</Text>
          </View>
          <View style={(styles.containerColumn, { width: 70 })}>
            <View>
              <Text style={styles.textTotalesFactura}>
                {parseCurrency(data.montoImporte)}
              </Text>

              <Text style={styles.textTotalesFactura}>
                {parseCurrency(
                  data.descuento && data.montoDescuento > 0
                    ? data.montoDescuento
                    : 0,
                )}
              </Text>

              <Text style={styles.textTotalesFactura}>
                {parseCurrency(data.montoInteres > 0 ? data.montoInteres : 0)}
              </Text>

              {(tipoComprobante === "A" ||
                tipoComprobante === "M" ||
                tipoComprobante === "FCEM A") && (
                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(data.montoSubtotal)}
                </Text>
              )}

              {(tipoComprobante === "A" ||
                tipoComprobante === "M" ||
                tipoComprobante === "FCEM A") && (
                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(data.montoIVA)}
                </Text>
              )}
              {data.impuestos_aplicados &&
                (tipoComprobante === "A" ||
                  tipoComprobante === "M" ||
                  tipoComprobante === "FCEM A") &&
                data.impuestos_aplicados.map((tax, index) => (
                  <Text key={index} style={styles.textTotalesFactura}>
                    {parseCurrency(tax.amount)}
                  </Text>
                ))}
            </View>

            <Text style={styles.textTotalesFactura}>
              {parseCurrency(data.montoTotal)}
            </Text>
          </View>
        </View>
      </View>
      {!config.ocultar_datos_ci ? (
        tipo === "presupuestoA4" ? (
          <View
            style={{
              position: "absolute",
              bottom: 100,
              paddingLeft: 20,
              paddingRight: 20,
              left: 0,
              right: 0,
            }}
            fixed
          >
            <LineA4Color />
          </View>
        ) : (
          <View
            style={{
              paddingTop: 5,
            }}
            fixed
          >
            <LineA4Color />
          </View>
        )
      ) : null}
      {!config.ocultar_datos_ci && (
        <FooterA4
          fixed={tipo === "presupuestoA4" ? true : false}
          sucursal={data.sucursal}
          venta={data.venta}
        />
      )}
    </>
  );
}
