import { Box, CircularProgress, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useRetrieveNotaDebitoComprobanteAsociado } from "../../../../services/notaDebito";
import ReportTable from "../../Report/ReportTable";
import { formatoMoneda } from "../../../../utils/parsers";
import InformacionMediosDePagoFacturaAsociada from "./InformacionMediosDePagoFacturaAsociada";
const columns = ["Cod.TEST", "Detalle", "Cantidad", "Subtotal"];
const formatDetalleVenta = (items) =>
  items.map((item) => ({
    cod: item.repuestoSucursal
      ? item.repuestoSucursal.repuestoProveedor.codProveedor
      : "---",
    detalle: (
      <Box>
        <p style={{ margin: 0 }}>
          {`${item.descripcion ? item.descripcion : "---"}`}
        </p>
        {item.descripcionPersonalizada ? (
          <p style={{ margin: 0, fontSize: 12, fontWeight: "bold" }}>
            {item.descripcionPersonalizada}
          </p>
        ) : null}
      </Box>
    ),
    cant: item.cantidad,
    subtotal: parseFloat(Number(item.subtotal)).toLocaleString("es-AR"),
  }));

export default function InformacionFacturaAsociada({ notaDebito }) {
  const retrieveNotaDebitoComprobanteAsociado =
    useRetrieveNotaDebitoComprobanteAsociado({
      queryParams: {
        id: notaDebito.id,
      },
    });

  if (
    retrieveNotaDebitoComprobanteAsociado.isLoading ||
    retrieveNotaDebitoComprobanteAsociado.isFetching
  ) {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 200,
        }}
      >
        <CircularProgress />
        <Typography>Cargando comprobante asociado...</Typography>
      </Box>
    );
  }
  return (
    <>
      <Typography variant="button" style={{ fontWeight: 600 }}>
        COMPROBANTE ASOCIADO{" "}
        {
          retrieveNotaDebitoComprobanteAsociado.data.factura_receipt
            .receipt_type
        }{" "}
        -{" "}
        {
          retrieveNotaDebitoComprobanteAsociado.data.factura_receipt
            .formatted_number
        }
      </Typography>
      <Box mt={2} />
      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Cliente: </span>
        {retrieveNotaDebitoComprobanteAsociado.data.factura_boxer.cliente}
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Fecha validación: </span>
        {moment(
          retrieveNotaDebitoComprobanteAsociado.data.factura_receipt
            .validation_date,
        ).format("YYYY-MM-DD")}
      </Typography>
      <Typography style={{ fontSize: "0.95rem" }}>
        <span className="fontBold">Aplica descuento: </span>
        {retrieveNotaDebitoComprobanteAsociado.data.factura_boxer.venta
          .id_desc_total
          ? (
              Number(
                retrieveNotaDebitoComprobanteAsociado.data.factura_boxer.venta
                  .id_desc_total.porcentaje,
              ) * 100
            ).toFixed(2)
          : "---"}{" "}
        %
      </Typography>
      <Typography variant="body2">
        <span style={{ fontWeight: "bolder" }}>Responsable: </span>
        {
          retrieveNotaDebitoComprobanteAsociado.data.factura_boxer.venta
            .responsable
        }
      </Typography>
      <Box mt={2} />

      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="body2">
          <span style={{ fontWeight: "bolder" }}>Importe: </span>
          {formatoMoneda({
            value:
              retrieveNotaDebitoComprobanteAsociado.data.factura_receipt
                .total_amount,
          })}
        </Typography>
      </Box>
      <InformacionMediosDePagoFacturaAsociada
        pagosCliente={
          retrieveNotaDebitoComprobanteAsociado.data.factura_boxer.venta
            .pagos_cliente
        }
      />
      <ReportTable
        billetera
        heightStyle={"calc(100vh - 350px)"}
        noResults={"No se ventas."}
        items={formatDetalleVenta(
          retrieveNotaDebitoComprobanteAsociado.data.factura_boxer.venta
            .detalles,
        )}
        columns={columns}
        loading={false}
      />
    </>
  );
}
