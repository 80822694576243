import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonGeneral from "../../../../../../components/ait-reusable/Button/ButtonGeneral";
import request from "../../../../../../requests/request";
import {
  createPlazoPagoCompra,
  deletePlazoPagoCompra,
  readPlazosPagoCompra,
} from "../../../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";

const useStyles = makeStyles((theme) => ({
  demo: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function PlazosPagoCompra({}) {
  const classes = useStyles();
  const [plazo, setPlazo] = useState("");
  const [error, setError] = useState(false);
  const [plazosPago, setPlazosPago] = useState([]);

  const getPlazosPago = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readPlazosPagoCompra,
        params: { q: "", page: 1, all: 1 },
      });
      let plazosActivos = response.data
        .filter((plazo) => plazo.is_active === true)
        .sort((a, b) => a.dias - b.dias);
      setPlazosPago(plazosActivos);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePostPlazoPago = async () => {
    try {
      const response = await request({
        method: "POST",
        url: createPlazoPagoCompra,
        data: {
          dias: plazo,
        },
      });
      successNotification("Plazo de pago agregado exitosamente.");
      getPlazosPago();
      setPlazo("");
    } catch (error) {
      errorNotification(
        "Ha ocurrido un error al intentar agregar el plazo de pago.",
      );
      console.error(error);
    }
  };

  const handleNewPlazoPago = (e) => {
    let value = e.target.value;

    // Validación para permitir solo números enteros positivos
    if (/^\d*$/.test(value)) {
      setPlazo(value);

      setPlazo(value);

      let plazos = plazosPago.map((plazo) => plazo.dias);
      let valueError = plazos.includes(Number(value));
      setError(valueError);
    }
  };

  const handleDeletePlazoPago = async (idPlazoPago) => {
    try {
      const response = await request({
        method: "PUT",
        url: deletePlazoPagoCompra,
        params: {
          id_plazo: idPlazoPago,
        },
      });
      successNotification("Plazo de pago eliminado con éxito");
      getPlazosPago();
    } catch (error) {
      errorNotification(
        "Ha ocurrido un error al intentar eliminar el plazo de pago.",
      );
      console.error(error);
    }
  };

  useEffect(() => {
    getPlazosPago();
  }, []);

  return (
    <div className={classes.demo}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            type="text"
            id="plazo"
            label="Días de plazo de pago"
            size="small"
            value={plazo}
            error={Boolean(error)}
            helperText={error ? "El plazo ya existe" : ""}
            onChange={(e) => handleNewPlazoPago(e)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ButtonGeneral
            click={() => handlePostPlazoPago()}
            fullwidth={true}
            disabled={!plazo.length || error}
            message={"Agregar"}
          />
        </Grid>
      </Grid>
      <Box pt={1} pb={1}>
        <Divider />
      </Box>
      <Grid container spacing={2} className="pt-2">
        <Grid item xs={12}>
          <List style={{ overflow: "auto", maxHeight: 320 }}>
            {plazosPago.map((plazo, i) => {
              return (
                <>
                  <ListItem key={i}>
                    <ListItemText primary={`Plazo de ${plazo.dias} días`} />
                    <ListItemSecondaryAction>
                      {!plazo.is_default ? (
                        <IconButton
                          edge="end"
                          title="Eliminar"
                          onClick={() => handleDeletePlazoPago(plazo.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <HelpIcon
                          titleAccess="No se puede eliminar"
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: 22,
                          }}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
