import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { MyDocumentTicket } from "../DePagos/DocumentTicket";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ButtonModalMaterial } from "../../ButtonModalMaterial";
import { MyDocumentA4 } from "../DePagos/DocumentA4";
import FileA4 from "../DeBilletera/fileA4";
import FileTicket from "../DeBilletera/fileTicket";
import PresupuestoTicket from "../DePresupuesto/PresupuestoTicket";
import PresupuestoA4 from "../DePresupuesto/PresupuestoA4";
import VentaTicket from "../DeVentas/VentaTicket";
import VentaA4 from "../DeVentas/VentaA4";
// import getLogo from "../utils";
import PagosDeCompras from "./DeProveedores/PagosDeCompras";
import MyReport from "../DeReporte/Report";
import NotaTicket from "../DeNotaCredito/NotaTicket";
import NotaA4 from "../DeNotaCredito/NotaA4";
import { useSelector } from "react-redux";
import MovimientosBilletera from "../DeBilletera/MovimientosBilletera";
import MovimientosCCProveedores from "../DeProveedores/MovimientosCCProveedores";
import CuentaCorrienteCliente from "../DeClientes/CuentaCorrienteCliente";
import RemitoTicket from "../DeRemito/RemitoTicket";
import RemitoA4 from "../DeRemito/RemitoA4";
import { RetencionPdf } from "../DeRetencion/RetencionPdf";
import ReciboA4 from "../DeRecibos/ReciboA4";
import ReciboTicket from "../DeRecibos/ReciboTicket";
import NotaDebitoA4 from "../DeNotaDebito/NotaDebitoA4";
import RotuloEnvioVentaA4 from "../DeVentas/RotuloEnvio/RotuloEnvioVentaA4";
import ConciliacionVentasA4 from "../DeConciliacion/ConciliacionVentasA4";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewPdf = React.memo(({ data, tipo, remito, isFcemA, sucursal }) => {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  // const [logoSucursal, setLogoSucursal] = useState("");
  const logoSucursal = "";

  return (
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      {(tipo === "reciboA4" || tipo === "reciboA4Ahorro") && (
        <ReciboA4 data={data} tipo={tipo} config={configGeneral} />
      )}
      {tipo === "reciboTicket" && (
        <ReciboTicket data={data} tipo={tipo} config={configGeneral} />
      )}
      {(tipo === "ticket" || tipo === "ticketPagoDeuda") && (
        <MyDocumentTicket data={data} />
      )}
      {(tipo === "A4" || tipo === "A4PagoDeuda" || tipo === "A4Ahorro") && (
        <MyDocumentA4 data={data} tipo={tipo} config={configGeneral} />
      )}
      {(tipo === "billeteraA4" || tipo === "billeteraA4Ahorro") && (
        <FileA4 data={data[0]} tipo={tipo} config={configGeneral} />
      )}
      {tipo === "billeteraTicket" && <FileTicket data={data[0]} tipo={tipo} />}
      {tipo.includes("reporte") && (
        <MyReport data={data} tipo={tipo} logo={logoSucursal} />
      )}
      {tipo === "presupuestoTicket" && (
        <PresupuestoTicket data={data} config={configGeneral} />
      )}
      {(tipo === "presupuestoA4" || tipo === "presupuestoA4Ahorro") && (
        <PresupuestoA4 data={data} tipo={tipo} config={configGeneral} />
      )}
      {tipo === "ventaTicket" && (
        <VentaTicket data={data} config={configGeneral} remito={remito} />
      )}
      {(tipo === "ventaA4" || tipo === "ventaA4Ahorro") && (
        <VentaA4
          data={data}
          tipo={tipo}
          config={configGeneral}
          remito={remito}
          isFCEMA={isFcemA}
        />
      )}
      {tipo === "notacreditoTicket" && (
        <NotaTicket data={data} config={configGeneral} remito={remito} />
      )}
      {(tipo === "notacreditoA4" || tipo === "ncA4Ahorro") && (
        <NotaA4
          data={data}
          tipo={tipo}
          config={configGeneral}
          remito={remito}
        />
      )}
      {tipo === "movBilletera" && (
        <MovimientosBilletera data={data} tipo={tipo} />
      )}
      {tipo === "cuentaCorrienteCliente" && (
        <CuentaCorrienteCliente data={data} tipo={tipo} />
      )}
      {tipo === "pagoCompras" && (
        <PagosDeCompras
          data={data}
          tipo={tipo}
          config={configGeneral}
          sucursal={sucursal}
        />
      )}
      {tipo === "movBilleteraProveedores" && (
        <MovimientosCCProveedores data={data} tipo={tipo} />
      )}
      {tipo === "remitoTicket" && (
        <RemitoTicket data={data} config={configGeneral} />
      )}
      {(tipo === "remitoA4" || tipo === "remitoA4Ahorro") && (
        <RemitoA4 data={data} tipo={tipo} config={configGeneral} />
      )}
      {tipo === "retencion" && (
        <RetencionPdf data={data} tipo={tipo} config={configGeneral} />
      )}
      {tipo === "notaDebitoA4" && (
        <NotaDebitoA4 notaDebito={data} config={configGeneral} />
      )}
      {tipo === "rotuloEnvioA4" && (
        <RotuloEnvioVentaA4 data={data} config={configGeneral} />
      )}
      {tipo === "conciliacionVentasA4" && (
        <ConciliacionVentasA4 data={data} config={configGeneral} tipo={tipo} />
      )}
    </PDFViewer>
  );
});

export default ViewPdf;

export const Showmodal = React.memo(
  ({ preview, toggle, data, tipo, remito, isFcemA, sucursal }) => {
    /**tipo puede ser ticket o A4 */

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    return preview && Object.values(data).length > 0 ? (
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="customized-dialog-title"
        open={preview}
        onClose={toggle}
        TransitionComponent={Transition}
      >
        <ButtonModalMaterial onClose={toggle}>
          {tipo.includes("reporte") ? "Informe" : "Comprobante"}
        </ButtonModalMaterial>
        <DialogContent dividers style={{ height: 600, overflow: "hidden" }}>
          <ViewPdf
            data={data}
            tipo={tipo}
            remito={remito}
            isFcemA={isFcemA}
            sucursal={sucursal}
          />
        </DialogContent>
      </Dialog>
    ) : null;
  },
);
