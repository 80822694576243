import React from "react";
import { getVersionLocalStorage, setVersionLocalStorage } from "./utils";
import useDisclosure from "../../../customHooks/useDisclosure";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalVerificadorVersion() {
  const [versionCargada, setVersionCargada] = React.useState(
    getVersionLocalStorage(),
  );
  const [versionActual, setVersionActual] = React.useState(null);
  const [opened, { open }] = useDisclosure(false);

  const getCurrentVersion = async () => {
    const request = await axios.get(
      "https://consultaafip.somosait.com/api/boxer_utils/version-frontend/",
    );
    return request.data.version_frontend;
  };

  React.useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const version = await getCurrentVersion();
        if (version !== versionCargada) {
          open();
          setVersionActual(version);
        }
      } catch (error) {
        console.error(error);
      }
    }, 3000);
    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(interval);
  }, []);

  const handleReload = () => {
    setVersionLocalStorage(versionActual);
    window.location.reload(true);
  };

  return (
    <Dialog
      open={opened}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Nueva versión disponible
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Se detectó una nueva versión del sistema. Para acceder a las últimas
          mejoras y actualizaciones, hacé clic en el botón de abajo para
          recargar la página.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReload} color="primary">
          Recargar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
