import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import ReportTable from "../../Report/ReportTable";
import { parseCurrency } from "../../../../utils/parsers";
import formatVentasConciliacionVenta from "./utils";

export default function ConciliacionVentas({
  conciliacionVentas,
  onDeletePagoConciliacion,
  refetch,
}) {
  let columns = ["Comprobante", "Monto", "Estado", "--"];
  const mediosDePago = () => {
    let items = [];

    if (conciliacionVentas.orden_carga_saldo) {
      items = [
        ...items,
        ...conciliacionVentas.orden_carga_saldo.detalles_orden.map(
          (item) => item.medio_pago.nombre,
        ),
      ];
    }

    if (Number(conciliacionVentas.importe_cuenta_corriente)) {
      items = [...items, "Saldo en billetera"];
    }

    return items;
  };

  const totalSaldado = conciliacionVentas.ventas_saldadas.reduce(
    (acc, venta) => acc + Number(venta.monto_saldado),
    0,
  );
  const totalSobrante = Number(
    (conciliacionVentas.importe_total - totalSaldado).toFixed(2),
  );
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6} spacing={2}>
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Fecha:
          </Typography>
          <Typography variant="body1">
            {moment(conciliacionVentas.fecha_hora).format(
              "DD/MM/YYYY HH:MM:ss",
            )}
          </Typography>
          <Box mt={1} />
          <Typography
            variant="h6"
            style={{ fontSize: 18, fontWeight: "bolder" }}
          >
            Medio de pago:{" "}
          </Typography>
          <Typography variant="body1">{mediosDePago().join(", ")}</Typography>
          <Box mt={1} />
          <Typography
            variant="h6"
            style={{ fontSize: 18, fontWeight: "bolder" }}
          >
            Descuento:{" "}
          </Typography>
          <Typography variant="body1">{`% ${Number(conciliacionVentas.descuento_porcentaje).toLocaleString("ES-ar")}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Responsable:
          </Typography>
          <Typography variant="body1">
            {conciliacionVentas.responsable}
          </Typography>
          <Box mt={1} />
          <Typography
            style={{ fontSize: 18, fontWeight: "bolder" }}
            variant="h6"
          >
            Observacion:
          </Typography>
          <Typography variant="body1">
            {conciliacionVentas.observacion}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReportTable
            noResults={"No se encontraron cobros registrados."}
            heightStyle={"calc(100vh - 350px)"}
            billetera
            loading={false}
            items={formatVentasConciliacionVenta({
              ventas: conciliacionVentas.ventas_saldadas,
              onPagoDelete: onDeletePagoConciliacion,
              refetch,
            })}
            columns={columns}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 8,
            }}
          >
            <Typography style={{ fontWeight: "bolder" }} variant="h6">
              Monto saldado: {parseCurrency(totalSaldado)}
            </Typography>
          </Box>
          {totalSobrante > 0 && (
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 8,
              }}
            >
              <Typography style={{ fontWeight: "bolder" }} variant="h6">
                Sobrante a billetera: {parseCurrency(totalSobrante)}
              </Typography>
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 8,
            }}
          >
            <Typography style={{ fontWeight: "bolder" }} variant="h6">
              Total: {parseCurrency(conciliacionVentas.importe_total)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
