import React, { useEffect, useState } from "react";
import {
  cambiarCantidad,
  cambiarPrecio,
  actualizarPrecio as reduxActualizarPrecio,
  eliminarItem,
  updateDescripcionPersonalizadaDetalle,
  actualizarObservaciones,
} from "../../../Redux/Actions/presupuestoActions";
import {
  TableBody,
  TableCell,
  TableRow,
  Backdrop,
  makeStyles,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FormFeedback, FormInput, FormSelect } from "shards-react";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import MaskedInput from "react-text-mask";
import {
  actualizarPrecio,
  createPedidoDesdeRepuesto,
  observacionArticulo,
} from "../../../requests/urls";
import request from "../../../requests/request";
import LocationIcon from "../../../components/ait-reusable/LocationIcon";
import {
  successNotification,
  errorNotification,
} from "../../../components/Notifications";
import { useLocation } from "react-router-dom";
import ModalObservacionarticulo from "../../Proveedores/Catalogo/Modales/ModalObservacionArticulo";
import ModalPedido from "../../Presupuesto/ListaArticulos/Components/ModalPedido";
import moment from "moment";
import ImagenArticulo from "../../../components/ait-reusable/ImagenArticulo/ImagenArticulo";
import LongTextWithHover from "../../../components/ait-reusable/LongTextWithHover";
import { tienePermisos } from "../../../utils/tienePermisos";
import { PERMISOS } from "../../../constantes/permisos";
import {
  handleMostrarCodigoArticulo,
  handleMostrarDescripcionArticulo,
} from "./components/Utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fontSizeOptions: {
    fontSize: 16,
  },
}));

const ArticleItem = ({
  articulo,
  index,
  handleOpenObservaciones,
  handleOpenModalPedido,
  idOrden,
  isVenta,
  permisoPrecioCosto,
  permisoPrecioVenta,
  listRowsSelected,
  columna_actualizacion_articulo,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { empleado, perfil } = useSelector((state) => state.loginReducer);
  const { detalles, descuento, medioPago, cliente } = useSelector(
    (store) => store.presupuesto,
  );

  const [listaDePrecios, setListaDePrecios] = useState([]);
  const [mostrarAvisoPrecio, setMostrarAvisoPrecio] = useState(null);
  const [selectedListaDePrecios, setSelectedListaDePrecios] = useState(null);
  const [descripcionPersonalizada, setDescripcionPersonalizada] = useState("");

  useEffect(() => {
    if (articulo.lista_precios) {
      setListaDePrecios(articulo.lista_precios);
      let default_lp;
      if (articulo.idListaPrecio) {
        default_lp = articulo.lista_precios.some(
          (lp) => lp.id === articulo.idListaPrecio,
        )
          ? articulo.idListaPrecio
          : null;
      } else {
        let findLista = articulo.lista_precios.find((lp) => lp.es_default);
        if (findLista) {
          default_lp = findLista.id;
        }
      }
      setSelectedListaDePrecios(default_lp);
    }
  }, [articulo]);

  const isPresupuesto = location.pathname.includes("presupuesto");

  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;

  const permisoParaVerToolTipDescuentoRecargo = tienePermisos(
    empleado,
    PERMISOS.ARTICULOS,
  );

  const isVisible = (nameRow) => {
    const row = listRowsSelected.find((x) => x.nombre === nameRow);
    return row ? row.es_visible : false;
  };

  const handleMostrarAvisoPrecio = (articulo) => {
    if (!permisoPrecioCosto && !articulo.precioValido) {
      setMostrarAvisoPrecio(true);
    }
    if (!permisoPrecioVenta && !articulo.precioValidoVenta) {
      setMostrarAvisoPrecio(false);
    }
  };

  const changeListaPrecio = (event) => {
    const { value } = event.target;
    setSelectedListaDePrecios(value);
    obtenerPrecioArticulo(value);
  };

  const obtenerPrecioArticulo = async (lista_de_precios) => {
    const response = await request({
      method: "GET",
      url: actualizarPrecio,
      params: {
        idArticuloProveedor: articulo.idRepuestoProveedor,
        listaPrecios: lista_de_precios,
        fraccionar_precio: Number(true),
      },
    });
    dispatch(
      reduxActualizarPrecio(
        detalles,
        index,
        response.data.data,
        configGeneral.redondeo_precio,
        cliente,
        descuento,
        medioPago,
        lista_de_precios,
      ),
    );
  };

  // Mostrar componentes del articulo
  const handleRenderDescripcion = (articulo) => {
    const puedeEditarDescripcion = tienePermisos(empleado, PERMISOS.VENTAS);

    if (puedeEditarDescripcion) {
      return (
        <React.Fragment>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={descripcionPersonalizada}
            error={descripcionPersonalizada.length > 2000}
            defaultValue={descripcionPersonalizada}
            // onChange={(e) => {
            //   setDescripcionPersonalizada(e.target.value);
            //   dispatch(
            //     updateDescripcionPersonalizadaDetalle(
            //       detalles,
            //       index,
            //       e.target.value
            //     )
            //   );
            // }}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue.length <= 2000) {
                setDescripcionPersonalizada(newValue);
                dispatch(
                  updateDescripcionPersonalizadaDetalle(
                    detalles,
                    index,
                    newValue,
                  ),
                );
              }
            }}
            InputProps={{
              endAdornment: (
                <TooltipMoreInfoMaterial
                  titleTooltip="Al modificar esta descripción sólo impactará en los comprobantes, la descripción original del artículo no cambiará."
                  position="top"
                />
              ),
            }}
          />
        </React.Fragment>
      );
    }
    return (
      <>
        <span className={classes.fontSizeOptions}>
          {handleMostrarCodigoArticulo(articulo, configGeneral)}
          {handleMostrarDescripcionArticulo(articulo)}
        </span>

        {!isPresupuesto &&
        articulo.idRepuestoProveedor &&
        articulo.observaciones &&
        articulo.observaciones.length > 0 ? (
          <p style={{ fontSize: "12px", opacity: "0.5" }}>
            {articulo.observaciones[0]
              ? articulo.observaciones[0].substr(0, 40).trim() +
                (articulo.observaciones[0].length > 40 ? "..." : "")
              : ""}
          </p>
        ) : null}
      </>
    );
  };
  // Mostrar componentes del articulo

  const handleSetDescripcionPersonalizada = () => {
    if (!articulo) return setDescripcionPersonalizada("");

    if (articulo.descripcionPersonalizada) {
      return setDescripcionPersonalizada(articulo.descripcionPersonalizada);
    }

    const descripcion = `${handleMostrarCodigoArticulo(
      articulo,
      configGeneral,
    )} ${handleMostrarDescripcionArticulo(articulo)}`;

    return setDescripcionPersonalizada(descripcion);
  };

  useEffect(() => {
    handleSetDescripcionPersonalizada();
  }, [descripcionPersonalizada, detalles]);

  return (
    <TableRow key={index} index={index}>
      {isVisible("Imagen") ? (
        <TableCell padding="checkbox">
          <ImagenArticulo articulo={articulo} />
        </TableCell>
      ) : null}

      {/* DESCRIPCION */}
      <TableCell
        style={{
          minWidth: 500,
          maxWidth: 500,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        title={articulo.descripcion.toUpperCase()}
      >
        {/* <span className={classes.fontSizeOptions}>
          {articulo && articulo.descripcion && handleShowCodigoArticulo(articulo)}
          {articulo &&
            articulo.descripcion &&
            articulo.descripcion.toUpperCase()}
        </span>

        {!isPresupuesto &&
        articulo.idRepuestoProveedor &&
        articulo.observaciones &&
        articulo.observaciones.length > 0 ? (
          <p style={{ fontSize: "12px", opacity: "0.5" }}>
            {articulo.observaciones[0]
              ? articulo.observaciones[0].substr(0, 40).trim() +
                (articulo.observaciones[0].length > 40 ? "..." : "")
              : ""}
          </p>
        ) : null} */}
        {handleRenderDescripcion(articulo)}
      </TableCell>

      {/* UNIDAD */}
      <TableCell align="center" style={{ minWidth: 110, maxWidth: 110 }}>
        <MaskedInput
          className="text-center form-control"
          guide={false}
          mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          onFocus={(event) => event.target.select()}
          disabled={idOrden ? true : false}
          onChange={(e) => {
            dispatch(cambiarCantidad(detalles, index, e, descuento, medioPago));
          }}
          value={articulo.cantidad}
          invalid={!articulo.cantidadValida}
          name="cantidad"
        />
        <FormFeedback>Cantidad inválida</FormFeedback>
      </TableCell>

      {/* PRECIO U. */}
      <TableCell align="center" style={{ minWidth: 180, maxWidth: 180 }}>
        <FormInput
          min="0"
          type="number"
          className="text-center"
          onFocus={(event) => event.target.select()}
          invalid={
            (!isVendedor &&
              configGeneral.activar_validacion_precio &&
              !articulo.precioValido) ||
            (!permisoPrecioCosto && !articulo.precioValido) ||
            (!permisoPrecioVenta && !articulo.precioValidoVenta)
          }
          onChange={(event) => {
            dispatch(
              cambiarPrecio(detalles, index, event, descuento, medioPago),
            );
            handleMostrarAvisoPrecio(articulo);
          }}
          value={articulo.precio}
          name="precio"
          disabled={
            isVenta &&
            cliente &&
            cliente.clienteDescuento.isClienteDescuento &&
            medioPago[0] &&
            medioPago[0].nombre &&
            medioPago[0].nombre === "Cuenta Corriente"
              ? true
              : false || idOrden
          }
        />

        {
          /* Se muestra el msj si es admin y tiene activada la validacion */
          !isVendedor &&
            configGeneral.activar_validacion_precio &&
            !articulo.precioValido && (
              <FormFeedback>
                El monto debe ser mayor a $
                {articulo.precioCosto ||
                  (articulo.precios && articulo.precios.costo) ||
                  "0.00"}
              </FormFeedback>
            )
        }

        {/* Se muestra el msj si es vendedor y no tiene activado el permiso "ventas - precio costo" */}
        {!permisoPrecioCosto &&
          !articulo.precioValido &&
          mostrarAvisoPrecio && (
            <FormFeedback>
              No se permite vender a un precio menor al precio costo ($
              {articulo.precioCosto ||
                (articulo.precios && articulo.precios.costo) ||
                "0.00"}
              ).
            </FormFeedback>
          )}

        {/* Se muestra el msj si es vendedor y no tiene activado el permiso "ventas - precio venta*/}
        {!permisoPrecioVenta &&
          !articulo.precioValidoVenta &&
          !mostrarAvisoPrecio && (
            <FormFeedback>
              No se permite vender a un precio menor al precio venta ($
              {articulo.precioVenta ||
                (articulo.precios && articulo.precios.venta) ||
                "0.00"}
              ).
            </FormFeedback>
          )}
      </TableCell>

      {/* COD ARTIC */}
      {isVisible("Artículo") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.codProveedor ? articulo.codProveedor : "---"}
          </span>
        </TableCell>
      )}

      {/* COD ORIG */}
      {isVisible("Original") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.codOriginal ? articulo.codOriginal : "---"}
          </span>
        </TableCell>
      )}

      {/* PRECIO C. */}
      {isVisible("P. Costo") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.precioCosto
              ? `$${articulo.precioCosto.toLocaleString("es-AR")}`
              : articulo.precios && articulo.precios.costo
                ? `$${articulo.precios.costo.toLocaleString("es-AR")}`
                : "---"}
          </span>
        </TableCell>
      )}

      {/* PRECIO L. */}
      {isVisible("P. Lista") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.precioLista
              ? `$${articulo.precioLista.toLocaleString("es-AR")}`
              : articulo.precios && articulo.precios.lista
                ? `$${articulo.precios.lista.toLocaleString("es-AR")}`
                : "---"}
          </span>
        </TableCell>
      )}

      {/* PRECIO VENTA */}
      {isVisible("P. Venta") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.precioVenta
              ? `$${articulo.precioVenta.toLocaleString("es-AR")}`
              : articulo.precios && articulo.precios.venta
                ? `$${articulo.precios.venta.toLocaleString("es-AR")}`
                : "---"}
          </span>
        </TableCell>
      )}

      {/* STOCK */}
      {isVisible("Stock") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stockDisponible
              : "---"}
          </span>
        </TableCell>
      )}

      {/* PROVEEDOR */}
      {isVisible("Proveedor") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.proveedor ? articulo.proveedor : "---"}
          </span>
        </TableCell>
      )}

      {/* COD AUXILIAR */}
      {isVisible("Auxiliar") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.codAuxiliar ? articulo.codAuxiliar : "---"}
          </span>
        </TableCell>
      )}

      {/* STOCK DESEADO */}
      {isVisible("Stock deseado") && (
        <TableCell
          align="center"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span className={classes.fontSizeOptions}>
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stock_deseado
              : "---"}
          </span>
        </TableCell>
      )}

      {/* SUBTOTAL */}
      <TableCell
        align="center"
        title={articulo.subtotal.toLocaleString("es-AR")}
        style={{ minWidth: 170, maxWidth: 170 }}
      >
        <div
          className="d-flex fontBold"
          style={{ justifyContent: "center", fontSize: 18 }}
        >
          $
          {articulo.subtotal.toLocaleString("es-AR").length < 10
            ? articulo.subtotal.toLocaleString("es-AR")
            : articulo.subtotal.toLocaleString("es-AR").substr(0, 9) + "..."}
          {permisoParaVerToolTipDescuentoRecargo && articulo.tipo_descuento && (
            <div style={{ marginLeft: "3px" }}>
              <TooltipMoreInfoMaterial titleTooltip={articulo.tipo_descuento} />
            </div>
          )}
        </div>
      </TableCell>

      {/* UBICACIÓN */}
      {isVisible("Ubicación") && (
        <TableCell align="center">
          {!articulo.repuesto_sucursal ||
          !articulo.repuesto_sucursal.deposito ? (
            "---"
          ) : (
            <LongTextWithHover
              text={`${
                articulo.repuesto_sucursal.deposito.descripcion +
                " - " +
                articulo.repuesto_sucursal.zona.descripcion +
                " - " +
                articulo.repuesto_sucursal.estante.descripcion +
                " - " +
                articulo.repuesto_sucursal.fila.descripcion
              }`}
            />
          )}
        </TableCell>
      )}

      {/* RUBRO */}
      {isVisible("Rubro") && (
        <TableCell align="center">
          {articulo.rubro ? articulo.rubro.nombre : "---"}
        </TableCell>
      )}

      {/* SUBRUBRO */}
      {isVisible("Subrubro") && (
        <TableCell align="center">
          {articulo.sub_rubro ? articulo.sub_rubro.nombre : "---"}
        </TableCell>
      )}

      {/* LISTA DE PRECIOS */}
      {isVisible("L. de Precios") && (
        <TableCell align="center">
          {listaDePrecios.length > 0 && listaDePrecios[0].is_precio_lista ? (
            <>
              <FormSelect
                value={selectedListaDePrecios}
                onChange={changeListaPrecio}
                name="lista_precios"
                invalid={!selectedListaDePrecios}
              >
                <option value="">Seleccionar Lista de Precios ...</option>
                {listaDePrecios.map((lp) => {
                  if (lp.is_precio_lista) {
                    return (
                      <option key={lp.id} value={lp.id}>
                        {lp.nombre}
                      </option>
                    );
                  }
                })}
              </FormSelect>

              {/* Se muestra el msj si se decide confirmar la venta sin seleccionar una Lista de Precios */}
              {!selectedListaDePrecios && (
                <FormFeedback>
                  Se debe seleccionar una Lista de Precios.
                </FormFeedback>
              )}
            </>
          ) : (
            "---"
          )}
        </TableCell>
      )}

      {/* FECHA ÚLTIMA ACTUALIZACIÓN */}
      {columna_actualizacion_articulo && (
        <TableCell align="center" style={{ minWidth: 170, maxWidth: 170 }}>
          {articulo.fechaUltimaActualizacion
            ? moment(articulo.fechaUltimaActualizacion).format(
                "DD-MM-YYYY HH:mm",
              )
            : "---"}
        </TableCell>
      )}

      {/* OPCIONES */}
      <TableCell
        align="center"
        style={{
          whiteSpace: "nowrap",
          alignItems: "center",
        }}
      >
        <i
          className="material-icons borrar-producto"
          style={{ margin: 0, cursor: "pointer", fontSize: 20 }}
          title="Eliminar"
          onClick={() => {
            dispatch(eliminarItem(detalles, index, descuento, medioPago));
          }}
        >
          delete
        </i>

        {articulo.idRepuestoProveedor && !isPresupuesto && (
          <i
            className="material-icons borrar-producto"
            style={{
              margin: 0,
              cursor: "pointer",
              fontSize: 20,
              opacity:
                articulo.observaciones && articulo.observaciones.length
                  ? "1"
                  : "0.5",
            }}
            title="Agregar observaciones"
            onClick={() => handleOpenObservaciones(articulo)}
          >
            visibility
          </i>
        )}

        {articulo.idRepuestoProveedor && (
          <i
            title="Agregar a pedido"
            onClick={() => handleOpenModalPedido(articulo)}
            className="material-icons"
            style={{ margin: "0 5px", cursor: "pointer", fontSize: 20 }}
          >
            local_shipping
          </i>
        )}
        <LocationIcon articulo={articulo} styles={{ fontSize: 20 }} />
      </TableCell>
    </TableRow>
  );
};

export default function BodyTable({
  isVenta,
  idOrden,
  listRowsSelected,
  columna_actualizacion_articulo,
  setDesabilitaRegistrarVenta,
  permisoPrecioCosto,
  permisoPrecioVenta,
}) {
  const classes = useStyles();

  const { detalles } = useSelector((store) => store.presupuesto);
  const dispatch = useDispatch();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [articuloModal, setArticuloModal] = useState(null);
  const [pedidosAbiertos, setPedidosAbiertos] = useState([]);
  const [openModalPedido, setOpenModlaPedido] = useState(false);
  const [modalObservacion, setModalObservacion] = useState(false);

  /**
   * Funcion encargada de actualizar las observaciones de un articulo
   * @param {String} obs - Es la observacion a actualizar
   * @param {Object} articulo - Es el articulo a actualizar
   */
  const handleSubmitObservaciones = async (obs, articulo) => {
    try {
      const response = await request({
        method: "PUT",
        url: observacionArticulo,
        data: {
          idRepuestoProveedor: articulo.idRepuestoProveedor,
          observaciones: obs,
        },
      });
      setModalObservacion(false);
      successNotification("Observaciones actualizadas con éxito");
      dispatch(actualizarObservaciones(detalles, articulo, obs));
    } catch (err) {
      console.log(err);
      errorNotification("Error, intente nuevamente");
    }
  };

  /**
   * Funcion encargada de crear un pedido desde un articulo
   * @param {Object} articulo - Es el articulo a crear el pedido
   */
  const handlePostPedido = async (articulo) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "POST",
        url: createPedidoDesdeRepuesto,
        data: {
          repuesto_proveedor_id: articulo.idRepuestoProveedor,
        },
      });
      setPedidosAbiertos(response.data.pedidos_abiertos);
      if (response.data.tiene_pedidos) {
        setOpenModlaPedido(true);
      } else {
        successNotification(
          `Se ha creado el pedido del artículo ${articulo.descripcion} con éxito`,
        );
      }
      setOpenBackdrop(false);
    } catch (error) {
      console.error(error);
      setOpenBackdrop(false);
    }
  };

  const handleOpenObservaciones = (articulo) => {
    setArticuloModal(articulo);
    setModalObservacion(true);
  };

  const handleOpenModalPedido = (articulo) => {
    handlePostPedido(articulo);
    setArticuloModal(articulo);
  };

  const handleCloseModalPedido = () => {
    setOpenModlaPedido(false);
    setArticuloModal(null);
  };

  /**
   * La función `validarPrecioDetalles` comprueba si los precios en el detalle son válidos para vender
   * dependiendo de las condiciones de los permisos de precios.
   * Si uno de los precios es invalido, bloquea el boton de venta.
   * Solo se desbloquea el boton de venta si todos los precios en los detalles son validos
   */
  const validarPrecioDetalles = () => {
    const copiaDetalles = [...detalles];
    let bandera = false; // usamos esta variable de bandera
    if (copiaDetalles) {
      copiaDetalles.forEach((detalle) => {
        if (
          (!permisoPrecioCosto && !detalle.precioValido) ||
          (!permisoPrecioVenta && !detalle.precioValidoVenta)
        ) {
          bandera = true;
        }
      });

      if (bandera) {
        setDesabilitaRegistrarVenta(true);
      } else {
        setDesabilitaRegistrarVenta(false);
      }
    }
  };

  useEffect(() => {
    validarPrecioDetalles();
  }, [detalles]);

  return (
    <TableBody>
      {detalles.map((articulo, index) => (
        <ArticleItem
          articulo={articulo}
          index={index}
          key={index}
          handleOpenObservaciones={handleOpenObservaciones}
          handleOpenModalPedido={handleOpenModalPedido}
          idOrden={idOrden}
          isVenta={isVenta}
          permisoPrecioCosto={permisoPrecioCosto}
          permisoPrecioVenta={permisoPrecioVenta}
          listRowsSelected={listRowsSelected}
          columna_actualizacion_articulo={columna_actualizacion_articulo}
        />
      ))}

      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {modalObservacion && articuloModal && (
        <ModalObservacionarticulo
          open={modalObservacion}
          obsArticulo={articuloModal.observaciones}
          handleClose={() => {
            setModalObservacion(false);
            setArticuloModal(null);
          }}
          onSubmitObservaciones={(obs) =>
            handleSubmitObservaciones(obs, articuloModal)
          }
        />
      )}

      {openModalPedido && (
        <ModalPedido
          open={openModalPedido}
          close={handleCloseModalPedido}
          articuloSeleccionado={articuloModal}
          pedidosAbiertos={pedidosAbiertos}
        />
      )}
    </TableBody>
  );
}
