import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";
import ResumenPagos from "./ResumenPagos";

export default function InformacionConciliacion({ conciliacion }) {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 300 })}>
              <Text style={styles.text2Title}>Observaciones</Text>
              <Text style={styles.text2}>
                {conciliacion.observacion || "---"}
              </Text>
              <Text style={styles.text2Title}>Observaciones de pago</Text>
              <Text style={styles.text2}>
                {(conciliacion.orden_carga_saldo &&
                  conciliacion.orden_carga_saldo.concepto) ||
                  "---"}
              </Text>
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 200 })}>
              <Text style={styles.text2Title}>Descuento</Text>
              <Text style={styles.text2}>
                {`% ${Number(conciliacion.descuento_porcentaje).toLocaleString("es-AR")}`}
              </Text>
              <Text style={styles.text2Title}>Formas de pago:</Text>
              {Number(conciliacion.importe_cuenta_corriente) ? (
                <Text style={styles.text2}>
                  {`- Saldo en billetera: $${Number(
                    conciliacion.importe_cuenta_corriente,
                  ).toLocaleString("es-AR")}`}
                </Text>
              ) : null}
              {conciliacion.orden_carga_saldo ? (
                <ResumenPagos
                  data={conciliacion.orden_carga_saldo.detalles_orden}
                  styles={styles.text2}
                />
              ) : null}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
