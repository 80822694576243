import { Card, Grid } from "@material-ui/core/";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import useConversionNumerosMiles from "../../../../customHooks/useConversionNumeroMiles";
import { useMedioPagos } from "../../../../customHooks/useMedioPagos";
import request from "../../../../requests/request";
import { getModalidadCheques } from "../../../../requests/urls";
import { getdataCliente } from "../../../Clientes/BilleteraVirtual/utils";
import getTiposMovimientosCtaCte from "../utils";
import OthersComponents from "./OthersComponents";
import FormularioCargaMediosPago from "./componentes/FomularioCargaMedioPago";
import {
  estadoInicialForm,
  validationYup,
} from "./componentes/FomularioCargaMedioPago/utils";
import SaldoBilletera from "./componentes/SaldoBilletera";

export default function CobrarDeuda({
  selectionList,
  cliente,
  getFacturasAdeudadas,
  updateResumen,
  newMontos,
  updatebilletera,
  tipo,
  // formPost,
  setMontos,
  closePago,
  dataEmpleado,
  facturas,
  observacion,
  setObservacion,
  // setOpenModalCargarSaldo,
  mBilletera,
  setMBilletera,
  setDataErrorAFIP,
}) {
  const [montocobrar, setMontocobrar] = useState("");
  const [errormonto, setErrormonto] = useState("");
  const { idCliente } = useParams();
  const { convertNumeroSinMiles } = useConversionNumerosMiles();
  const [isLoadingGetDataCliente, setIsLoadingGetDataCliente] = useState(false);
  const [modalidadCheques, setModalidadCheques] = React.useState([]);
  const [tipoMovCtacte, settipoMovCtacte] = React.useState([]);
  const { mediosPago } = useMedioPagos();
  const date = moment(new Date()).format("YYYY-MM-DD");
  const montoACobrar = Number(
    newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
  );

  const calcularMonoInicialMedioPagoInicial = () => {
    return String(montoACobrar).replace(".", ",");
  };

  /**
   * La función filterMediosPago tiene como propósito modificar la lista de medios de pago.
   * Se quita el medio de pago "Cuenta Corriente" y se agrega "Saldo en billetera".
   * "Saldo en billetera" no es un medio de pago en sí, pero se agrega para que el usuario pueda seleccionarlo.
   * Representa el saldo que tiene en la billetera.
   */
  const filterMediosPago = () => {
    let newMediosPago = mediosPago.slice();
    newMediosPago = newMediosPago.filter(
      (value) => value.nombre !== "Cuenta Corriente",
    );
    newMediosPago = [
      ...newMediosPago,
      {
        nombre: "Saldo en billetera",
        idMedioPago: 0,
        tipo: "billetera",
      },
    ];

    return newMediosPago;
  };

  const medioPagoInicial = filterMediosPago().find(
    (medio) => medio.nombre === "Saldo en billetera",
  );

  const formik = useFormik({
    initialValues: estadoInicialForm({
      medioPagoInicial,
      modalidadCheques: modalidadCheques,
    }),
    validationSchema: validationYup({ montoBilletera: mBilletera }),
  });

  useEffect(() => {
    async function getData() {
      setIsLoadingGetDataCliente(true);
      await getdataCliente(idCliente).then((dataClient) => {
        setMBilletera(Number(dataClient ? dataClient.monto_billetera : 0));
        setIsLoadingGetDataCliente(false);

        let mediosPagoCargados = formik.values.medios;
        mediosPagoCargados[0].monto = calcularMonoInicialMedioPagoInicial(
          Number(dataClient ? dataClient.monto_billetera : 0),
        );
        formik.setFieldValue("medios", mediosPagoCargados);
      });
    }
    getData();
  }, [updatebilletera]);

  useEffect(() => {
    let mTotal = Number(
      newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
    );
    if (mTotal > mBilletera) {
      let newMonto = Number(mBilletera).toFixed(2);
      setMontocobrar(Number(newMonto).toLocaleString("es-AR"));
    } else {
      let newMonto = Number(
        newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
      ).toFixed(2);
      setMontocobrar(Number(newMonto).toLocaleString("es-AR"));
    }
  }, [mBilletera, newMontos.descuentoUnico]);

  React.useEffect(() => {
    getModalidadCheque();
    getTiposMovimientosCtaCte(false).then((x) => {
      settipoMovCtacte(x);
      // Se setea el primero elemento del array de motivos
      if (x.length) {
        formik.setFieldValue("motivo", x[0].id);
      }
    });
  }, []);

  const montoTotalCargado = formik.values.medios.reduce(
    (acc, medio) =>
      acc + Number(String(medio.monto).replace(/\./g, "").replace(",", ".")),
    0,
  );
  let montoASaldar = Number(
    newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
  ).toFixed(2);

  const getModalidadCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getModalidadCheques,
      });
      if (response.status === 200) {
        setModalidadCheques(response.data);
      }
    } catch (error) {
      console.error(error);
      setModalidadCheques([]);
    }
  };

  const getValueSaldar = () => {
    let dataReturn = {
      monto: "",
      msg: "",
    };

    let mTotal = Number(
      newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
    ).toFixed(2);
    let mResto = mBilletera - mTotal;

    if (tipo.disabledSelectSinRecargo && !tipo.disabledSelectRecargo) {
      if (newMontos.subtotal > 0 && mBilletera >= mTotal) {
        dataReturn = {
          monto: mTotal,
          msg: `Te quedarían $ ${Number(
            Number(mResto).toFixed(2),
          ).toLocaleString("es-AR")} en billetera`,
        };
      } else {
        if (newMontos.subtotal > 0 && mBilletera < mTotal) {
          dataReturn = { monto: "", msg: "No cuentas con saldo suficiente" };
        } else {
          dataReturn = { monto: "", msg: "" };
        }
      }
    } else {
      let montoIngresado =
        montocobrar === "" ? 0 : convertNumeroSinMiles(montocobrar);
      if (Number(montoIngresado) > Number(mBilletera)) {
        dataReturn = { monto: "", msg: "No cuentas con saldo suficiente" };
      } else if (
        Number(montoIngresado) <= Number(mBilletera) &&
        Number(montoIngresado) <= Number(mTotal)
      ) {
        dataReturn = {
          monto: mTotal,
          msg: `Te quedarían $ ${Number(
            Number(mBilletera - montoIngresado).toFixed(2),
          ).toLocaleString("es-AR")} en billetera`,
        };
      } else {
        dataReturn = {
          monto: "",
          msg: "La cantidad ingresada se excede al total",
        };
      }
    }
    return dataReturn;
  };

  if (isLoadingGetDataCliente)
    return <CircularBackdrop openBackdrop={isLoadingGetDataCliente} />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <SaldoBilletera montoBilletera={mBilletera} />
          </Card>
          <br />
          <Card>
            <FormularioCargaMediosPago
              formik={formik}
              montoBilletera={mBilletera}
              newMontos={newMontos}
              filterMediosPago={filterMediosPago}
              tipoMovCtacte={tipoMovCtacte}
              modalidadCheques={modalidadCheques}
              date={date}
              montoASaldar={montoASaldar}
              montoTotalCargado={montoTotalCargado}
            />
          </Card>
        </Grid>
      </Grid>
      <OthersComponents
        dataEmpleado={dataEmpleado}
        observacion={observacion}
        setObservacion={setObservacion}
        selectionList={selectionList}
        tipo={tipo}
        getValueSaldar={getValueSaldar}
        newMontos={newMontos}
        convertNumeroSinMiles={convertNumeroSinMiles}
        facturas={facturas}
        closePago={closePago}
        setMontos={setMontos}
        setErrormonto={setErrormonto}
        montocobrar={montocobrar}
        setMontocobrar={setMontocobrar}
        cliente={cliente}
        getFacturasAdeudadas={getFacturasAdeudadas}
        updateResumen={updateResumen}
        setDataErrorAFIP={setDataErrorAFIP}
        montoTotalCargado={montoTotalCargado}
        montoASaldar={montoASaldar}
        formikMediosPago={formik}
      />
    </>
  );
}
