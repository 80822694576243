import { peticiones } from "../../../utils/peticiones";
import { listProveedores } from "../../../requests/urls";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { esNotaDeCredito } from "../../../components/ait-reusable/validateNotaDeCredito";

export const obtenerTipoNotaCredito = (compra, comprobantes) => {
  if (compra.tipo_comprobante.nombre.includes("Factura")) {
    return comprobantes.find((c) =>
      c.nombre.includes(`Notas de Crédito ${compra.tipo_factura.nombre}`),
    );
  }
  if (compra.tipo_comprobante.nombre.includes("Comprobante interno")) {
    return comprobantes.find((c) => c.nombre.includes(`Notas de Crédito X`));
  }
};

export const defaultValues = {
  razonSocial: "",
  puntoVenta: "",
  nroFactura: "",
  fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
  fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
  fechaVencimiento: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
  pagada: false,
  comprobante: "",
  cae: "",
  medio_pago: "",
  descuento: "0",
  responsable: null,
  observacion: "",
  pagosAsociados: [],
  provincia: "",
  activar_plazos_pago: false,
};

export const getProveedores = async () => {
  let listProv = [];
  await peticiones("get", listProveedores).then((res) => {
    listProv = res.data.data ? res.data.data : [];
  });
  return listProv;
};

export const valuesForm = (
  proveedor,
  dataForm,
  tipoComprobante,
  compra,
  provincia,
) => {
  let values = {
    razonSocial: proveedor ? proveedor.razonSocial : "",
    puntoVenta: dataForm.puntoVenta,
    nroFactura: dataForm.nroFactura,
    fechaFacturacion: dataForm.fechaFacturacion,
    fechaImputacion: dataForm.fechaImputacion,
    fechaVencimiento: dataForm.fechaVencimiento,
    medio_pago: dataForm.medio_pago,
    pagada: false,
    comprobante: tipoComprobante ? tipoComprobante.nombre : "",
    cae: dataForm.cae,
    descuento: dataForm.descuento,
    responsable: dataForm.responsable,
    compra: compra ? compra.nro_factura : "",
    observacion: dataForm.observacion,
    pagosAsociados: dataForm.pagosAsociados,
    provincia: provincia,
    activar_plazos_pago: dataForm.activar_plazos_pago,
  };

  return values;
};

export const validateMaxLength = (e) => {
  switch (e.target.name) {
    case "puntoVenta":
      if (e.target.value.length > 4) {
        e.target.value = e.target.value.slice(0, 4);
      } else {
        if (e.target.value === "0000") {
          e.target.value = e.target.value.slice(0, 3);
        }
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    case "nroFactura":
      if (e.target.value.length > 8) {
        e.target.value = e.target.value.slice(0, 8);
      } else {
        if (e.target.value === "00000000") {
          e.target.value = e.target.value.slice(0, 7);
        }
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    case "cae":
      if (e.target.value.length > 14) {
        e.target.value = e.target.value.slice(0, 14);
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    case "percepcion":
      e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
      break;
    case "descuento":
      e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
      break;
    default:
      break;
  }
};

export const validateForm = (values) => {
  let validForm = true;
  let keys = [
    "razonSocial",
    "puntoVenta",
    "nroFactura",
    "fechaFacturacion",
    "comprobante",
    "cae",
    "compra",
  ];

  keys.forEach((key) => {
    if (key === "compra") {
      if (esNotaDeCredito(values) && values.compra === "") {
        validForm = false;
      }
    } else {
      if (values[key] === "") {
        validForm = false;
      }
    }
  });

  return validForm;
};

export const useStyles = makeStyles((theme) => ({
  wrapperCardLeft: {
    height: "calc(100vh - 430px)",
    minHeight: 380,
    width: "50%",
    overflow: "auto",
    borderRadius: "25px 0px 0px 25px",
    backgroundColor: "#eef2f3",
    border: "1px solid #ada996 ",
  },
  wrapperCardRight: {
    height: "calc(100vh - 430px)",
    minHeight: 380,
    width: "50%",
    overflow: "auto",
    borderRadius: "0px 25px 25px 0px",
    backgroundColor: "#D4D3DD",
    border: "1px solid #ada996",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: "solid 5px #0575e6",
  },
  inputProveedor: {
    borderRadius: "25px 25px 25px 25px",
  },
  buttonLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#0575e6",
    cursor: "pointer",
  },
  inputPercepcion: {
    "& .MuiOutlinedInput-root": {
      height: 25,
      fontSize: 15,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 5,
    },
  },
  spanPercepcion: {
    fontSize: 15,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  inputFecha: {
    "& .MuiOutlinedInput-input": {
      paddingRight: 1,
      paddingTop: 15,
    },
  },
}));

export const obtenerEmpleadoPorDefecto = (useEmpleado) => {
  const { nombre, apellido } = JSON.parse(localStorage.getItem("empleado"));
  const empleadoLocalStorage = nombre + " " + apellido;
  const esAit =
    empleadoLocalStorage.includes("Ait") ||
    empleadoLocalStorage.includes("ait");
  let responsablePorDefecto = null;

  if (useEmpleado.empleados.length > 0) {
    if (!esAit) {
      let predet = useEmpleado.empleados.filter(
        (e) => e.nombre + " " + e.apellido === empleadoLocalStorage,
      )[0];
      responsablePorDefecto = predet || null;
    } else {
      responsablePorDefecto = useEmpleado.empleados.map((e) => e)[0];
    }
  }

  return responsablePorDefecto;
};

export const calcularTotal = (
  articulos,
  tipoComprobante,
  vattTypes,
  dataForm,
) => {
  const includeIva = ![
    "Factura C",
    "Factura B",
    "Comprobante interno",
    "Notas de Crédito X",
    "Notas de Crédito C",
    "Notas de Crédito B",
  ].includes(tipoComprobante ? tipoComprobante.nombre : "");

  const hasValidDescuento =
    dataForm.descuento !== "" && !isNaN(dataForm.descuento);

  let total = articulos.reduce((a, detalle) => {
    let ivaItem = 0;

    if (includeIva) {
      const vatType = vattTypes.find((v) => v.id === detalle.vat);
      if (vatType) {
        ivaItem = Number((Number(vatType.porcentaje) / 100).toFixed(4));
      }
    }

    return (
      a +
      Number(detalle.subtotal) *
        (1 - (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
        (includeIva ? ivaItem + 1 : 1)
    );
  }, 0);

  let totPercepciones = dataForm.percepciones.reduce(
    (a, per) =>
      a +
      (per.valor === "" || isNaN(Number(per.valor))
        ? 0
        : parseFloat(per.valor)),
    0,
  );

  return parseFloat((total + totPercepciones).toFixed(2));
};
