import React, { useState, useEffect } from "react";
import ModalReutilizable from "../Modal";
import Content from "./Content";
import request from "../../../requests/request";
import { readPlazosPagoCompra } from "../../../requests/urls";
import useConversionNumeroMiles from "../../../customHooks/useConversionNumeroMiles";
import Actions from "./Actions";
import { useDebounce } from "../../../customHooks/useDebounce";

export default function ModalPagoEnPlazosCompra({
  open,
  total,
  submit,
  plazosPago,
  setPlazosPago,
  openBackdrop,
  plazosSelected,
  setPlazosSelected,
  cancel,
}) {
  const [loading, setLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescription = useDebounce(descripcion, 200);
  const { convertNumeroSinMiles } = useConversionNumeroMiles();

  useEffect(() => {
    getPlazosPago();
  }, [debouncedDescription]);

  const getPlazosPago = async () => {
    setLoading(true);
    try {
      const response = await request({
        method: "GET",
        url: readPlazosPagoCompra,
        params: { q: debouncedDescription, page: 1, all: 0 },
      });
      let plazosActivos = response.data
        .filter((plazo) => plazo.is_active === true)
        .sort((a, b) => a.dias - b.dias);

      plazosActivos = plazosActivos.map((obj) => ({ ...obj, monto: "" }));
      setPlazosPago(plazosActivos);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getSumaPagos = () => {
    const sumaPagos = plazosSelected.reduce((acc, plazo) => {
      return (
        (plazo.monto === ""
          ? 0
          : Number(convertNumeroSinMiles(plazo.monto.toString()))) + acc
      );
    }, 0);
    return sumaPagos.toFixed(2);
  };

  const getDiferencia = () => {
    const sumaPagos = getSumaPagos();
    const diferencia = Number(total).toFixed(2) - sumaPagos;
    return diferencia;
  };

  return (
    <ModalReutilizable
      title={"Configurar plazos de pago"}
      open={open}
      actions={
        <Actions
          cancel={cancel}
          diferencia={getDiferencia()}
          submit={submit}
          openBackdrop={openBackdrop}
        />
      }
      content={
        <Content
          plazos={plazosPago}
          setPlazosPago={setPlazosPago}
          sumaPagos={getSumaPagos()}
          diferencia={getDiferencia()}
          total={total}
          loading={loading}
          descripcion={descripcion}
          setDescripcion={setDescripcion}
          plazosSelected={plazosSelected}
          setPlazosSelected={setPlazosSelected}
        />
      }
      openBackdrop={openBackdrop}
    />
  );
}
