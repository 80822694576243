import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import useGetTiposCliente from "../../../../customHooks/useGetTiposCliente";
import { useDispatch } from "react-redux";

export default function FilterClientType({ filter, setFilter }) {
  const dispatch = useDispatch();
  const { tiposCliente } = useGetTiposCliente();

  const handleFilter = (value) => {
    dispatch(setFilter("tipoDeCliente", value));
  };

  return (
    <Grid item xs={12} sm={12} md={2} lg={2}>
      <TextField
        style={{ marginTop: 5 }}
        size="small"
        fullWidth
        select
        label="Tipo de cliente"
        name="tipoDeCliente"
        variant="outlined"
        value={filter}
        onChange={(e) => handleFilter(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      >
        <MenuItem value="todos">Todos</MenuItem>
        {tiposCliente.length > 0 &&
          tiposCliente.map((tipoCliente) => {
            return (
              <MenuItem key={tipoCliente.id} value={tipoCliente.id}>
                {tipoCliente.nombre}
              </MenuItem>
            );
          })}
      </TextField>
    </Grid>
  );
}
