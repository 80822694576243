import React from "react";
import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import wallet from "../../../Assets/Images/newWallet.png";

export default function SaldoBilletera({ montoBilletera }) {
  return (
    <Box>
      <Box
        style={{
          textAlign: "center",
          borderBottom: "solid 2px #cdced0",
        }}
      >
        <Typography
          style={{
            color: "#5e5f61",
            fontWeight: "bold",
            marginBottom: 0,
            marginTop: 3,
            textTransform: "capitalize",
          }}
        >
          SALDO DE BILLETERA
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <img
          src={wallet}
          className="imgWallet"
          style={{ width: 45, height: 45, margin: 0, marginRight: 8 }}
          alt="Billetera"
        />
        <Typography variant="h4" color="inherit">
          {`$ ${montoBilletera.toLocaleString("es-AR")}`}
        </Typography>
        {/* <TextField
          size="small"
          name="montocobrar"
          fullWidth
          label={"Saldo en billetera"}
          // disabled={true}

          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          value={montoBilletera.toLocaleString("es-AR")}
          variant="outlined"
          style={{ backgroundColor: "white" }}
        /> */}
      </Box>
    </Box>
  );
}
