import React from "react";
import { useFormik } from "formik";
import { estadoInicialForm } from "./utils";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Transferencia from "../../../MediosDePago/Transferencia";
import Cheque from "../../../MediosDePago/Cheque";
import Tarjetas from "../../../MediosDePago/Tarjetas";
import useConversionNumeroMiles from "../../../../../../customHooks/useConversionNumeroMiles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";

export default function FormularioCargaMediosPago({
  formik,
  modalidadCheques,
  filterMediosPago,
  date,
  tipoMovCtacte,
  montoASaldar,
  montoTotalCargado,
  montoBilletera,
}) {
  const { conversionNumero, convertNumeroSinMiles } =
    useConversionNumeroMiles();
  const agregaMedioPago = () => {
    let montoNuevoMedioPago = 0;
    let diferenciaMontosASaldar = Number(montoASaldar - montoTotalCargado);

    if (diferenciaMontosASaldar > 0) {
      montoNuevoMedioPago = diferenciaMontosASaldar;
    }

    let mediosPagoActualizados = [...formik.values.medios];

    mediosPagoActualizados.push({
      medioPago: null,
      monto: String(montoNuevoMedioPago).replace(".", ","),
      nroLote: "",
      nroAut: "",
      medioSelected: "",
      tarjetaSelected: null,
      coeficienteSelected: null,
      referenciaTarjeta: null,
      porcentajeSelected: 0,
      nroCheque: null,
      tipoCheque: 1,
      modalidadCheque: modalidadCheques.length && modalidadCheques[0].id,
      referenciaCheque: null,
      fechaEmisionCheque: date,
      fechaVencimientoCheque: date,
      propioCheque: false,
      bancoTransf: null,
      fecha_transferencia: date,
      referenciaTransf: null,
    });
    // Agregar medio de pago, como se agrega el nuevo medio de pago
    formik.setFieldValue("medios", mediosPagoActualizados);
  };

  // Eliminar medio de pago
  const elimnarMedioPago = (index) => {
    const mediosPagoActualizados = formik.values.medios.filter(
      (m, i) => i !== index,
    );
    formik.setFieldValue("medios", mediosPagoActualizados);
  };

  const onChageMedioPago = (event, index) => {
    const medioPago =
      event.target.value || event.target.value === 0
        ? filterMediosPago().find(
            (medio) => medio.idMedioPago == event.target.value,
          )
        : null;

    formik.setFieldValue(`medios[${index}].medioPago`, medioPago);
    formik.setFieldValue(
      `medios[${index}].medioSelected`,
      medioPago.idMedioPago,
    );
  };

  /**
   * Filtra los medios de pago para que no se pueda
   * seleccionar "Saldo en billetera" si ya se seleccionó otro medio de pago.
   */
  const filtrarMedioPagoBilleteraSeleccionado = (index) => {
    const selectedOptions = formik.values.medios
      .slice(0, index)
      .map((so) => (so.medioPago ? so.medioPago.nombre : "")); // Opciones seleccionadas antes de este índice
    if (selectedOptions.includes("Saldo en billetera")) {
      return filterMediosPago().filter(
        (medio) => medio.nombre !== "Saldo en billetera",
      );
    }
    return filterMediosPago();
  };

  return (
    <Box>
      <Box
        style={{
          textAlign: "center",
          borderBottom: "solid 2px #cdced0",
        }}
      >
        <Typography
          style={{
            color: "#5e5f61",
            fontWeight: "bold",
            marginBottom: 0,
            marginTop: 3,
            textTransform: "capitalize",
          }}
        >
          MEDIO DE PAGO
        </Typography>
      </Box>
      <Box p={1}>
        <Alert severity="info">
          Ahora podés hacer el pago con saldo en billetera o seleccionar
          distintos medios de pago. El sistema hará una carga de saldo
          automáticamente antes de registrar la conciliación.
        </Alert>
      </Box>
      <Box
        style={{
          padding: 8,
        }}
      >
        {formik.values.medios.map((medioPago, index) => {
          let labelMonto =
            medioPago &&
            medioPago.medioPago &&
            medioPago.medioPago.tipo === "Cheque"
              ? "Monto del cheque"
              : "Monto";

          return (
            <Box>
              <Box
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 8,
                  paddingBottom: 8,
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  style={{ backgroundColor: "white" }}
                  onWheel={(e) => e.target.blur()}
                  autoFocus
                  fullWidth
                  label={labelMonto}
                  value={conversionNumero(String(medioPago.monto))}
                  name={`medios.${index}.monto`}
                  onChange={(event) => {
                    formik.setFieldValue(
                      `medios.${index}.monto`,
                      event.target.value,
                    );
                  }} // convertir el número primero
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.medios &&
                    formik.errors.medios[index] &&
                    formik.errors.medios[index].monto
                  }
                  error={Boolean(
                    formik.errors.medios &&
                      formik.errors.medios[index] &&
                      formik.errors.medios[index].monto,
                  )}
                  InputProps={{
                    startAdornment: () => (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <Autocomplete
                  options={filtrarMedioPagoBilleteraSeleccionado(index)}
                  getOptionLabel={(option) => option.nombre}
                  value={medioPago.medioPago}
                  onChange={async (e, value) => {
                    await formik.setFieldValue(
                      `medios[${index}].medioPago`,
                      value,
                    );
                    await formik.setFieldValue(
                      `medios[${index}].medioSelected`,
                      value ? value.idMedioPago : "",
                    );
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      label="Medio de pago"
                      variant="outlined"
                      style={{ backgroundColor: "white" }}
                      name={`medios.${index}..medioSelected`}
                      value={medioPago.medioSelected}
                      helperText={
                        formik.errors.medios &&
                        formik.errors.medios[index] &&
                        formik.errors.medios[index].medioSelected
                      }
                      error={Boolean(
                        formik.errors.medios &&
                          formik.errors.medios[index] &&
                          formik.errors.medios[index].medioSelected,
                      )}
                    />
                  )}
                />

                {index > 0 && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      elimnarMedioPago(index);
                    }}
                  >
                    <SvgIcon>
                      <DeleteIcon color="error" />
                    </SvgIcon>
                  </IconButton>
                )}
              </Box>
              {medioPago.medioPago &&
                medioPago.medioPago.nombre === "Saldo en billetera" &&
                montoBilletera < montoASaldar &&
                montoBilletera <= 0 && (
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="error"
                      align="center"
                      style={{ fontWeight: "bolder" }}
                    >
                      No cuentas con saldo suficiente en la billetera.
                    </Typography>
                  </Box>
                )}
              {medioPago.medioPago &&
                medioPago.medioPago.nombre === "Saldo en billetera" &&
                montoBilletera < montoASaldar &&
                montoBilletera >= 0 && (
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      align="center"
                      style={{ fontWeight: "bolder" }}
                      color="error"
                    >
                      El saldo en billetera no es suficiente para cubrir el
                      total. Podes agregar más medios de pago o registrar un
                      pago parcial.
                    </Typography>
                  </Box>
                )}
              <Box>
                {medioPago.medioPago &&
                  medioPago.medioPago.tipo === "Tarjeta" && (
                    <Tarjetas
                      values={{
                        tarjetaSelected: medioPago.tarjetaSelected,
                        coeficienteSelected: medioPago.coeficienteSelected,
                        referenciaTarjeta: medioPago.referenciaTarjeta,
                        nroAut: medioPago.nroAut,
                      }}
                      errors={formik.errors}
                      touched={formik.touched}
                      mediosPago={medioPago.medioSelected}
                      montoMedio={medioPago.monto}
                      index={index}
                      porcentaje={medioPago.porcentajeSelected}
                      setFieldValue={formik.setFieldValue}
                    />
                  )}
                {medioPago.medioPago &&
                medioPago.medioPago.tipo === "Cheque" ? (
                  <Cheque
                    values={{
                      propioCheque: medioPago.propioCheque,
                      referenciaCheque: medioPago.referenciaCheque,
                      fechaVencimientoCheque: medioPago.fechaVencimientoCheque,
                      fechaEmisionCheque: medioPago.fechaEmisionCheque,
                      tipoCheque: medioPago.tipoCheque,
                      nroCheque: medioPago.nroCheque,
                      modalidadCheque: medioPago.modalidadCheque,
                    }}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleBlur={formik.handleBlur}
                    index={index}
                    setFieldValue={formik.setFieldValue}
                    modalidadCheques={modalidadCheques}
                  />
                ) : null}
                {medioPago.medioPago &&
                  medioPago.medioPago.tipo === "Transferencia" && (
                    <Transferencia
                      values={{
                        fecha_transferencia: medioPago.fecha_transferencia,
                        bancoTransf: medioPago.bancoTransf,
                        referenciaTransf: medioPago.referenciaTransf,
                      }}
                      setFieldValue={formik.setFieldValue}
                      index={index}
                    />
                  )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: 8,
          gap: 8,
        }}
      >
        <Button
          // style={{
          //   color: "grey",
          // }}
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={agregaMedioPago}
        >
          Agregar medio de pago
        </Button>
      </Box>
      <Box
        style={{
          padding: 8,
        }}
      >
        <Autocomplete
          style={{ paddingBottom: 8 }}
          options={tipoMovCtacte}
          getOptionLabel={(option) => option.nombre}
          value={
            formik.values.motivo
              ? tipoMovCtacte.find((tipo) => tipo.id === formik.values.motivo)
              : null
          }
          onChange={(e, value) => {
            formik.setFieldValue("motivo", value ? value.id : "");
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label="Motivo"
              variant="outlined"
              name="motivo"
              helperText={formik.touched.motivo && formik.errors.motivo}
              error={Boolean(formik.touched.motivo && formik.errors.motivo)}
            />
          )}
        />
        <TextField
          fullWidth
          size="small"
          style={{ backgroundColor: "white" }}
          export
          name="concepto"
          multiline
          inputProps={{ maxLength: 150 }}
          label={"Concepto"}
          placeholder="En concepto de"
          value={formik.values.concepto}
          onChange={formik.handleChange}
          helperText={`${formik.values.concepto.length} de 150 caracteres`}
          variant="outlined"
        />
      </Box>
    </Box>
  );
}
