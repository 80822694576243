import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { esNotaDeCredito } from "../../../components/ait-reusable/validateNotaDeCredito";

export const defaultValues = {
  razonSocial: "",
  puntoVenta: "",
  nroFactura: "",
  fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
  fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
  fechaVencimiento: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
  pagada: false,
  comprobante: "",
  cae: "",
  medio_pago: "",
  descuento: "0",
  montoSubtotal: "",
  montoDescuento: "",
  montoIva: "",
  montoPercepciones: "",
  montoTotal: "",
  responsable: null,
  compra: null,
  observacion: "",
  pagosAsociados: [],
  provincia: "",
};

export const valuesForm = (
  proveedor,
  dataForm,
  tipoComprobante,
  montos,
  compra,
  provincia,
) => {
  let values = {
    razonSocial: proveedor ? proveedor.razonSocial : "",
    puntoVenta: dataForm.puntoVenta,
    nroFactura: dataForm.nroFactura,
    fechaFacturacion: dataForm.fechaFacturacion,
    fechaImputacion: dataForm.fechaImputacion,
    fechaVencimiento: dataForm.fechaVencimiento,
    medio_pago: dataForm.medio_pago,
    pagada: false,
    comprobante: tipoComprobante ? tipoComprobante.nombre : "",
    cae: dataForm.cae,
    descuento: dataForm.descuento,
    montoSubtotal: montos.subtotal,
    montoDescuento: montos.descuento,
    montoIva: montos.iva,
    montoPercepciones: montos.percepciones,
    montoTotal: montos.total,
    responsable: dataForm.responsable,
    compra: compra ? compra.nro_factura : "",
    observacion: dataForm.observacion,
    pagosAsociados: dataForm.pagosAsociados,
    provincia: provincia,
    activar_plazos_pago: dataForm.activar_plazos_pago,
  };

  return values;
};

export const validateMaxLength = (e) => {
  switch (e.target.name) {
    case "puntoVenta":
      if (e.target.value.length > 4) {
        e.target.value = e.target.value.slice(0, 4);
      } else {
        if (e.target.value === "0000") {
          e.target.value = e.target.value.slice(0, 3);
        }
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    case "nroFactura":
      if (e.target.value.length > 8) {
        e.target.value = e.target.value.slice(0, 8);
      } else {
        if (e.target.value === "00000000") {
          e.target.value = e.target.value.slice(0, 7);
        }
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    case "cae":
      if (e.target.value.length > 14) {
        e.target.value = e.target.value.slice(0, 14);
      }
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
      break;
    case "percepcion":
      e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
      break;
    case "descuento":
      e.target.value = e.target.value.replace(/[^0-9]+/g, ".");
      break;
    default:
      break;
  }
};

export const useStyles = makeStyles((theme) => ({
  wrapperCardLeft: {
    height: "calc(100vh - 430px)",
    minHeight: 380,
    width: "50%",
    overflow: "auto",
    borderRadius: "25px 0px 0px 25px",
    backgroundColor: "#eef2f3",
    border: "1px solid #ada996 ",
  },
  wrapperCardRight: {
    height: "calc(100vh - 430px)",
    minHeight: 380,
    width: "50%",
    overflow: "auto",
    borderRadius: "0px 25px 25px 0px",
    backgroundColor: "#D4D3DD",
    border: "1px solid #ada996",
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    border: "solid 5px #0575e6",
  },
  inputProveedor: {
    borderRadius: "25px 25px 25px 25px",
  },
  buttonLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: "#0575e6",
    cursor: "pointer",
  },
  inputPercepcion: {
    "& .MuiOutlinedInput-root": {
      height: 25,
      fontSize: 15,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 5,
    },
  },
  spanPercepcion: {
    fontSize: 15,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const validateForm = (values) => {
  let validForm = true;
  let keys = [
    "razonSocial",
    "puntoVenta",
    "nroFactura",
    "fechaFacturacion",
    "comprobante",
    "cae",
    "compra",
  ];

  keys.forEach((key) => {
    if (key === "compra") {
      if (esNotaDeCredito(values) && values.compra === "") {
        validForm = false;
      }
    } else {
      if (values[key] === "") {
        validForm = false;
      }
    }
  });

  return validForm;
};

export const validateDescriptions = (detalles) => {
  let error = false;

  detalles.forEach((d) => {
    if (d.descripcionProveedor === "") {
      error = true;
    }
  });

  return error;
};
