import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseDate } from "../../../../../utils/parsers";
import NoValidoFactura from "./NoValidoFactura";

export default function HeaderA4({ conciliacion }) {
  return (
    <View
      style={{
        flexDirection: "row",
      }}
      fixed={false}
    >
      <View
        style={{
          flexDirection: "column",
          flex: 2,
        }}
      >
        <Text
          style={{
            flex: 1,
            textAlign: "right",
            fontSize: 17,
            marginBottom: 5,
            color: "#1B71BA",
          }}
        >
          {`Conciliación n° ${conciliacion.id}`}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
              paddingBottom: 20,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                flex: 2,
                paddingBottom: 20,
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 10,
                  marginBottom: 5,
                  marginLeft: 10,
                  color: "#1B71BA",
                  textTransform: "uppercase",
                }}
              >
                {conciliacion.sucursal.razonSocial}
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 10,
                  marginBottom: 0,
                  marginLeft: 10,
                  color: "#1B71BA",
                }}
              >
                COMPROBANTE DE PAGO
              </Text>

              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Text style={styles.newText}>Fecha: </Text>

                  <Text style={styles.newText}>CUIT: </Text>
                  <Text style={styles.newText}>Ing. Brutos: </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    flex: 2,
                  }}
                >
                  <Text style={styles.newText}>
                    {parseDate(conciliacion.fecha_hora, "DD/MM/YY HH:mm")}
                  </Text>

                  <Text style={styles.newText}>
                    {conciliacion.sucursal.CUIT}
                  </Text>
                  <Text style={styles.newText}>{`${
                    conciliacion.sucursal.ingresosBrutos || "N/A"
                  }`}</Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            <NoValidoFactura />
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Image
          cache={false}
          style={{ width: 152, height: 115, paddingBottom: 3 }}
          allowDangerousPaths={true}
          src={
            conciliacion.sucursal.logo_base64 &&
            `data:image/png/jpg;base64,${conciliacion.sucursal.logo_base64}`
          }
        />
      </View>
    </View>
  );
}
