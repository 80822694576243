import React, { useState, useEffect } from "react";
import { Container, Backdrop, CircularProgress, Chip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import PageTitle from "../../../components/common/PageTitle";
import Formulario from "./Formulario";
import request from "../../../requests/request";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useVatTypes from "../../../customHooks/useVatTypes";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { useSelector } from "react-redux";
import { useStyles } from "./utils";
import {
  postPedido,
  getEstados,
  putPedido,
  // readPedido,
} from "../../../requests/urls";
import {
  clearPedido,
  // setearDatosDePedido,
} from "../../../Redux/Actions/pedidoActions";
import { useParams } from "react-router-dom";
import moment from "moment";

const Pedidos = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { pedidos } = useSelector((state) => state.pedido);
  const [estados, setEstados] = useState([]);
  const [hiddenChip, setHiddenChip] = useState(false);
  const [estadoSelected, setEstadoSelected] = useState(null);
  const [responsableDeSesion, setResponsableDeSesion] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataPedido, setDataPedido] = useState(null);
  const history = useHistory();
  const vattTypes = useVatTypes();
  const { idPedido } = useParams();

  // const handleGetPedido = async () => {
  //   try {
  //     const response = await request({
  //       method: "GET",
  //       url: readPedido(idPedido),
  //     });
  //     console.log(response.data);
  //     setDataPedido(response.data);
  //     dispatch(setearDatosDePedido(response.data));
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleGetEstados = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getEstados,
      });
      const { status } = response;
      status === 200 ? setEstados(response.data.data) : setEstados([]);
    } catch (error) {
      console.error(error);
    }
  };

  const hayUnPrecioMenorACero = (array) => {
    for (let item of array) {
      if (parseFloat(item.precio) < 0) {
        return true;
      }
    }
    return false;
  };

  const handlePostPedido = async ({ resetForm, values }) => {
    if (pedidos.length === 0) {
      errorNotification("El carrito no puede estar vacío");
    } else {
      try {
        let newPedido = await getNewPedido();
        const productoConPrecioMenorACero = hayUnPrecioMenorACero(newPedido);
        if (productoConPrecioMenorACero) {
          errorNotification(
            "No se puede realizar un pedido con un precio menor a 0",
          );
          return;
        }
        setOpenBackdrop(true);
        const response = await request({
          method: "POST",
          url: postPedido,
          data: {
            proveedor: values.proveedor.idProveedor,
            emision: moment(values.fechaEmision).format("YYYY-MM-DD HH:mm:SS"),
            entrega: moment(values.fechaEntrega).format("YYYY-MM-DD HH:mm:SS"),
            pedidos: newPedido,
            estado: values.estado,
            observacion: values.observacion || "",
            alias: values.alias,
            responsable: values.responsable,
          },
        });
        dispatch(clearPedido(responsableDeSesion));
        successNotification("Pedido realizado con éxito");
        setOpenBackdrop(false);
        resetForm();
      } catch (error) {
        console.log(error);
        errorNotification(
          "Ha ocurrido un error al intentar realizar el pedido",
        );
        setOpenBackdrop(false);
      }
    }
  };

  const handlePutPedido = async (values) => {
    if (pedidos.length === 0) {
      errorNotification("El carrito no puede estar vacío");
    } else {
      try {
        let newPedido = await getNewPedidoEdit();
        const productoConPrecioMenorACero = hayUnPrecioMenorACero(newPedido);
        if (productoConPrecioMenorACero) {
          errorNotification(
            "No se puede realizar un pedido con un precio menor a 0",
          );
          return;
        }
        setOpenBackdrop(true);
        const response = await request({
          method: "PUT",
          url: putPedido,
          data: {
            pedido_id: idPedido,
            proveedor: values.proveedor.idProveedor,
            emision: moment(values.fechaEmision).format("YYYY-MM-DD HH:mm:SS"),
            entrega: moment(values.fechaEntrega).format("YYYY-MM-DD HH:mm:SS"),
            pedidos: newPedido,
            estado: values.estado,
            observacion: values.observacion ? values.observacion : "",
            alias: values.alias,
            responsable: values.responsable,
          },
        });
        dispatch(clearPedido(""));
        successNotification("El pedido se ha editado exitosamente");
        setOpenBackdrop(false);
        history.push("/informe/pedidos/");
      } catch (error) {
        console.error(error);
        errorNotification("No se ha podido editar el pedido.");
        setOpenBackdrop(false);
      }
    }
  };

  const handleSubmitPedido = ({ resetForm, values }) => {
    if (idPedido) {
      handlePutPedido(values);
    } else {
      handlePostPedido({ resetForm, values });
    }
  };

  const getNewPedido = async () => {
    let newPedido = [];
    let pedidosFilter = pedidos.filter((p) => p.is_deleted === false);
    pedidosFilter.forEach((pedido) => {
      newPedido.push({
        idRepuestoProveedor: pedido.idRepuestoProveedor,
        codProveedor: pedido.codProveedor,
        codOriginal: pedido.codOriginal,
        descripcion: pedido.descripcionProveedor,
        cantidad: pedido.cantidad,
        precio: Number(pedido.precios.costo).toFixed(2),
      });
    });
    return newPedido;
  };

  const getNewPedidoEdit = async () => {
    let newPedido = [];
    pedidos.forEach((pedido) => {
      newPedido.push({
        id: pedido.id ? pedido.id : null,
        idRepuestoProveedor: pedido.idRepuestoProveedor,
        codProveedor: pedido.codProveedor,
        codOriginal: pedido.codOriginal,
        descripcion: pedido.descripcionProveedor || null,
        cantidad: pedido.cantidad,
        precio: Number(pedido.precios.costo).toFixed(2),
        is_deleted: pedido.is_deleted,
      });
    });
    return newPedido;
  };

  const handleDelete = () => {
    setHiddenChip(true);
  };

  useEffect(() => {
    handleGetEstados();
  }, []);

  useEffect(() => {
    setDataPedido(pedidos);
    // if (idPedido && !desdeInforme) {
    //   handleGetPedido();
    // } else {
    //   setDataPedido(pedidos);
    // }
  }, []);

  return (
    <>
      {idPedido ? (
        <Container maxWidth={true}>
          <PageTitle
            title={`Pedido ${idPedido ? idPedido : ""}`}
            subtitle={`Editar pedido`}
            className="text-center  text-lg-left page-header py-2"
          />

          {!hiddenChip && (
            <Chip
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 3,
                marginRight: 3,
                width: "100%",
              }}
              size="small"
              color="primary"
              onDelete={handleDelete}
              label="Si eliminas o cambias el proveedor que seleccionaste, la tabla de artículos que cargaste tambien se eliminará. Recuerda que los artículos se cargan por proveedor. "
              icon={<InfoIcon />}
            />
          )}
          <Formulario
            vattTypes={vattTypes}
            handleSubmitPedido={handleSubmitPedido}
            estados={estados}
            setEstadoSelected={setEstadoSelected}
            setResponsableDeSesion={setResponsableDeSesion}
            responsableDeSesion={responsableDeSesion}
            idPedido={idPedido}
            dataPedido={dataPedido}
            pedidos={pedidos}
            {...props}
          />
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      ) : (
        <>
          {!hiddenChip && (
            <Chip
              style={{
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 3,
                marginRight: 3,
                width: "100%",
              }}
              size="small"
              color="primary"
              onDelete={handleDelete}
              label="Si eliminas o cambias el proveedor que seleccionaste, la tabla de artículos que cargaste tambien se eliminará. Recuerda que los artículos se cargan por proveedor. "
              icon={<InfoIcon />}
            />
          )}
          <Formulario
            vattTypes={vattTypes}
            handleSubmitPedido={handleSubmitPedido}
            estados={estados}
            setEstadoSelected={setEstadoSelected}
            setResponsableDeSesion={setResponsableDeSesion}
            responsableDeSesion={responsableDeSesion}
            idPedido={idPedido}
            dataPedido={dataPedido}
            pedidos={pedidos}
            {...props}
          />
          <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

export default Pedidos;
