import React from "react";
import { Divider, Grid, Typography, Chip } from "@material-ui/core";
import { dates, formatDetalles, relevantData } from "./formatters";
import { TypographyRow } from "./TypographyRow";
import ReusableTable from "../../../../../components/ait-reusable/Report/ReusableTable";
import TableTotales from "./TableTotales";
import ButtonCancelar from "../../../../../components/ait-reusable/Button/ButtonCancelar";
import InfoIcon from "@material-ui/icons/Info";

export default function Detalles({
  compra,
  classes,
  volverModal,
  showChip,
  toggle,
  deleteChip,
}) {
  return (
    <Grid container>
      <Grid container item justify={"center"} className="mb-2">
        <Grid xs={6} item>
          {dates(compra).map((d) => (
            <TypographyRow classes={classes} d={d} />
          ))}
        </Grid>
        <Grid xs={6} item>
          {relevantData(compra).map((d) => (
            <TypographyRow classes={classes} d={d} />
          ))}
        </Grid>
        <Grid xs={12} item>
          <TypographyRow
            classes={classes}
            d={{
              k: "Observación: ",
              v: compra.observaciones ? compra.observaciones : "---",
            }}
          />
        </Grid>
        {compra.plazos_pago && (
          <Grid xs={12} item>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Plazos de pago:
            </Typography>
            {compra.plazos_pago.map((plazo, index) => (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ paddingLeft: 8 }}>
                    {`- ${index + 1}° plazo (${plazo.dias} días): $ ${Number(
                      plazo.monto,
                    ).toLocaleString("es-AR")}`}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      {!compra.detalles.length && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}

      <Grid item container>
        {compra.detalles.length ? (
          <ReusableTable
            columns={
              compra.rapida
                ? ["Articulo", "Cantidad"]
                : ["Articulo", "$/u", "Cantidad", "Subtotal"]
            }
            items={formatDetalles(compra)}
          />
        ) : (
          <Grid item xs={12} style={{ paddingTop: "0.5em" }}>
            <Typography align="center" color="textSecondary">
              Esta compra no posee articulos.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid xs={12} item container justify={"flex-end"}>
        <Grid xs={6} item>
          <TableTotales compra={compra} classes={classes} />
        </Grid>
      </Grid>

      {volverModal ? (
        <Grid
          xs={12}
          container
          fullWidth
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonCancelar message="Volver" click={toggle} />
        </Grid>
      ) : (
        <Grid
          xs={12}
          container
          fullWidth
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonCancelar message="Salir" click={toggle} />
        </Grid>
      )}
      <Grid item container justifyContent="center" xs={12}>
        {showChip && compra.rapida && (
          <Chip
            color="primary"
            size="small"
            onDelete={deleteChip}
            label="Esta es una compra rápida. Las compras rapidas no manejan precios por artículo."
            icon={<InfoIcon />}
          />
        )}
      </Grid>
    </Grid>
  );
}
