import React from "react";
import { Grid } from "@material-ui/core";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";
import TooltipWithoutIcon from "../TooltipMoreInfo/TooltipWithoutIcon";

export default function Actions({ cancel, diferencia, submit, openBackdrop }) {
  return (
    <>
      <ButtonCancelar click={() => cancel()} message={"CANCELAR"} />
      {Number(diferencia) !== 0 ? (
        <TooltipWithoutIcon
          placement={"top"}
          body={
            <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
              {diferencia > 0
                ? "Debe completar el monto pendiente"
                : "El monto de pago excede el monto total de la compra"}
            </h6>
          }
        >
          <span>
            <ButtonAceptar
              click={() => {}}
              message={"ACEPTAR"}
              disabled={true}
            />
          </span>
        </TooltipWithoutIcon>
      ) : (
        <ButtonAceptar
          click={() => submit()}
          message={"ACEPTAR"}
          disabled={openBackdrop}
        />
      )}
    </>
  );
}
