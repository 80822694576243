import React from "react";
import { TableCell } from "@material-ui/core";
export const StockDisponible = ({ articulo }) => {
  return (
    <span
      style={{
        color: articulo
          ? articulo.stockDisponible > 0
            ? "green"
            : "red"
          : "black",
        fontWeight: "bold",
      }}
    >
      {articulo ? articulo.stockDisponible : "---"}
    </span>
  );
};

export const StockDeseado = ({ articulo }) => {
  return (
    <span
      style={{
        color: articulo
          ? articulo.stockDisponible > 0
            ? "green"
            : "red"
          : "black",
        fontWeight: "bold",
      }}
    >
      {articulo ? articulo.stock_deseado : "---"}
    </span>
  );
};

const StockDisponibleSucursal = ({ stock }) => {
  return (
    <span
      style={{
        color: stock !== null ? (stock > 0 ? "green" : "red") : "black",
        fontWeight: "bold",
      }}
    >
      {stock !== null ? stock : "---"}
    </span>
  );
};

export const StockSucursales = ({ item, sucursales }) => {
  if (
    item.repuesto_sucursal &&
    item.repuesto_sucursal.codigo_vinculacion_sucursal
  ) {
    return sucursales.map((s, i) => {
      const stockSucursal = item.repuesto_sucursal.sucursales.find(
        (i) => i.sucursal_id === s.id,
      );

      return (
        <TableCell align="center" key={i}>
          <StockDisponibleSucursal
            stock={stockSucursal && stockSucursal.stock}
          />
        </TableCell>
      );
    });
  } else {
    return sucursales.map((s, i) => {
      return (
        <TableCell align="center" key={i}>
          <span style={{ color: "black", fontWeight: "bold" }}>---</span>
        </TableCell>
      );
    });
  }
};
