import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Col } from "shards-react";
import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarNavItems from "./SidebarNavItems";

export default function MainSidebar() {
  const isOpen = useSelector((state) => state.sidebar.isOpen);

  const classes = classNames("main-sidebar", "px-0", "col-12", {
    open: isOpen,
  });

  return (
    <Col tag="aside" className={classes} lg={{ size: 2 }} md={{ size: 3 }}>
      <SidebarMainNavbar />
      <SidebarNavItems />
    </Col>
  );
}
