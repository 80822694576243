import React from "react";
import { useDispatch } from "react-redux";
import { openSidebar } from "../../../Redux/Actions/sidebarActions";

export default function NavbarToggle() {
  const dispatch = useDispatch();

  const handleOpenSidebar = () => {
    dispatch(openSidebar());
  };

  return (
    <nav className="nav">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={handleOpenSidebar}
        className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-inline d-lg-none text-center"
        style={{ cursor: "pointer" }}
      >
        <i className="material-icons">&#xE5D2;</i>
      </a>
    </nav>
  );
}
