import request from "../../requests/request";
import { get_ConfigGeneral, put_ConfigGeneral } from "../../requests/urls";
import { GET_CONFIGURACION_GENERAL } from "./types";

export const getConfigGeneral = () => {
  return (dispatch) => {
    request({ method: "GET", url: get_ConfigGeneral })
      .then((res) => {
        if (res.status === 200) {
          dispatch(config_general(res.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const updateConfigGeneral = (storedConfig) => async (dispatch) => {
  try {
    const response = await request({
      method: "PUT",
      url: put_ConfigGeneral,
      data: {
        ...storedConfig,
        tipo_venta: Array.isArray(storedConfig.tipo_venta)
          ? storedConfig.tipo_venta.map((d) => d.id)[0]
          : storedConfig.tipo_venta,
        json_leyendas: storedConfig.json_leyendas
          ? JSON.stringify(storedConfig.json_leyendas)
          : null,
        leyendas: storedConfig.activar_leyenda ? true : false,
      },
    });

    if (response.status === 201) {
      dispatch(config_general(response.data));
    } else {
      console.error(
        "Error al sincronizar la configuración general con el servidor.",
      );
    }
  } catch (error) {
    console.error(
      "Error en la actualización de la configuración general con el servidor:",
      error,
    );
  }
};

export const config_general = (data) => {
  return { type: GET_CONFIGURACION_GENERAL, data };
};
