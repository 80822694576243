import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCompraRapida } from "../../Redux/Actions/compraRapidaActions";
import FormCompraRapida from "./Formulario/FormCompraRapida";
import { Backdrop, Button, Card, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import request from "../../requests/request";
import { postCompra, validateCompra } from "../../requests/urls";
import { getNewDetalle, getNewPer, getNewVats } from "./utils";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import { useSearchEmpleado } from "../../customHooks/useSearchEmpleado";
import { Alert, AlertTitle } from "@material-ui/lab";
import { obtenerEmpleadoPorDefecto } from "../Compras/Formulario/utils";
import { getIdPagos } from "../Compras/utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useConversionNumeroMiles from "../../customHooks/useConversionNumeroMiles";

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ComprasRapidas() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loadingCompra, setLoadingCompra] = useState(false);
  const {
    proveedor,
    dataForm,
    tipoComprobante,
    detalles,
    vattSelected,
    montos,
    compra,
    generarNotaCredito,
    notaCreditoAsociadas,
    compra_importacion_afip,
    provincia,
  } = useSelector((state) => state.compraRapida);
  const { useEmpleado } = useSearchEmpleado();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { convertNumeroSinMiles } = useConversionNumeroMiles();
  const [openModalPlazos, setOpenModalPlazos] = useState(false);

  const esSinIva = () => {
    if (tipoComprobante) {
      if (
        tipoComprobante.nombre === "Factura C" ||
        tipoComprobante.nombre === "Comprobante interno" ||
        tipoComprobante.nombre === "Factura B" ||
        tipoComprobante.nombre === "Notas de Crédito B" ||
        tipoComprobante.nombre === "Notas de Crédito C" ||
        tipoComprobante.nombre === "Notas de Crédito X"
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handlePostCompra = async (
    resetForm,
    setFieldValue,
    comprobantes,
    plazosSelected,
  ) => {
    let copyComprobante = tipoComprobante ? tipoComprobante.nombre : "";
    let newDetalle = await getNewDetalle(detalles, tipoComprobante);
    let newVats = await getNewVats(vattSelected);
    let newPercepciones = await getNewPer(dataForm.percepciones);
    let mTotal =
      tipoComprobante && tipoComprobante.nombre === "Notas de Crédito Descuento"
        ? await calcularTotalPagos()
        : montos.total;
    let idPagos = getIdPagos(dataForm.pagosAsociados);
    let dataPlazos = dataForm.activar_plazos_pago
      ? plazosSelected.map((plazo) => {
          return {
            dias: plazo.dias,
            monto: Number(convertNumeroSinMiles(plazo.monto.toString())),
          };
        })
      : null;

    try {
      const response = await request({
        method: "POST",
        url: postCompra,
        data: {
          cae: dataForm.cae,
          detalles: newDetalle,
          descuento: parseFloat(dataForm.descuento).toFixed(2),
          documento: proveedor.CUIT,
          fecha_facturacion: dataForm.fechaFacturacion,
          fecha_imputacion: dataForm.fechaImputacion,
          fecha_vencimiento: dataForm.fechaVencimiento,
          medio_pago:
            dataForm.medio_pago && dataForm.medio_pago !== ""
              ? dataForm.medio_pago
              : null,
          monto_total: mTotal,
          nro_factura: dataForm.nroFactura,
          pagada:
            tipoComprobante.idTipoComprobante === 3 ? false : dataForm.pagada,
          percepciones: newPercepciones,
          proveedor: proveedor.idProveedor,
          punto_venta: dataForm.puntoVenta,
          tipo_comprobante: tipoComprobante.idTipoComprobante,
          tipo_factura: tipoComprobante.idTipoFactura,
          compra_asociada: compra ? compra.id : null,
          responsable: dataForm.responsable
            ? dataForm.responsable.idEmpleado
            : null,
          vats: esSinIva() ? [] : newVats,
          rapida: true,
          observacion:
            dataForm.observacion === "" ? null : dataForm.observacion,
          es_descuento:
            compra || !dataForm.pagosAsociados.length ? false : true,
          ids_pagos: idPagos,
          compra_importacion_afip: compra_importacion_afip,
          provincia: provincia,
          plazos_pago: dataPlazos,
        },
      });
      const facturaTipoA = comprobantes.filter((c) => c.nombre === "Factura A");
      setOpenBackdrop(false);
      resetForm();
      successNotification(
        `${
          copyComprobante.includes("Notas de Crédito")
            ? "Nota de Crédito"
            : "Compra"
        } realizada con éxito`,
      );
      setFieldValue("comprobante", "Factura A");
      let respSelected = obtenerEmpleadoPorDefecto(useEmpleado);
      setFieldValue("responsable", respSelected);
      setOpenModalPlazos(false);
      dispatch(clearCompraRapida(facturaTipoA[0], respSelected));
      compra_importacion_afip && history.push("/compras-afip");
      setLoadingCompra(false);
    } catch (error) {
      console.error(error);
      console.log(error.request);
      setOpenBackdrop(false);
      setLoadingCompra(false);
      errorNotification(
        `Error al realizar la ${
          copyComprobante.includes("Notas de Crédito")
            ? "nota de Crédito"
            : "compra"
        }`,
      );
    }
  };

  const calcularTotalPagos = async () => {
    let total = dataForm.pagosAsociados.reduce((a, pago) => {
      return a + Number(pago.monto_descuento) * -1;
    }, 0);

    return Number(total.toFixed(2));
  };

  const existeLaCompra = async (
    values,
    resetForm,
    setFieldValue,
    comprobantes,
    plazosSelected,
  ) => {
    setOpenBackdrop(true);
    try {
      const { data } = await request({
        method: "GET",
        url: validateCompra(proveedor.idProveedor),
        params: {
          punto_venta: dataForm.puntoVenta,
          nro_factura: dataForm.nroFactura,
          tipo_comprobante: tipoComprobante.idTipoComprobante,
          tipo_factura: tipoComprobante.idTipoFactura,
        },
      });
      postValidation(
        data,
        values,
        resetForm,
        setFieldValue,
        comprobantes,
        plazosSelected,
      );
    } catch (error) {
      setOpenBackdrop(false);
      setLoadingCompra(false);
      console.error(error);
    }
  };

  const postValidation = (
    data,
    values,
    resetForm,
    setFieldValue,
    comprobantes,
    plazosSelected,
  ) => {
    if (data === true) {
      setOpenBackdrop(false);
      setLoadingCompra(false);
      return errorNotification("La compra ya existe.");
    } else {
      handlePostCompra(
        values,
        resetForm,
        setFieldValue,
        comprobantes,
        plazosSelected,
      );
    }
  };

  useEffect(() => {
    setOpenBackdrop(true);
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 400);
  }, []);

  return (
    <>
      {generarNotaCredito && (
        <Alert severity="error">
          <AlertTitle>GENERAR NOTA DE CRÉDITO DE COMPRA</AlertTitle>
          {notaCreditoAsociadas && (
            <React.Fragment>
              ESTA COMPRA{" "}
              <strong style={{ fontWeight: "bolder" }}>
                POSEE NOTAS DE CRÉDITO ASOCIADAS
              </strong>
              . NO SE DESCUENTAN DE LA COMPRA, LAS CANTIDADES DE LAS NOTA DE
              CRÉDITO EXISTENTES.
            </React.Fragment>
          )}
        </Alert>
      )}

      {compra_importacion_afip && (
        <Alert severity="info">
          <AlertTitle>COMPRA IMPORTADA DESDE AFIP</AlertTitle>

          <React.Fragment>
            Si desea generar una compra que no este vinculada con una compra de
            afip presione{" "}
            <Button
              color="primary"
              variant={"outlined"}
              size={"small"}
              onClick={() => window.location.reload()}
            >
              AQUÍ
            </Button>{" "}
          </React.Fragment>
        </Alert>
      )}
      <FormCompraRapida
        handlePostCompra={existeLaCompra}
        loadingCompra={loadingCompra}
        setLoadingCompra={setLoadingCompra}
        openBackdrop={openBackdrop}
        openModalPlazos={openModalPlazos}
        setOpenModalPlazos={setOpenModalPlazos}
      />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
