import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useObtenerConciliacionVentas } from "../../../services/conciliacionVentas";
import ConciliacionVentas from "./components/ConciliacionVentas";
import ButtonCancelar from "../Button/ButtonCancelar";
import { ButtonModalMaterial } from "../ButtonModalMaterial";

export default function ModalDetalleConciliacionVentas({
  conciliacion_ventas_id,
  toggle,
  onDeletePagoConciliacion,
}) {
  const conciliacionVentas = useObtenerConciliacionVentas({
    queryParams: { conciliacionVentasId: conciliacion_ventas_id },
  });

  return (
    <Dialog open={true} onClose={toggle}>
      {conciliacionVentas.isLoading ||
        (conciliacionVentas.isFetching && (
          <DialogContent>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 200,
              }}
            >
              <CircularProgress />
              <Typography>Cargando conciliación...</Typography>
            </Box>
          </DialogContent>
        ))}

      {!conciliacionVentas.isLoading &&
        !conciliacionVentas.isFetching &&
        conciliacionVentas.data &&
        !conciliacionVentas.error && (
          <>
            <ButtonModalMaterial
              onClose={toggle}
            >{`Detalles de la conciliación n° ${conciliacionVentas.data.id}`}</ButtonModalMaterial>
            <DialogContent dividers>
              <ConciliacionVentas
                onDeletePagoConciliacion={onDeletePagoConciliacion}
                conciliacionVentas={conciliacionVentas.data}
                refetch={conciliacionVentas.refetch}
              />
            </DialogContent>
            <DialogActions>
              <ButtonCancelar
                click={toggle}
                color="primary"
                message={"Cerrar"}
              />
            </DialogActions>
          </>
        )}
    </Dialog>
  );
}
