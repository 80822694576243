import React from "react";
import { TableContainer, Table } from "@material-ui/core";
import NoResults from "../../../../../components/ait-reusable/NoResults";
import Spinner from "../../../../../components/Spinner";
import HeaderTable from "./HeaderTable";
import BodyTable from "./BodyTable";

const TablaComprobantes = ({
  loading,
  columns,
  items,
  handleClickRow,
  noResults,
  colorRow,
  children,
  mostrarFilasPlazo,
  getStyleCursor,
  applyBorder,
}) => {
  return (
    <>
      {children}
      <TableContainer>
        <Table
          name="report-table"
          stickyHeader
          size="small"
          aria-label="a dense table"
        >
          <HeaderTable columns={columns} />
          {!loading ? (
            <BodyTable
              items={items}
              handleClickRow={handleClickRow}
              colorRow={colorRow}
              mostrarFilasPlazo={mostrarFilasPlazo}
              getStyleCursor={getStyleCursor}
              applyBorder={applyBorder}
            />
          ) : null}
        </Table>
      </TableContainer>
      {loading ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : items.length === 0 ? (
        <NoResults noResult={noResults} />
      ) : null}
    </>
  );
};

export default TablaComprobantes;
