import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import useDisclosure from "../../../customHooks/useDisclosure";
import { VIDEO_EXPLICACION_CONCILIACIONES } from "./utils";

export default function BotonesResumenDeuda({
  props,
  setOpenModalCargarSaldo,
  idCliente,
}) {
  const [
    openedConfirmInfoConc,
    { open: openConfirmInfoConc, close: closeConfirmInfoConc },
  ] = useDisclosure();
  const [noMostrarMensaje, setNoMostrarMensaje] = React.useState(false);

  const aceptarConfirmInfoConc = () => {
    if (noMostrarMensaje) {
      localStorage.setItem("noMostrarMensajeCache", "true");
      setNoMostrarMensaje(false);
    }
    closeConfirmInfoConc();
    setOpenModalCargarSaldo();
  };

  const handleOpenModalConfirmInfoConc = () => {
    const noMostrarMensajeCache = localStorage.getItem("noMostrarMensajeCache");

    if (noMostrarMensajeCache === "true") {
      setOpenModalCargarSaldo();
      return;
    }
    openConfirmInfoConc();
  };

  return (
    <div
      style={{
        marginTop: 5,
        display: "inline-flex",
        width: "100%",
      }}
    >
      <Button
        style={{
          borderRadius: 4,
          paddingBottom: 5,
          paddingTop: 5,
          width: "50%",
          marginRight: 5,
        }}
        className="mb-2 font-weight-bold"
        color="primary"
        variant="outlined"
        fullWidth
        size="large"
        onClick={() =>
          props.history.push(`/cliente/billetera-virtual/${idCliente}`)
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <span className="material-icons mr-2">account_balance_wallet</span>
          IR A MI BILLETERA
        </div>
      </Button>
      <Button
        style={{
          borderRadius: 4,
          paddingBottom: 5,
          paddingTop: 5,
          marginTop: 0,
          marginLeft: 5,
          width: "50%",
        }}
        color="primary"
        variant="outlined"
        fullWidth
        className="mb-2 font-weight-bold"
        size="large"
        onClick={handleOpenModalConfirmInfoConc}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <span className="material-icons mr-2">paid</span>
          CARGAR SALDO
        </div>
      </Button>

      <ModalConfirm
        open={openedConfirmInfoConc}
        handleSubmit={aceptarConfirmInfoConc}
        handleClose={closeConfirmInfoConc}
        title={"Simplificamos las conciliaciones 🎉"}
        body={
          <Box>
            <Typography variant="body1" style={{ fontWeight: "bolder" }}>
              ¿Sabías que ya no necesitas cargar saldo antes de conciliar?
            </Typography>
            <Typography variant="body1">
              Antes, para saldar comprobantes pendientes, necesitabas cargar
              saldo manualmente en la billetera.
            </Typography>
            <Typography variant="body1" style={{ fontWeight: "bolder" }}>
              ¡Ahora ya no hace falta! Podés elegir los medios de pago
              directamente al conciliar y el sistema hará la carga de saldo
              automáticamente.
            </Typography>
            <Typography variant="body1">
              <a href={VIDEO_EXPLICACION_CONCILIACIONES} target="_blank">
                Hacé clic acá
              </a>{" "}
              y te mostramos cómo funciona.
            </Typography>
            <FormControlLabel
              style={{ margin: 0, marginTop: 10 }}
              control={
                <Checkbox
                  checked={noMostrarMensaje}
                  onChange={() => setNoMostrarMensaje(!noMostrarMensaje)}
                  name="noMostrarMensaje"
                  size="small"
                  color="primary"
                />
              }
              color="primary"
              label="No volver a mostrar este mensaje."
            />
          </Box>
        }
        buttons={{ cancel: "Cancelar", submit: "Cargar saldo" }}
      />
    </div>
  );
}
