import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";
import { textAbbreviation } from "../../DeVentas/Components/utils";

export default function TotalesTFactura({ nota, vat, factura }) {
  const isGreaterThanOrEqualToOneMillion = Number(nota.monto) > 1000000;

  const addTextEmpty = (text) => {
    let value = false;
    let newText = textAbbreviation(text);

    if (
      (isGreaterThanOrEqualToOneMillion && newText.length > 19) ||
      (!isGreaterThanOrEqualToOneMillion && newText.length > 22)
    ) {
      value = true;
    }
    return value;
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
      }}
    >
      <View
        style={[
          styles.containerColumn,
          { width: isGreaterThanOrEqualToOneMillion ? "75%" : "82%" },
        ]}
      >
        <Text style={styles.textTotalesFactura}>
          {factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M"
            ? "SUBTOTAL"
            : "TOTAL"}
        </Text>
        <Text style={styles.textTotalesFactura}>
          {(factura.idTipoFactura.nombre === "A" ||
            factura.idTipoFactura.nombre === "M") &&
            `IVA ${"21%" || ""}`}
        </Text>
        {(factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M") &&
          nota.datos_afip.taxes.map((tax, index) => (
            <Text key={index} style={styles.textTotalesFacturaAlignLeft}>
              {`${textAbbreviation(tax.tax_type.description).toUpperCase()} - ${Number(
                tax.aliquot,
              )}%`}
            </Text>
          ))}
        {(factura.idTipoFactura.nombre === "A" ||
          factura.idTipoFactura.nombre === "M") && (
          <Text style={styles.textTotalesFactura}>TOTAL</Text>
        )}
      </View>
      <View style={styles.containerColumn2}>
        <View>
          <Text style={styles.textTotalesFactura}>
            {(factura.idTipoFactura.nombre === "A" ||
              factura.idTipoFactura.nombre === "M") &&
              `$ ${Number(
                Number(nota.monto - vat.amount).toFixed(2),
              ).toLocaleString("es-AR")}`}
          </Text>
          <Text style={styles.textTotalesFactura}>
            {(factura.idTipoFactura.nombre === "A" ||
              factura.idTipoFactura.nombre === "M") &&
              `${parseCurrency(Number(vat.amount))}`}
          </Text>
          {(factura.idTipoFactura.nombre === "A" ||
            factura.idTipoFactura.nombre === "M") &&
            nota.datos_afip.taxes.map((tax, index) => (
              <Fragment key={index}>
                <Text key={index} style={styles.textTotalesFactura}>
                  {parseCurrency(tax.amount)}
                </Text>
                {addTextEmpty(tax.tax_type.description) ? (
                  <Text
                    style={[styles.textTotalesFactura, { paddingTop: 6 }]}
                  ></Text>
                ) : null}
              </Fragment>
            ))}
          <Text style={styles.textTotalesFactura}>
            {`${parseCurrency(Number(nota.monto))}`}
          </Text>
        </View>
      </View>
    </View>
  );
}
