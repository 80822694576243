import request from "../requests/request";
import { useQuery } from "react-query";

export const conciliacionVentasServices = {
  obtenerConciliacionVentas: async ({ conciliacionVentasId }) => {
    const response = await request({
      method: "GET",
      url: `/api/pagos/obtener-conciliacion-ventas/${conciliacionVentasId}/`,
    });
    return response.data;
  },
};

export const useObtenerConciliacionVentas = ({
  queryParams,
  queryProps,
} = {}) => {
  return useQuery({
    queryKey: ["listaMedioPago", queryParams],
    queryFn: () =>
      conciliacionVentasServices.obtenerConciliacionVentas({ ...queryParams }),
    ...queryProps,
  });
};
