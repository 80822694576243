import React, { useEffect, useState } from "react";
import {
  Divider,
  InputAdornment,
  List,
  ListItem,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  SvgIcon,
  CircularProgress,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Encabezado from "./EncabezadoPagoFacturas";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Tarjetas from "./MediosDePago/Tarjetas";
import Cheque from "./MediosDePago/Cheque";
import request from "../../../requests/request";
import {
  getModalidadCheques,
  postCargarSaldo,
  postRetirarSaldo,
  saldarVentas,
} from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { Formik, FieldArray } from "formik";
import getTiposMovimientosCtaCte, {
  DataPostBilletera,
  DataSaldarVenta,
  initialValues,
  validationYup,
} from "./utils";
import { useParams } from "react-router-dom";
import { getComprobante } from "../../../Redux/Actions/actionPDF";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirmMaterial";
import Transferencia from "./MediosDePago/Transferencia";
import { parseCurrency } from "../../../utils/parsers";
import AutocompleteEmpleado from "../../../components/ait-reusable/AutocompleteEmpleado";
import useConversionNumeroMiles from "../../../customHooks/useConversionNumeroMiles";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function PagoFacturas({
  selectionList,
  mediosPago,
  cliente,
  getFacturasAdeudadas,
  billetera,
  updateBilletera,
  showEncabezado = true,
  setPrintComprobante = null,
  dataEmpleado,
  updateMBilleteraConciliacionFacturas,
  valueRetiroSaldo,
}) {
  const { selection, setSelection } = selectionList;
  const { empleado } = useSelector((state) => state.loginReducer);
  const [deuda, setDeuda] = useState(null);
  const [retiro, setretiro] = useState(false);
  const dispatch = useDispatch();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [ingresoBilletera, setIngresoBilletera] = useState(0);
  const [openmodal, setopenmodal] = useState(false);
  const [values, setvalues] = useState(null);
  const [tipoMovCtacte, settipoMovCtacte] = useState([]);
  const [montoTotal, setMontoTotal] = useState(0);
  const [modalidadCheques, setModalidadCheques] = useState([]);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [loading, setLoading] = useState(false);

  const { comprobante_pdf } = useSelector((store) => store.pDF);

  const date = moment(new Date()).format("YYYY-MM-DD");
  const { conversionNumero, convertNumeroSinMiles } =
    useConversionNumeroMiles();

  let { idCliente } = useParams();
  async function saldarCuenta(values) {
    try {
      const response = await request({
        method: "PUT",
        url: saldarVentas(idCliente),
        data: DataSaldarVenta(values, deuda, selectionList),
      });
      handleResponseSaldarCuenta(response);
    } catch (error) {
      console.error(error);
      errorNotification("Ha ocurrido un error durante el registro.");
    }
  }
  const handleResponseSaldarCuenta = async (response) => {
    if (response.status === 200) {
      dispatch(getComprobante(response.data.recibos, comprobante_pdf));
      setSelection([]);

      successNotification("Pago parcial registrado con éxito.");
    } else {
      errorNotification("Ha ocurrido un error durante el registro.");
    }
    getFacturasAdeudadas();
  };
  const handleResponseBilletera = async (response) => {
    if (response.status === 200) {
      setIngresoBilletera(0);
      setPrintComprobante &&
        setPrintComprobante(response.data.data.orden_carga_saldo);

      successNotification("El movimiento se ha realizado con éxito.");
      dataEmpleado.useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          dataEmpleado.useEmpleado.empleado.idEmpleado,
        );
      updateMBilleteraConciliacionFacturas &&
        updateMBilleteraConciliacionFacturas();
    } else {
      errorNotification("Ha ocurrido un error durante el registro.");
    }
    billetera === undefined && saldarCuenta(values);
  };
  async function transaccionBilletera(values) {
    if (dataEmpleado.useEmpleado.empleado) {
      setLoadingSubmit(true);

      try {
        const response = await request({
          method: "POST",
          url: !retiro
            ? postCargarSaldo(idCliente)
            : postRetirarSaldo(idCliente),
          data: DataPostBilletera(
            values,
            billetera === undefined ? ingresoBilletera : values.monto,
            retiro,
            billetera,
            montoTotal,
            dataEmpleado.useEmpleado.empleado.idEmpleado,
            convertNumeroSinMiles,
          ),
        });
        setLoadingSubmit(false);
        setopenmodal(false);
        dataEmpleado.useEmpleado.empleado &&
          localStorage.setItem(
            "responsable_venta",
            dataEmpleado.useEmpleado.empleado.idEmpleado,
          );
        dataEmpleado.useQueryEmpleado.setQuery("");

        billetera !== undefined && updateBilletera();
        handleResponseBilletera(response);
      } catch (error) {
        setLoadingSubmit(false);
        console.error(error);
        errorNotification("Ha ocurrido un error durante el registro.");
      }
    } else {
      setErrorResponsable(true);
    }
  }

  const validateMonto = async (values) => {
    setvalues(values);
    if (parseFloat(values.monto) > parseFloat(deuda)) {
      setIngresoBilletera(values.monto - deuda);
      setopenmodal(true);
    } else {
      saldarCuenta(values);
    }
  };

  const handleChangeRetiro = (e) => {
    setretiro(e.target.checked);
    getTiposMovimientosCtaCte(!retiro).then((x) => settipoMovCtacte(x));
  };

  const calculateMontoTotal = (medios) => {
    let montoTotal = medios.reduce((acc, medio) => {
      return (
        (medio.monto === ""
          ? 0
          : Number(convertNumeroSinMiles(medio.monto.toString()))) + acc
      );
    }, 0);
    setMontoTotal(Number(montoTotal).toFixed(2));
    return Number(montoTotal).toFixed(2);
  };

  const filterMediosPago = () => {
    let newMediosPago = mediosPago.slice();

    if (cliente === undefined || (cliente && cliente.saldo < 0)) {
      newMediosPago.filter((value) => value.nombre !== "Cuenta Corriente");
    }

    return newMediosPago;
  };

  /**
   * Funcion que verifica si el cliente tiene valores de deuda o saldo y devuelve el valor correspondiente. Si no tiene ninguno
   * Entonces retorna el monto de billetera
   * @returns Montos de cliente
   */

  const getValueSaldoODeuda = () => {
    //Si es billetera tenemos el dato de la deuda
    if (cliente.deuda) {
      return Number(cliente.deuda);
    } else {
      //Si es fact adeudadas tenemos el dato del saldo
      if (cliente.saldo) {
        return Number(cliente.saldo) * -1;
      } else {
        return Number(cliente.monto_billetera);
      }
    }
  };

  let saldo =
    cliente && Number(cliente.monto_billetera) - getValueSaldoODeuda() !== 0
      ? Number(Number(cliente.monto_billetera).toFixed(2)) -
        Number(getValueSaldoODeuda().toFixed(2))
      : 0;

  const getModalidadCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getModalidadCheques,
      });
      handleResponseModalidadCheques(response);
    } catch (error) {
      console.error(error);
      setModalidadCheques([]);
    }
  };

  const handleResponseModalidadCheques = (response) => {
    if (response.status === 200) {
      setModalidadCheques(response.data);
    }
  };

  /**
   * Retorna el nombre del tipo del medio de pago. Del id del madio de pago pasado por parametro
   * @param {*} idMedioPago
   * @returns String
   */
  const obtenerTipoMedioPagoDesdeIdMedioPago = (idMedioPago) => {
    let medioPago = mediosPago.find((m) => m.idMedioPago === idMedioPago);
    return medioPago ? medioPago.tipo : "Simple";
  };

  useEffect(() => {
    getModalidadCheque();
  }, []);

  const getMontoNewMedio = (medios) => {
    let sumaMontos = medios.reduce((total, item) => {
      return total + item.monto === ""
        ? 0
        : Number(convertNumeroSinMiles(item.monto));
    }, 0);
    let res = saldo * -1 - sumaMontos;
    return res > 0 ? Number(res.toFixed(2)).toLocaleString("es-AR") : "";
  };

  useEffect(() => {
    if (valueRetiroSaldo !== undefined) {
      setretiro(valueRetiroSaldo);
      getTiposMovimientosCtaCte(valueRetiroSaldo).then((x) =>
        settipoMovCtacte(x),
      );
    } else {
      billetera &&
        getTiposMovimientosCtaCte(valueRetiroSaldo).then((x) =>
          settipoMovCtacte(x),
        );
    }
  }, [valueRetiroSaldo]);

  // useEffect(() => {
  //   billetera &&
  //     getTiposMovimientosCtaCte(valueRetiroSaldo).then((x) =>
  //       settipoMovCtacte(x)
  //     );
  // }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues(retiro, modalidadCheques)}
        enableReinitialize={true}
        validationSchema={validationYup(
          deuda,
          billetera,
          obtenerTipoMedioPagoDesdeIdMedioPago,
        )}
        onSubmit={(values, { resetForm }) => {
          // resetForm();
          billetera === undefined
            ? validateMonto(values)
            : transaccionBilletera(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Grid container>
              <List
                component="nav"
                className="p-0"
                aria-label="mailbox folders"
                style={{ width: "100%" }}
              >
                {showEncabezado && (
                  <Encabezado
                    handleChange={handleChangeRetiro}
                    retiro={retiro}
                    selectionList={selectionList}
                    deuda={deuda}
                    billetera={billetera}
                  />
                )}

                <ListItem className="pt-0 pb-0 mt-2">
                  <ListItemText>
                    <Grid item lg={12} sm={12} md={12} xs={12} className="mb-2">
                      <TextField
                        variant="outlined"
                        label="Saldo"
                        value={Number(saldo.toFixed(2)).toLocaleString("es-AR")}
                        fullWidth
                        style={{ backgroundColor: "white" }}
                        // inputProps={{
                        //   style: {
                        //     height: heightInputSaldo,
                        //   },
                        // }}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Box
                      pt={2}
                      maxHeight={350}
                      style={{
                        overflowY: "auto",
                        width: "100%",
                        overflowX: "hidden",
                      }}
                    >
                      <FieldArray name="medios">
                        {({ insert, remove, push }) => (
                          <>
                            {values.medios.length !== 0 &&
                              values.medios.map((medio, index) => (
                                <Grid key={index}>
                                  <Grid
                                    container
                                    spacing={2}
                                    className={"pb-2 mb-1"}
                                  >
                                    <Grid
                                      item
                                      xs={index !== 0 ? 5 : 6}
                                      style={{ textAlign: "left" }}
                                    >
                                      <TextField
                                        size="small"
                                        style={{ backgroundColor: "white" }}
                                        onWheel={(e) => e.target.blur()}
                                        id="monto"
                                        autoFocus
                                        fullWidth
                                        label={
                                          billetera === undefined
                                            ? medio.medioSelected !== 6
                                              ? "Registrar pago por"
                                              : "Registre monto del cheque"
                                            : "Monto"
                                        }
                                        disabled={
                                          !(
                                            (selectionList.selection &&
                                              selectionList.selection.length !==
                                                0) ||
                                            billetera
                                          )
                                        }
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                        }}
                                        value={medio.monto}
                                        name={`medios.${index}.monto`}
                                        onChange={(e) => {
                                          let newValue = conversionNumero(
                                            e.target.value,
                                          );
                                          setFieldValue(
                                            `medios.${index}.monto`,
                                            newValue,
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        helperText={
                                          errors.medios &&
                                          errors.medios[index] &&
                                          errors.medios.length >= index + 1 &&
                                          errors.medios[index].monto &&
                                          errors.medios[index].monto
                                        }
                                        error={
                                          errors.medios &&
                                          errors.medios[index] &&
                                          errors.medios.length >= index + 1 &&
                                          Boolean(
                                            errors.medios &&
                                              errors.medios[index] &&
                                              errors.medios[index].monto &&
                                              errors.medios[index].monto,
                                          )
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={index !== 0 ? 5 : 6}
                                      style={{ textAlign: "left" }}
                                    >
                                      <Autocomplete
                                        options={filterMediosPago()}
                                        getOptionLabel={(option) =>
                                          option.nombre
                                        }
                                        // Refactorizar este componente entero some day
                                        value={
                                          filterMediosPago().length > 0
                                            ? filterMediosPago().find(
                                                (m) =>
                                                  m.idMedioPago ===
                                                  Number(medio.medioSelected),
                                              )
                                            : null
                                        }
                                        onChange={(e, value) => {
                                          setFieldValue(
                                            `medios.${index}.medioSelected`,
                                            value ? value.idMedioPago : "",
                                          );
                                        }}
                                        fullWidth
                                        disabled={
                                          !(
                                            (selectionList.selection &&
                                              selectionList.selection.length !==
                                                0) ||
                                            billetera
                                          )
                                        }
                                        size="small"
                                        renderInput={(params) => (
                                          <TextField
                                            label="Medio de pago"
                                            name="medioSelected"
                                            {...params}
                                            variant="outlined"
                                            error={Boolean(
                                              touched.medioSelected &&
                                                errors.medioSelected,
                                            )}
                                            helperText={
                                              touched.medioSelected &&
                                              errors.medioSelected
                                            }
                                          />
                                        )}
                                      />
                                    </Grid>
                                    {index > 0 && (
                                      <Grid item xs={2}>
                                        <IconButton
                                          onClick={() => {
                                            remove(index);
                                            // filterMedioSelected(values.medios);
                                          }}
                                        >
                                          <SvgIcon>
                                            <DeleteIcon color="error" />
                                          </SvgIcon>
                                        </IconButton>
                                      </Grid>
                                    )}
                                  </Grid>
                                  {medio.medioSelected &&
                                    obtenerTipoMedioPagoDesdeIdMedioPago(
                                      medio.medioSelected,
                                    ) === "Tarjeta" && (
                                      <div>
                                        <Tarjetas
                                          values={{
                                            tarjetaSelected:
                                              medio.tarjetaSelected,
                                            coeficienteSelected:
                                              medio.coeficienteSelected,
                                            referenciaTarjeta:
                                              medio.referenciaTarjeta,
                                            nroAut: medio.nroAut,
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          mediosPago={medio.medioSelected}
                                          montoMedio={medio.monto}
                                          index={index}
                                          porcentaje={medio.porcentajeSelected}
                                          setFieldValue={setFieldValue}
                                          disabled={
                                            !(
                                              (selectionList.selection &&
                                                selectionList.selection
                                                  .length !== 0) ||
                                              billetera
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  {medio.medioSelected &&
                                  obtenerTipoMedioPagoDesdeIdMedioPago(
                                    medio.medioSelected,
                                  ) === "Cheque" ? (
                                    <Cheque
                                      values={{
                                        propioCheque: medio.propioCheque,
                                        referenciaCheque:
                                          medio.referenciaCheque,
                                        fechaVencimientoCheque:
                                          medio.fechaVencimientoCheque,
                                        fechaEmisionCheque:
                                          medio.fechaEmisionCheque,
                                        tipoCheque: medio.tipoCheque,
                                        nroCheque: medio.nroCheque,
                                        modalidadCheque: medio.modalidadCheque,
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      handleBlur={handleBlur}
                                      index={index}
                                      setFieldValue={setFieldValue}
                                      modalidadCheques={modalidadCheques}
                                      disabled={
                                        !(
                                          (selectionList.selection &&
                                            selectionList.selection.length !==
                                              0) ||
                                          billetera
                                        )
                                      }
                                    />
                                  ) : null}
                                  {medio.medioSelected &&
                                    obtenerTipoMedioPagoDesdeIdMedioPago(
                                      medio.medioSelected,
                                    ) === "Transferencia" && (
                                      <Transferencia
                                        values={{
                                          fecha_transferencia:
                                            medio.fecha_transferencia,
                                          bancoTransf: medio.bancoTransf,
                                          referenciaTransf:
                                            medio.referenciaTransf,
                                        }}
                                        setFieldValue={setFieldValue}
                                        index={index}
                                        disabled={
                                          !(
                                            (selectionList.selection &&
                                              selectionList.selection.length !==
                                                0) ||
                                            billetera
                                          )
                                        }
                                      />
                                    )}
                                </Grid>
                              ))}

                            <Grid item xs={12} lg={12} className="pb-2">
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  startIcon={<AddIcon />}
                                  onClick={() => {
                                    push({
                                      monto: getMontoNewMedio(values.medios),
                                      nroLote: "",
                                      nroAut: "",
                                      motivo: "",
                                      medioSelected: 2,
                                      tarjetaSelected: null,
                                      coeficienteSelected: null,
                                      referenciaTarjeta: null,
                                      porcentajeSelected: 0,
                                      nroCheque: null,
                                      tipoCheque: null,
                                      referenciaCheque: null,
                                      fechaCobroCheque: date,
                                      fechaEmisionCheque: date,
                                      fechaVencimientoCheque: date,
                                      propioCheque: false,
                                      modalidadCheque:
                                        modalidadCheques.length &&
                                        modalidadCheques[0].id,
                                      bancoTransf: null,
                                      fecha_transferencia: null,
                                      referenciaTransf: null,
                                    });
                                    // filterMedioSelected(values.medios);
                                  }}
                                >
                                  Agregar medio de pago
                                </Button>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Box>

                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "left" }}
                      className="mb-2 pb-2"
                    >
                      <AutocompleteEmpleado
                        useEmpleado={dataEmpleado.useEmpleado}
                        useQueryEmpleado={dataEmpleado.useQueryEmpleado}
                        errorResponsable={errorResponsable}
                        setErrorResponsable={setErrorResponsable}
                        empleado={empleado}
                        setLoading={setLoading}
                      />
                    </Grid>

                    <Grid
                      hidden={!billetera}
                      item
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      className="mb-2 pb-2"
                      style={{ textAlign: "left" }}
                    >
                      <Autocomplete
                        options={tipoMovCtacte}
                        getOptionLabel={(option) => option.nombre}
                        // Refactorizar este componente entero some day
                        value={
                          tipoMovCtacte.length > 0
                            ? tipoMovCtacte.find(
                                (x) => x.id === values.tipo_movimiento,
                              )
                            : null
                        }
                        onChange={(e, value) => {
                          setFieldValue(
                            "tipo_movimiento",
                            value ? value.id : "",
                          );
                        }}
                        size="small"
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField
                            label="Motivo"
                            {...params}
                            variant="outlined"
                            error={Boolean(
                              touched.tipo_movimiento && errors.tipo_movimiento,
                            )}
                            helperText={
                              touched.tipo_movimiento && errors.tipo_movimiento
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      hidden={!billetera}
                      className="pb-2 mb-2"
                      style={{ textAlign: "left" }}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="concepto"
                        fullWidth
                        size="small"
                        inputProps={{ maxLength: 200 }}
                        label={"En concepto de"}
                        value={values.concepto}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                      md={12}
                      hidden={!billetera}
                      className="pb-2 mb-2"
                      style={{ textAlign: "left" }}
                    >
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="observacion"
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 150 }}
                        label={"Observación"}
                        value={values.observacion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={`${values.observacion.length} de 150 caracteres`}
                        variant="outlined"
                      />
                    </Grid>
                  </ListItemText>
                </ListItem>
                <Grid
                  item
                  container
                  xs={12}
                  className="pb-3 pr-3"
                  justifyContent="flex-end"
                >
                  <Typography style={{ fontWeight: "bold" }}>
                    Total a {retiro ? "retirar" : "pagar"}: $
                    {Number(calculateMontoTotal(values.medios)).toLocaleString(
                      "es-AR",
                    )}
                  </Typography>
                </Grid>
              </List>
            </Grid>
            <Divider
              hidden={showEncabezado}
              style={{
                margin: "-3px -24px 15px -24px",
              }}
            />
            <Box className="pt-0 pl-3 pr-3 pb-3">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={loadingSubmit}
              >
                {loadingSubmit ? <CircularProgress size={24} /> : "REGISTRAR"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      {ingresoBilletera > 0 && openmodal && (
        <ModalConfirm
          open={openmodal}
          handleCancel={() => {
            saldarCuenta(values);
            setopenmodal(false);
          }}
          handleClose={() => setopenmodal(false)}
          title={"Saldo en positivo"}
          textContent={`Queda un saldo positivo de $ ${Number(
            ingresoBilletera.toFixed(2),
          ).toLocaleString(
            "es-AR",
          )} ¿Desea agregarlo a la billetera virtual del cliente?`}
          handleSubmit={() => transaccionBilletera(values)}
          size={"xs"}
          msgCancel={"Cancelar"}
          msgConfirm={"Aceptar"}
        />
      )}
    </div>
  );
}
