import * as actionTypes from "../Actions/types";
import { updateObject } from "../utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
  superUser: false,
  isAuth: false,
  empleado: null,
  perfil: null,
  estado_tributario: null,
  fechaVencimientoAfip: null,
  correctSession: true,
  showModal: false,
};

const setToken = (state, action) => {
  return updateObject(state, {
    token: action.token,
  });
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false,
    empleado: action.empleado,
    estado_tributario: action.estado_tributario,
    id: action.id,
    superUser: action.superUser,
    isAuth: true,
    correctSession: action.correctSession,
  });
};

const setPerfilUsuario = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    isAuth: true,
    ...action.payload,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
  });
};

const setSesionToken = (state, action) => {
  return updateObject(state, {
    correctSession: action.correctSession,
  });
};

const setShowModal = (state, action) => {
  return {
    ...state,
    showModal: action.payload,
  };
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_TOKEN:
      return setToken(state, action);
    case actionTypes.SET_PERFIL:
      return setPerfilUsuario(state, action);
    case actionTypes.SET_SESION_BY_TOKEN:
      return setSesionToken(state, action);
    case actionTypes.SHOW_MODAL:
      return setShowModal(state, action);
    default:
      return state;
  }
};

export default loginReducer;
