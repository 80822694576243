import React, { useState } from "react";
import { Grid, Card, TextField } from "@material-ui/core";
import AutocompleteEmpleado from "../../../../components/ait-reusable/AutocompleteEmpleado";
import { useSelector } from "react-redux";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import request from "../../../../requests/request";
import { conciliacionVentas } from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import { parseConciliarVentas } from "./componentes/FomularioCargaMedioPago/utils";
import { formatCurrency, parseCurrency } from "../../../../utils/parsers";
import { Showmodal } from "../../../../components/ait-reusable/PDF/Components/PreviewPdf";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../../components/ait-reusable/HandlePdf/handlePdf";
import { conciliacionVentasServices } from "../../../../services/conciliacionVentas";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";

export default function OthersComponents({
  dataEmpleado,
  observacion,
  setObservacion,
  selectionList,
  //tipo,
  //getValueSaldar,
  newMontos,
  //convertNumeroSinMiles,
  facturas,
  closePago,
  setMontos,
  setErrormonto,
  //montocobrar,
  setMontocobrar,
  cliente,
  getFacturasAdeudadas,
  updateResumen,
  setDataErrorAFIP,
  montoTotalCargado,
  //montoASaldar,
  formikMediosPago,
}) {
  const { empleado } = useSelector((state) => state.loginReducer);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setLoding] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [preview, setpreview] = useState(false);
  const [dataPdf, setdataPdf] = useState([]);
  const [tipoImpresion, settipoImpresion] = useState("");
  const [openModalPagoMayor, setOpenModalPagoMayor] = useState(false);
  const { setSelection } = selectionList;

  const montoSobrante = montoTotalCargado - newMontos.subtotal;

  const _disabled = () => {
    let disabled = false;
    if (disabled) return true;
    // Si en monto cargado en los medios de pago es igual o menor 0
    if (montoTotalCargado <= 0) return true;
    // Si el formulario de medios de pago no es valido
    if (!formikMediosPago.isValid) {
      return true;
    }
    // Si el formulario de medios de pago esta en proceso de validacion
    if (formikMediosPago.isValidating) {
      return true;
    }
    // Si no hay ventas seleccionadas
    if (selectionList.selection.length === 0) return true;

    return disabled;
  };

  const handleSubmit = async () => {
    if (dataEmpleado.useEmpleado.empleado) {
      setDisabled(true);
      setLoding(true);
      if (!_disabled()) {
        try {
          const data = {
            cliente_sucursal_id: cliente.idClienteDeSucursal,
            descuentoPorcentaje: newMontos.descuentoPorcentaje,
            ventas: facturas,
            responsable:
              dataEmpleado && dataEmpleado.useEmpleado.empleado.idEmpleado,
            is_pago_con_descuento: newMontos.descuentoUnico > 0 ? true : false,
            observacion: observacion === "" ? null : observacion,
            informacion_medios_pago: formikMediosPago.values,
          };
          const parsedData = parseConciliarVentas(data);
          const response = await request({
            method: "POST",
            url: conciliacionVentas,
            data: parsedData,
          });
          await handleResponse(response);
          setLoding(false);
        } catch (err) {
          console.log(err);
          setDisabled(false);
          let msgError = JSON.stringify(err.response.data);

          if (
            msgError.includes(
              "Errores al validar las siguientes notas de crédito",
            )
          ) {
            actionErrorNC(msgError);
          } else {
            errorNotification("Ha ocurrido un error durante el registro.");
          }
          setLoding(false);
        }
      } else {
        setDisabled(false);
      }
    } else {
      setErrorResponsable(true);
    }
  };

  const printPDFConciliacion = async (conciliacionVentasId) => {
    try {
      const responseData =
        await conciliacionVentasServices.obtenerConciliacionVentas({
          conciliacionVentasId: conciliacionVentasId,
        });
      const blobPdf = await getBlobPdf(
        "conciliacionVentasA4",
        responseData,
        configGeneral,
      );
      if (configGeneral.previsualizacion) {
        imprimirPdf(blobPdf);
        closePago();
      } else {
        settipoImpresion("conciliacionVentasA4");
        setdataPdf(responseData);
        setpreview(true);
        imprimirPdf(blobPdf);
      }
    } catch (error) {
      console.log(error);
      setdataPdf([]);
      setpreview(false);
    }
  };

  const toggle = () => {
    closePago();
    setpreview(!preview);
  };

  const actionErrorNC = (msgError) => {
    const regexInformacion = /Factura ID: (\S+) Nota de crédito ID: (\d+)/g;

    // Crear un objeto para almacenar elementos únicos basados en los campos 'fact' y 'nc'
    const informacionUnica = {};

    let match;
    while ((match = regexInformacion.exec(msgError)) !== null) {
      const fact = match[1];
      const nc = match[2];
      const key = `${fact}-${nc}`;

      if (!informacionUnica[key]) {
        informacionUnica[key] = { nroFactura: fact, idNotaCredito: nc };
      }
    }

    const informacionUnicaArray = Object.values(informacionUnica);
    errorNotification(
      "Factura generada con éxito pero ocurrieron errores en las validaciones de AFIP de notas de crédito.",
      3000,
    );

    setDataErrorAFIP((prev) => ({
      ...prev,
      facturas: informacionUnicaArray,
      open: true,
    }));
  };

  const handleResponse = async (response) => {
    setDisabled(false);
    if (response.status === 201) {
      setMontocobrar("");
      setSelection([]);
      setMontos.setNewMontos({
        subtotal: 0,
        descuento: 0,
        descuentoUnico: 0,
      });
      setObservacion("");
      dataEmpleado.useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          dataEmpleado.useEmpleado.empleado.idEmpleado,
        );
      setErrormonto("");
      successNotification("El pago se ha realizado con éxito.");
      await printPDFConciliacion(response.data.id);
    } else {
      errorNotification("Ha ocurrido un error durante el registro.");
    }
    getFacturasAdeudadas();
    updateResumen();
  };

  const handleSubmitModalPagoMayor = () => {
    if (montoTotalCargado > newMontos.subtotal) {
      setOpenModalPagoMayor(true);
    } else {
      handleSubmit();
    }
  };

  const handleModalConfirmSubmit = () => {
    setOpenModalPagoMayor(false);
    handleSubmit();
  };

  const modalMsg = (
    <>
      {`Estás por registrar un pago de `}
      <strong>{formatCurrency(montoTotalCargado)}</strong>,
      {` que supera el total de la deuda `}
      <strong>{formatCurrency(newMontos.subtotal)}</strong>.
      <br />
      {`Si confirmás la operación, se generará automáticamente una carga de saldo por el monto sobrante `}
      <strong>{formatCurrency(montoSobrante)}</strong>
      {` en la billetera del cliente que podrá usarse para saldar otros pagos en un futuro.`}
    </>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2} className="p-2">
              <Grid item xs={12}>
                <AutocompleteEmpleado
                  useEmpleado={dataEmpleado.useEmpleado}
                  useQueryEmpleado={dataEmpleado.useQueryEmpleado}
                  errorResponsable={errorResponsable}
                  setErrorResponsable={setErrorResponsable}
                  empleado={empleado}
                  setLoading={setLoading}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  size="small"
                  style={{ backgroundColor: "white" }}
                  name="observacion"
                  fullWidth
                  multiline
                  inputProps={{ maxLength: 150 }}
                  label="Observación"
                  value={observacion}
                  onChange={(event) => setObservacion(event.target.value)}
                  helperText={`${observacion.length} de 150 caracteres`}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ButtonGeneral
            fullwidth={true}
            message={`Registrar pago por ${parseCurrency(montoTotalCargado)}`}
            disabled={_disabled() || isLoading}
            click={handleSubmitModalPagoMayor}
          />
        </Grid>
      </Grid>
      {preview && (
        <Showmodal
          tipo={tipoImpresion}
          preview={preview}
          toggle={toggle}
          data={dataPdf}
        />
      )}
      <ModalConfirm
        open={openModalPagoMayor}
        handleClose={() => setOpenModalPagoMayor(false)}
        title="Pago mayor al monto de deuda"
        body={modalMsg}
        handleSubmit={handleModalConfirmSubmit}
        buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
      />
      <CircularBackdrop openBackdrop={isLoading} />
    </>
  );
}
