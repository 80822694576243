import {
  ACTUALIZAR_COMPRA,
  ACTUALIZAR_COMPROBANTE,
  ACTUALIZAR_DATA,
  ACTUALIZAR_EXPANDED,
  ACTUALIZAR_PROVEEDOR,
  LIMPIAR_COMPRA,
  RECREAR_COMPRA,
  CARGAR_COMPRA_AFIP,
  ACTUALIZAR_PROVINCIA,
} from "../Actions/compraActions";
import { updateObject } from "../utility";
import {
  ADD_ARTICULO_TO_CART,
  EMPTY_CART,
  REMOVE_ARTICULO_FROM_CART,
  UPDATE_ARTICULO_TO_CART,
  SET_VATT_SELECTED,
  ADD_ARTICULOS_EXCEL,
  CARGAR_DATOS_DESDE_PEDIDOS,
  RESET_DATA_NC_DESCUENTO,
} from "../Actions/types";
import moment from "moment";

const initialState = {
  proveedor: null,
  generarNotaCredito: null,
  provincia: "",
  dataForm: {
    puntoVenta: "",
    nroFactura: "",
    fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
    fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
    fechaVencimiento: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
    cae: "",
    percepciones: [],
    medio_pago: "",
    pagada: false,
    descuento: "0",
    responsable: null,
    observacion: "",
    pagosAsociados: [],
    activar_plazos_pago: false,
  },
  notaCreditoAsociadas: null,
  compra: null,
  detalles: [],
  tipoComprobante: null,
  expanded: false,
  vattSelected: [],
  compra_importacion_afip: null,
};

const updateData = (state, action) => {
  return updateObject(state, {
    dataForm: action.payload,
  });
};

const updateComprobante = (state, action) => {
  return updateObject(state, { tipoComprobante: action.payload });
};

const updateProveedor = (state, action) => {
  return updateObject(state, { proveedor: action.payload });
};

const updateProvincia = (state, action) => {
  return updateObject(state, { provincia: action.payload });
};
const updateCompra = (state, action) => {
  return updateObject(state, { compra: action.payload });
};
const addToCart = (state, action) => {
  return { ...state, detalles: action.productos };
};
const updateToCart = (state, action) => {
  return { ...state, detalles: action.productos };
};
const removeFromCart = (state, action) => {
  return { ...state, detalles: action.productos };
};

const clearCompra = (state, action) => {
  return updateObject(state, action.payload);
};

const updateExpanded = (state, action) => {
  return updateObject(state, { expanded: action.payload });
};
const emptyCart = (state) => {
  return { ...state, detalles: [], vattSelected: [] };
};
const setVattSelected = (state, action) => {
  return { ...state, vattSelected: action.arrayVatt };
};

const addArticulosExcel = (state, action) => {
  return { ...state, detalles: action.newCartItems };
};
const recrearCompra = (state, action) => {
  return { ...state, ...action.payload };
};
const cargarCompraAfip = (state, action) => {
  return { ...state, ...action.payload };
};
const cargarDatosDesdePedidos = (state, action) => {
  return { ...state, ...action.payload };
};

const resetDataNCDescuento = (state, action) => {
  return { ...state, ...action.payload };
};

const compraReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTUALIZAR_COMPROBANTE:
      return updateComprobante(state, action);
    case ACTUALIZAR_PROVEEDOR:
      return updateProveedor(state, action);
    case ACTUALIZAR_COMPRA:
      return updateCompra(state, action);
    case ADD_ARTICULO_TO_CART:
      return addToCart(state, action);
    case REMOVE_ARTICULO_FROM_CART:
      return removeFromCart(state, action);
    case ACTUALIZAR_DATA:
      return updateData(state, action);
    case LIMPIAR_COMPRA:
      return clearCompra(state, action);
    case ACTUALIZAR_EXPANDED:
      return updateExpanded(state, action);
    case UPDATE_ARTICULO_TO_CART:
      return updateToCart(state, action);
    case EMPTY_CART:
      return emptyCart(state);
    case SET_VATT_SELECTED:
      return setVattSelected(state, action);
    case ADD_ARTICULOS_EXCEL:
      return addArticulosExcel(state, action);
    case RECREAR_COMPRA:
      return recrearCompra(state, action);
    case CARGAR_DATOS_DESDE_PEDIDOS:
      return cargarDatosDesdePedidos(state, action);
    case RESET_DATA_NC_DESCUENTO:
      return resetDataNCDescuento(state, action);
    case CARGAR_COMPRA_AFIP:
      return cargarCompraAfip(state, action);
    case ACTUALIZAR_PROVINCIA:
      return updateProvincia(state, action);
    default:
      return state;
  }
};

export default compraReducer;
