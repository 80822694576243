import moment from "moment";

/** Types */
export const ACTUALIZAR_COMPROBANTE = "@compraRapida/actualizar-comprobante";
export const ACTUALIZAR_PROVEEDOR = "@compraRapida/actualizar-proveedor";
export const UPDATE_PROVINCIA = "@compraRapida/actualizar-provincia";

export const ACTUALIZAR_COMPRA = "@compraRapida/actualizar-compra";
export const ACTUALIZAR_DATA = "@compraRapida/actualizar-data";
export const LIMPIAR_COMPRA_RAPIDA = "@compraRapida/limpiar-compra-rapida";
export const EMPTY_CART = "@compraRapida/empty-cart";
export const ACTUALIZAR_EXPANDED = "@compraRapida/actualizar-expanded";
export const ADD_ARTICULO_TO_CART = "@compraRapida/add-articulo-to-cart";
export const REMOVE_ARTICULO_FROM_CART =
  "@compraRapida/remove-articulo-from-cart";
export const UPDATE_ARTICULO_TO_CART = "@compraRapida/update-articulo-to-cart";
export const UPDATE_MONTO = "@compraRapida/update-monto";
export const UPDATE_MONTO_TEST_IVA = "@compraRapida/update-monto-test-iva";

export const UPDATE_MONTO_IVA = "@compraRapida/update-monto-iva";
export const ACTUALIZAR_DATA_COMPLETE =
  "@compraRapida/actualizar-data-complete";
export const ADD_ARTICULOS_EXCEL = "@compraRapida/add-articulos-excel";
export const RECREAR_COMPRA_RAPIDA = "@compraRapida/recrear-compra-rapida";
export const CARGAR_DATOS_DESDE_PEDIDOS = "@compraRapida/recrear-compra-rapida";
export const RESET_DATA_NC_DESCUENTO = "@compraRapida/reset-data-nc-descuento";
export const CARGAR_COMPRA_RAPIDA_AFIP =
  "@compraRapida/cargar-compra-rapida-afip";

//Función que agrega un artículo al carrito de compra rápida
export const addArticulo = (detalle, producto) => {
  const cartItems = detalle.slice();
  let isOnCart = false;

  cartItems.forEach((item) => {
    if (
      producto.idRepuestoProveedor &&
      item.idRepuestoProveedor === producto.idRepuestoProveedor
    ) {
      isOnCart = true;
      item.cantidad = parseInt(item.cantidad + 1);
    }
  });

  if (!isOnCart) {
    const newProducto = getDataNewProducto(producto);
    cartItems.push({
      ...newProducto,
    });
  }

  return {
    type: ADD_ARTICULO_TO_CART,
    cartItems,
  };
};

//Función que agrega todos los artículos importados desde excel
export const addArticulosExcel = (detalles, proveedor) => {
  const cartItems = detalles.slice().filter((det) => det.procesado);
  const newCartItems = [];

  cartItems.forEach((item) => {
    let isOnCart = false;

    newCartItems.forEach((det) => {
      if (
        item.idRepuestoProveedor &&
        det.idRepuestoProveedor === item.idRepuestoProveedor
      ) {
        isOnCart = true;
        det.cantidad = det.cantidad + item.cantidad;
      }
    });

    if (!isOnCart) {
      let newDet = {
        activo: true,
        codOriginal: item.codOriginal,
        codProveedor: item.codProveedor,
        descripcionProveedor: item.descripcion,
        idProveedor: proveedor.idProveedor,
        idRepuestoProveedor: item.idRepuestoProveedor,
        proveedor: proveedor.razonSocial,
        cantidad: item.cantidad,
        precio_unitario: 0,
        vat: null,
      };

      newCartItems.push(newDet);
    }
  });

  return {
    type: ADD_ARTICULOS_EXCEL,
    newCartItems,
  };
};

// remover item del carrito
export const removeArticuloFromCart = (detalle, producto) => {
  let detalles = [...detalle];
  detalles.splice(producto.index, 1);

  return {
    type: REMOVE_ARTICULO_FROM_CART,
    detalles,
  };
};

//Modifica descripción o cantidad de un artículo
export const updateArticulo = (detalle, value, name, index) => {
  let detalles = [...detalle];
  let item = detalles[index];

  item[name] = value;

  return {
    type: UPDATE_ARTICULO_TO_CART,
    detalles,
  };
};

//Modifica los montos de la compra rápida
export const updateMonto = (montos, name, value, montoDescuento) => {
  let newMontos = { ...montos };

  newMontos[name] = value;
  if (name === "subtotal") {
    newMontos.descuento = montoDescuento;
  }

  newMontos.total = calculateAmountTotal(newMontos);
  return {
    type: UPDATE_MONTO,
    newMontos,
  };
};

export const setearIva = (montos, vattItems) => {
  let iva = 0;
  let total = 0;
  vattItems.forEach((v) => {
    iva += Number(v.montoIVA);
  });

  total = Number(
    Number(montos.subtotal) +
      iva +
      Number(montos.percepciones) -
      montos.descuento,
  ).toFixed(2);

  return {
    type: UPDATE_MONTO_TEST_IVA,
    payload: { ...montos, iva, total },
  };
};
//Modifica los montos de los vatt de la compra rápida
export const updateMontoIVA = (vattItems, montos, value, name, item) => {
  let newVatt = vattItems.slice();
  let index = newVatt.findIndex((element) => element.id === item.id);

  //El vatt ya existe
  if (index >= 0) {
    let otherName = name === "montoIVA" ? "montoBase" : "montoIVA";

    //Si el input que esta cambiando es "" y el otro input también es "" elimina el vatt sino setea el value
    //del input seleccionado
    if (value === "" && newVatt[index][otherName] === "") {
      newVatt.splice(index, 1);
    } else {
      newVatt[index][name] = value;
      newVatt[index][otherName] = calculateReverseAmount(
        value,
        name,
        newVatt[index].porcentaje,
      );
    }
  }

  //El vat no existe y se esta agregando uno nuevo
  if (index === -1) {
    let newMonto = calculateReverseAmount(value, name, item.porcentaje);
    newVatt.push({
      ...item,
      montoIVA: name === "montoIVA" ? value : newMonto,
      montoBase: name === "montoBase" ? value : newMonto,
    });
  }

  let newMontos = calculateMontoIVA(montos, newVatt);
  return {
    type: UPDATE_MONTO_IVA,
    payload: { newMontos, newVatt },
  };
};

//Función que elimina todos los vat seleccionados, luego calcula el monto total
export const deleteVattSelected = (montos) => {
  let newMontos = { ...montos };
  let newVatt = [
    {
      descripcion: null,
      id: 1,
      is_activo: true,
      montoBase: "",
      montoIVA: "",
      nombre: "21%",
      porcentaje: "21.00000",
    },
  ];

  newMontos.iva = 0;
  newMontos.total = calculateAmountTotal(newMontos);

  return {
    type: UPDATE_MONTO_IVA,
    payload: { newMontos, newVatt },
  };
};

/** Funcion encargada de actualizar el proveedor de la compra rápida */
export const updateProveedor = (proveedor) => {
  return {
    type: ACTUALIZAR_PROVEEDOR,
    payload: proveedor,
  };
};

export const updateProvincia = (provincia) => {
  return {
    type: UPDATE_PROVINCIA,
    payload: provincia,
  };
};

export const updateCompra = (compra) => {
  return {
    type: ACTUALIZAR_COMPRA,
    payload: compra,
  };
};

export const vaciarCart = () => (dispatch) => {
  dispatch({
    type: EMPTY_CART,
  });
};

/**Funcion encargada de actualizar el comprobrante seleciconado */
export const updateComprobante = (comprobante) => {
  return {
    type: ACTUALIZAR_COMPROBANTE,
    payload: comprobante,
  };
};

/**Funcion encargada de actualizar el valor del accordion */
export const updateExpanded = (value) => {
  return {
    type: ACTUALIZAR_EXPANDED,
    payload: value,
  };
};

/**Funcion encargada de actualizar los datos de compra rápida*/
export const updateNewData =
  (data, value, name, itemSelect, montos, eliminarPercepcion = false) =>
  (dispatch) => {
    let newMontos = { ...montos };
    let newData = { ...data };
    if (
      [
        "puntoVenta",
        "nroFactura",
        "fechaFacturacion",
        "fechaImputacion",
        "fechaVencimiento",
        "cae",
        "pagada",
        "medio_pago",
        "descuento",
        "responsable",
        "observacion",
        "pagosAsociados",
        "activar_plazos_pago",
      ].includes(name)
    ) {
      newData[name] = value;
    } else {
      const items = newData.percepciones.slice();
      let isOnCart = false;

      // Calcula el monto total de las percepciones
      if (eliminarPercepcion) {
        const itemsFiltered = items.filter((i) => i.id !== itemSelect.id);
        newData.percepciones = itemsFiltered;
        newMontos = calculateAmountPercepciones(itemsFiltered, montos, newData);

        return dispatch(updateDataComplete(newData, newMontos));
      }

      items.forEach((item) => {
        if (item.id === parseInt(name)) {
          isOnCart = true;
          item.valor = value;
        }
      });

      if (!isOnCart) {
        const newItem = {
          ...itemSelect,
          valor: value,
        };
        items.push({
          ...newItem,
        });
      }
      newData.percepciones = items;
      newMontos = calculateAmountPercepciones(items, montos, newData);

      //Calcula el monto total de las percepciones
    }
    return dispatch(updateDataComplete(newData, newMontos));
  };

/**Funcion encargada de actualizar los datos de compra rápida*/
export const updateNewDataDescuento =
  (data, value, name, montos, vattSelected) => (dispatch) => {
    let iva = 0;
    let newMontos = { ...montos };
    let newData = { ...data };

    const formula = Number(
      (Number(newMontos.subtotal) * Number(value)) / 100,
    ).toFixed(2);
    newData[name] = value;
    newMontos.descuento = formula;
    newMontos.total = calculateAmountTotal(newMontos);
    newMontos.iva = vattSelected.forEach((v) => {
      iva += Number(v.montoIVA);
    });
    return dispatch(updateDataComplete(newData, newMontos));
  };

/**Funcion encargada de actualizar los datos de compra rápida*/
export const updateData = (data) => {
  return {
    type: ACTUALIZAR_DATA,
    payload: data,
  };
};

/**Funcion encargada de actualizar los datos de compra rápida y los montos*/
export const updateDataComplete = (data, montos) => {
  return {
    type: ACTUALIZAR_DATA_COMPLETE,
    payload: { data, montos },
  };
};

export const clearCompraRapida = (facturaA, responsable) => {
  return {
    type: LIMPIAR_COMPRA_RAPIDA,
    payload: {
      proveedor: null,
      dataForm: {
        puntoVenta: "",
        nroFactura: "",
        fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
        fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
        fechaVencimiento: moment(new Date()).format("YYYY-MM-DD"),
        cae: "",
        percepciones: [],
        medio_pago: "",
        pagada: false,
        descuento: "0",
        responsable: responsable ? responsable : null,
        observacion: "",
        pagosAsociados: [],
        activar_plazos_pago: false,
      },
      compra: null,
      detalles: [],
      montos: {
        subtotal: "",
        descuento: "",
        iva: 0,
        percepciones: 0,
        total: 0,
      },
      vattSelected: [
        {
          descripcion: null,
          id: 1,
          is_activo: true,
          montoBase: "0.00",
          montoIVA: "0.00",
          nombre: "21%",
          porcentaje: "21.00000",
        },
      ],
      tipoComprobante: facturaA,
      expanded: false,
      generarNotaCredito: false,
      notaCreditoAsociadas: null,
      compra_importacion_afip: null,
    },
  };
};

export const recrearCompraRapida = (
  compraSeleccionada,
  generarNotaCredito = false,
) => {
  const {
    pagada,
    cae,
    descuento,
    tipo_comprobante,
    tipo_factura,
    vats,
    monto_total,
    iva,
    medio_pago_json: medioPago,
    proveedor_json: proveedor,
    punto_venta: puntoVenta,
    nro_factura: nroFactura,
    percepciones: percepcionesCompraSelecionada,
    detalles: detallesCompraSelecionada,
    observaciones,
  } = compraSeleccionada;

  // Armar array de percepciones
  const percepciones = percepcionesCompraSelecionada.map((p) => ({
    id: p.id,
    nombre: p.tipo_percepcion.nombre,
    descripcion: p.tipo_percepcion.descripcion,
    valor: Number(p.monto),
  }));

  // Armar objeto de tipo de comprobante
  const tipoComprobante = generarNotaCredito
    ? null
    : {
        idTipoFactura: tipo_factura ? tipo_factura.idTipoFactura : null,
        idTipoComprobante: tipo_comprobante.id,
        nombre: tipo_factura ? tipo_factura.nombre : "Comprobante interno",
      };

  // Armar array de ivas seleccionado
  const vattSelected = vats.map((v) => ({
    id: v.tipo_vat.id,
    nombre: v.tipo_vat.nombre,
    descripcion: v.tipo_vat.descripcion,
    porcentaje: v.tipo_vat.porcentaje,
    is_activo: v.tipo_vat.is_activo,
    montoIVA: Number(v.monto),
    montoBase: Number(v.monto_base),
  }));

  // Armar array de detalles
  const detalles = detallesCompraSelecionada.map((d) => ({
    idRepuestoProveedor: d.articulo.idRepuestoProveedor,
    codProveedor: d.articulo.codigo,
    descripcionProveedor: d.descripcion,
    precio_unitario: Number(d.precio_unitario),
    cantidad: Number(d.cantidad),
    vat: d.vat,
  }));

  // Armar objeto de los montos

  const totalPercepciones = percepciones.reduce(
    (acc, p) => Number((p.valor + acc).toFixed(2)),
    0,
  );

  const subtotalConDescuento = monto_total - totalPercepciones - iva;

  const subtotalSinDescuento = Number(
    ((subtotalConDescuento * 100) / (100 - Number(descuento))).toFixed(2),
  );
  const montoDescuento = Number(
    ((subtotalSinDescuento * Number(descuento)) / 100).toFixed(2),
  );

  const montos = {
    subtotal: subtotalSinDescuento,
    descuento: montoDescuento,
    total: Number(monto_total),
    percepciones: totalPercepciones,
    iva,
  };

  const notaCreditoAsociadas = compraSeleccionada.nc
    ? compraSeleccionada.nc.asociadas
    : null;

  let compra = null;
  if (generarNotaCredito) {
    compra = {
      id: compraSeleccionada.id,
      punto_venta: compraSeleccionada.punto_venta,
      nro_factura: compraSeleccionada.nro_factura,
      tipo_comprobante: compraSeleccionada.tipo_comprobante,
      tipo_factura: compraSeleccionada.tipo_factura,
    };
  }

  const updatedFormularioCompraRapida = {
    proveedor,
    tipoComprobante,
    vattSelected,
    detalles,
    montos,
    generarNotaCredito,
    notaCreditoAsociadas,
    compra,
    dataForm: {
      fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaVencimiento: moment(new Date()).format("YYYY-MM-DD"),
      cae: String(cae),
      percepciones,
      pagada,
      nroFactura: !generarNotaCredito ? String(nroFactura) : "",
      puntoVenta: !generarNotaCredito ? String(puntoVenta) : "",
      descuento: Number(descuento),
      medio_pago: medioPago ? medioPago.idMedioPago : "",
      observacion: observaciones ? observaciones : "",
      pagosAsociados: [],
    },
  };

  return {
    type: RECREAR_COMPRA_RAPIDA,
    payload: updatedFormularioCompraRapida,
  };
};

/** ------FUNCIONES EXTRAS------ */

/** Función que retorna el nuevo artículo a ingresar al carrito con sus atributos. 
Filtra los atributos que más significativos del artículo para la compra rápida*/
const getDataNewProducto = (art) => {
  const newArt = {
    activo: art.activo,
    codOriginal: art.codOriginal,
    codProveedor: art.codProveedor,
    descripcionProveedor: art.descripcionProveedor,
    idProveedor: art.idProveedor,
    idRepuestoProveedor: art.idRepuestoProveedor,
    proveedor: art.proveedor,
    cantidad: 1,
    precio_unitario: 0,
    vat: null,
  };
  return newArt;
};

//Función que calcula el monto total de las percepciones, luego calcula el monto total y retorna
//todos los montos de la compra rápida
const calculateAmountPercepciones = (items, montos) => {
  let newMontos = { ...montos };
  let totalPercep = items.reduce((total, item) => {
    let monto = isNaN(Number(item.valor))
      ? 0
      : Number(Number(item.valor).toFixed(2));

    return monto + total;
  }, 0);
  newMontos.percepciones = totalPercep.toFixed(2);
  newMontos.total = calculateAmountTotal(newMontos);
  return newMontos;
};

// const calculateDescuento = (montos, data) => {
//   montos = (data.descuento * montos.subtotal) / 100;
// };

//Función que calcula el monto total de IVA, luego calcula el monto total y retorna
//todos los montos de la compra rápida
const calculateMontoIVA = (montos, vattSelected) => {
  let newMontos = { ...montos };

  let montoIVA = vattSelected.reduce((total, item) => {
    let monto = isNaN(Number(item.montoIVA))
      ? 0
      : Number(Number(item.montoIVA).toFixed(2));

    return monto + total;
  }, 0);

  newMontos.iva = montoIVA.toFixed(2);
  newMontos.total = calculateAmountTotal(newMontos);

  return newMontos;
};

//Función que calcula y retorna el monto total
const calculateAmountTotal = (montos) => {
  let newMontos = { ...montos };
  let subtotal = isNaN(Number(newMontos.subtotal))
    ? 0
    : Number(newMontos.subtotal).toFixed(2);
  let descuento = isNaN(Number(newMontos.descuento))
    ? 0
    : Number(newMontos.descuento).toFixed(2);
  let iva = isNaN(Number(newMontos.iva)) ? 0 : Number(newMontos.iva).toFixed(2);
  let percepciones = isNaN(Number(newMontos.percepciones))
    ? 0
    : Number(newMontos.percepciones).toFixed(2);

  return Number(
    Number(subtotal) - Number(descuento) + Number(iva) + Number(percepciones),
  ).toFixed(2);
};

//Función que calcula el monto base o monto iva según si se ingresa monto iva o monto base
const calculateReverseAmount = (monto, name, vatt) => {
  let newAmount = "";
  let porc = vatt / 100;
  if (!isNaN(Number(monto))) {
    if (name === "montoBase") {
      newAmount = Number(monto * porc).toFixed(2);
    } else if (name === "montoIVA") {
      newAmount = Number(monto / porc).toFixed(2);
    }
  }

  return newAmount;
};

export const cargarCompraRapidaDesdeInformePedidos = (pedido) => {
  const detallesPedidos = pedido.detalles.map((producto) => ({
    idRepuestoProveedor:
      producto.articulo && producto.articulo.idRepuestoProveedor,
    codProveedor: producto.articulo && producto.articulo.codProveedor,
    descripcionProveedor: producto.descripcion,
    precio_unitario: Number(producto.precio),
    cantidad: Number(producto.cantidad),
    vat: 1,
  }));

  const newState = {
    proveedor: pedido.proveedor,
    generarNotaCredito: null,
    dataForm: {
      puntoVenta: "",
      nroFactura: "",
      fechaFacturacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaImputacion: moment(new Date()).format("YYYY-MM-DD"),
      fechaVencimiento: moment(new Date()).add(1, "M").format("YYYY-MM-DD"),
      cae: "",
      percepciones: [],
      medio_pago: "",
      pagada: false,
      descuento: "0",
      responsable: null,
      observacion: "",
      pagosAsociados: [],
    },
    notaCreditoAsociadas: null,
    compra: null,
    detalles: detallesPedidos,
    tipoComprobante: {
      idTipoFactura: 3,
      idTipoComprobante: 1,
      nombre: "Factura A",
    },
    expanded: false,
    vattSelected: [],
  };

  return {
    type: CARGAR_DATOS_DESDE_PEDIDOS,
    payload: newState,
  };
};

export const resetDataNCDescuento = (proveedor, dataForm, tipoComprobante) => {
  const newDataForm = { ...dataForm, percepciones: [], descuento: "0" };
  const copyCompra = {
    proveedor: proveedor,
    dataForm: newDataForm,
    compra: null,
    detalles: [],
    montos: {
      subtotal: 0,
      descuento: 0,
      iva: 0,
      percepciones: 0,
      total: 0,
    },
    vattSelected: [],
    tipoComprobante: tipoComprobante,
    expanded: false,
    generarNotaCredito: false,
    notaCreditoAsociadas: null,
  };

  return {
    type: RESET_DATA_NC_DESCUENTO,
    payload: copyCompra,
  };
};

export const cargarCompraRapidaAfip = (compra, tipoComprobante) => {
  const montoIva = Number(compra.iva) / 1.21;
  const vattSelected = [
    {
      descripcion: null,
      id: 1,
      is_activo: true,
      montoBase: compra.iva,
      montoIVA: Number(montoIva).toFixed(2),
      nombre: "21%",
      porcentaje: "21.00000",
    },
  ];
  const subtotalCalculate = () => {
    if (compra.iva === "0.00") {
      return compra.amount_total;
    } else {
      return compra.net_amount_taxed;
    }
  };

  const newState = {
    dataForm: {
      proveedor: "",
      generarNotaCredito: null,
      puntoVenta: compra.point_of_sales.toString().padStart(4, "0"),
      nroFactura: compra.number.toString().padStart(8, "0"),
      fechaFacturacion: moment(compra.date_issue).format("YYYY-MM-DD"),
      cae: compra.cae ? compra.cae : "",
      percepciones: [],
      medio_pago: "",
      pagada: false,
      descuento: "0",
      responsable: null,
      observacion: "",
      pagosAsociados: [],
    },
    montos: {
      subtotal: subtotalCalculate(),
      descuento: 0,
      iva: compra.iva ? compra.iva : 0,
      percepciones: 0,
      total: compra.amount_total,
    },
    notaCreditoAsociadas: null,
    compra: null,
    detalles: [],
    tipoComprobante: tipoComprobante,
    expanded: false,
    vattSelected: compra.iva ? vattSelected : [],
    compra_importacion_afip: compra.id,
  };
  return {
    type: CARGAR_COMPRA_RAPIDA_AFIP,
    payload: newState,
  };
};
