import React from "react";
import { parseCurrency } from "../../../../utils/parsers";
import MenuB from "../../Menu/MenuB";
import { Box, IconButton, MenuItem } from "@material-ui/core";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import useDisclosure from "../../../../customHooks/useDisclosure";
import BackdropLoading from "../../BackdropLoading/BackdropLoading";
import { deleteMov } from "../../../../views/Clientes/BilleteraVirtual/utils";
import { errorNotification, successNotification } from "../../../Notifications";
import DeleteIcon from "@material-ui/icons/Delete";

export default function formatVentasConciliacionVenta({
  ventas,
  onPagoDelete,
  refetch,
}) {
  const tipoComprobante = (venta) => {
    if (venta.tipo_comprobante === "Factura") {
      return "FA";
    } else if (venta.tipo_comprobante === "Comprobante interno") {
      return "CI";
    } else {
      return venta.tipo_comprobante;
    }
  };
  return ventas.map((venta) => ({
    comprobante: `${tipoComprobante(venta)} N°${venta.nro_factura}`,
    monto: parseCurrency(venta.monto_saldado),
    estado: venta.estado_pago ? "Vigente" : "Anulado",
    acciones: (
      <Opciones venta={venta} onPagoDelete={onPagoDelete} refetch={refetch} />
    ),
  }));
}

const Opciones = ({ venta, onPagoDelete = () => {}, refetch }) => {
  return (
    <AnularPago venta={venta} onPagoDelete={onPagoDelete} refetch={refetch} />
  );
};

const AnularPago = ({ venta, onPagoDelete, refetch }) => {
  const [opened, { toggle, open, close }] = useDisclosure();
  const [
    openedBackdrop,
    { toggle: toggleBackrop, open: openBackdrop, close: closeBackdrop },
  ] = useDisclosure();

  const handleSubmit = async () => {
    openBackdrop();
    try {
      await deleteMov(venta.pago_cliente_id);
      await onPagoDelete();
      await refetch();
      successNotification("Pago anulado correctamente");
    } catch (e) {
      errorNotification("Error al anular el pago");
    } finally {
      closeBackdrop();
    }
  };

  return (
    <>
      <IconButton size="small" onClick={open} disabled={!venta.estado_pago}>
        <DeleteIcon color={venta.estado_pago ? "error" : "disabled"} />
      </IconButton>

      {opened && (
        <ModalConfirm
          open={opened}
          handleClose={close}
          title={"Confirmación de anulación de cobro"}
          body={
            <>
              {`¿Está seguro de anular el cobro de la venta n° ${
                venta.nro_factura
              } de un monto de $${Number(
                venta.monto_saldado.toFixed(2),
              ).toLocaleString("es-AR")}?`}
              <BackdropLoading open={openedBackdrop} />
            </>
          }
          handleSubmit={handleSubmit}
          buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
        />
      )}
    </>
  );
};
