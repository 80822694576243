import axiosInstance from "../middleware/axiosMiddleware";

const request = async ({
  method,
  url,
  data,
  params,
  headers,
  signal,
  ...props
}) => {
  const token = localStorage.getItem("token");

  const defaultHeaders = { Authorization: `Token ${token}` };

  const config = {
    method,
    url,
    data,
    params,
    headers: { ...defaultHeaders, ...headers },
    signal,
    ...props,
  };

  return new Promise((resolve, reject) => {
    axiosInstance(config)
      .then((res) => resolve(res))
      .catch((e) => {
        reject(e);
      });
  });
};

export default request;
