import { useEffect, useState } from "react";

const useConversionNumeroMiles = () => {
  /**
   * Funcion principal que se llama cuando cambia el valor de entrada
   * @param {string} value - Valor a convertir con numero de miles por ejemplo 1000000,00
   * @returns (string) - Valor convertido con numero de miles por ejemplo 1.000.000,00
   */
  const conversionNumero = (value) => {
    let result = "";
    const cadenaSinPuntosNiComas = value.replace(/[.,]/g, ""); // Elimina los puntos y las comas
    if (Number(cadenaSinPuntosNiComas)) {
      // Si es un numero
      let valueConvertido = value.replace(/\./g, ""); // Elimina los puntos para que no se dupliquen
      let partes = valueConvertido.split(","); // Separa la parte entera de la decimal
      if (partes.length >= 2) {
        valueConvertido = partes[0] + "," + partes[1].substring(0, 2);
      }
      result = formatNumber(valueConvertido); // Convierte el numero con miles
    } else {
      if (cadenaSinPuntosNiComas === "") {
        result = "";
      }
    }
    return result;
  };

  /**
   * Funcion que realmente realiza la conversion
   * @param {string} number - Es el valor a convertir
   * @returns (string) - Valor convertido con numero de miles por ejemplo 1.000.000,00
   */
  const formatNumber = (number) => {
    // Validar si el valor de entrada es un número válido
    if (number === null || number === undefined) {
      return "";
    }

    // Convertir el número a cadena y eliminar espacios en blanco
    const numberString = String(number).replace(/\s+/g, "");

    // Dividir la cadena en parte entera y decimal (si existe)
    const [integerPart, decimalPart] = numberString.split(".");

    // Aplicar separador de miles a la parte entera
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Combinar parte entera y decimal (si existe)
    const formattedNumber =
      decimalPart === undefined
        ? formattedInteger
        : `${formattedInteger},${decimalPart}`;

    return formattedNumber;
  };

  /**
   * Funcion que realiza la conversion de un numero con puntos y comas a un numero solo con punto
   * @param {string} num
   * @returns (string) - numero convertida a formato float y con 2 decimales
   */
  const convertNumeroSinMiles = (num) => {
    let valueSinPuntos = String(num).replace(/\./g, ""); //Remueve los puntos
    let valueConvert = valueSinPuntos.replace(/,/g, "."); //Remueve las comas y las reemplaza por punto
    return parseFloat(valueConvert).toFixed(2);
  };

  return { conversionNumero, convertNumeroSinMiles };
};

export default useConversionNumeroMiles;
