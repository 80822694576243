import { useEffect, useState } from "react";
import request from "../../../../../requests/request";
import { getDataBajasRetencionesProveedor } from "../../../../../requests/urls";
import moment from "moment";
import { errorNotification } from "../../../../../components/Notifications";

const useVerificacionAplicacionRetenciones = (
  idProveedor,
  selection,
  dataGral,
  setIsPago,
  useComprasNC,
  setSelection,
  history,
) => {
  const [dataBajaReciente, setDataBajaReciente] = useState(null);
  const [dataBajasAnteriores, setDataBajasAnteriores] = useState(null);
  const [aplicarRetencion, setAplicarRetencion] = useState(false);
  const [loadingDataRetenciones, setLoadingDataRetenciones] = useState(false);
  const [certificadoVencido, setCertificadoVencido] = useState(null);
  const [dataEstadoRetencionPago, setDataEstadoRetencionPago] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);

  useEffect(() => {
    getDataBajasRetenciones();
  }, [dataGral.fechaReal]);

  useEffect(() => {
    if (dataBajaReciente) {
      existeVencimientoCertificados();
    }
  }, [dataBajaReciente]);

  /**
   * Funcion que verifica si para la fecha actual existe un vencimiento de certificado proximo.
   * Para eso se toma la ultima baja vigente y de ella se busca el certificado con estado Vigente. Se comparara la fecha fin del
   * certificado con la fecha actual.
   */
  const existeVencimientoCertificados = () => {
    let ultimoCertificado = dataBajaReciente.certificados.filter(
      (c) => c.estado === "Vigente",
    )[0];
    let hayVencimiento = true;
    let mensaje = "";
    if (ultimoCertificado) {
      const today = new Date().setUTCHours(0, 0, 0, 0);
      const diferencia =
        new Date(ultimoCertificado.fecha_fin).setUTCHours(0, 0, 0, 0) - today;
      const diasDiferencia = Math.floor(diferencia / (1000 * 60 * 60 * 24));

      if (diasDiferencia < 0) {
        mensaje = `El certificado de exclusión Nº ${ultimoCertificado.numero_certificado} está vencido. Diríjase a la edición del proveedor para renovarlo.`;
      } else if (diasDiferencia === 0) {
        mensaje = `El certificado de exclusión Nº ${ultimoCertificado.numero_certificado} vence hoy. Diríjase a la edición del proveedor para renovarlo.`;
      } else if (diasDiferencia <= 10) {
        mensaje = `El certificado de exclusión Nº ${ultimoCertificado.numero_certificado} vence en ${diasDiferencia} ${diasDiferencia === 1 ? "día" : "días"}.`;
      } else {
        hayVencimiento = false;
      }
    } else {
      hayVencimiento = false;
    }

    if (hayVencimiento) {
      setCertificadoVencido({
        mensaje,
        vencimiento: true,
      });
    }
  };

  const getDataBajasRetenciones = async () => {
    setLoadingDataRetenciones(true);
    setOpenModal(false);
    const dataIds = getDataIds();

    try {
      const response = await request({
        method: "GET",
        url: getDataBajasRetencionesProveedor(dataIds),
        params: {
          fecha_pago: moment(dataGral.fechaReal).format("YYYY-MM-DD"),
          id_proveedor: Number(idProveedor),
        },
      });
      if (response.status === 201) {
        setDataBajaReciente(response.data.baja_reciente);
        setDataBajasAnteriores(response.data.bajas_anteriores);
        setAplicarRetencion(response.data.aplicar_retencion);
        setDataEstadoRetencionPago({
          info: response.data.info,
          retencionesAplicadas: response.data.retenciones_aplicadas,
          certificadosImplicados: response.data.certificados_implicados,
        });
        openModalSegunInfo(response.data.info);
      }
      setLoadingDataRetenciones(false);
    } catch (err) {
      console.log(err);
      errorNotification(
        "Ocurrió un error al obtener los datos para el pago, por favor reintente.",
      );
      setDataBajaReciente(null);
      setDataBajasAnteriores(null);
      setAplicarRetencion(false);
      setDataEstadoRetencionPago(null);
      setLoadingDataRetenciones(false);
      setIsPago(false);
    }
  };

  /**
   * Funcion que setea el estado de la variable que abre o no el modal con informacion  del estado del pago.
   * Se abre el modal si info contiene alguno de los siguientes mensajes:
   * - Existe certificado, no se debe aplicar retencion
   * - Existe certificado pero fue anulado, se debe aplicar retencion
   * - No tiene certificado y se aplico retencion en el mes, se debe aplicar retencion
   * @param {String} info
   */
  const openModalSegunInfo = (info) => {
    if (
      info.includes("Existe certificado, no se debe aplicar retencion") ||
      info.includes(
        "Existe certificado pero fue anulado, se debe aplicar retencion",
      ) ||
      info.includes(
        "No tiene certificado y se aplico retencion en el mes, se debe aplicar retencion",
      ) ||
      info.includes(
        "La fecha de pago supera la fecha de validez del certificado vigente, se debe aplicar retencion",
      )
    ) {
      setOpenModal(true);
    }
  };

  const getDataIds = () => {
    let compras = [];

    selection.map((s) => {
      if (s.tipo_comprobante.nombre !== "Notas de Crédito") {
        // Se agrega esto para que solo agregue el id de un solo plazo ya que tienen la misma compra
        if (!compras.includes(s.id)) {
          compras.push(s.id);
        }
      }
    });

    const comprasQuery =
      compras.length > 0
        ? compras.map((id) => `ids_compras=${id}`).join("&")
        : "";
    return comprasQuery;
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsPago(false);
  };

  /**
   * Funcion que confirma que el cliente esta de acuerdo con la informacion respecto a si se debe o no calcular retencion segun el modal de informacion.
   * Para cualquier opcion lo que se debe hacer es directamente cerrar el modal ya que la aplicacion o no de retencion se va a determinar por el valor aplicarRetencion
   * Para las opciones 1, 3 y 4, como se va a retener, hay que verificar si existen comprobantes seleccionados distintos a A o M y si es asi,
   * se deben eliminar de la lista de comprobantes seleccionados.
   * Para la opcion 5 se debe redirigir a la edicion del proveedor.
   */
  const handleConfirmacion = () => {
    if (optionSelected.value === 5) {
      history.push(`/proveedor/modificar/${idProveedor}`);
    } else if (
      optionSelected.value === 1 ||
      optionSelected.value === 3 ||
      optionSelected.value === 4
    ) {
      eliminarComprobantesQueNoSonAoM();
    }

    setOpenModal(false);
  };

  const eliminarComprobantesQueNoSonAoM = () => {
    let copySelection = selection.slice();

    copySelection = copySelection.filter((s) => {
      return (
        s.tipo_factura &&
        (s.tipo_factura.nombre === "A" || s.tipo_factura.nombre === "M")
      );
    });
    setSelection(copySelection);

    //Si la cantidad de comprobantes seleccionados cambio, se setea la variable que indica que se cambio la seleccion por calculo de retencion
    selection.length !== copySelection.length &&
      useComprasNC.setChangeSelectionPorCalculoRetencion(true);
  };

  return {
    useDataBajas: {
      loadingDataRetenciones,
      certificadoVencido,
      handleCloseModal,
      handleConfirmacion,
      openModal,
      dataBajasAnteriores,
      aplicarRetencion,
      dataEstadoRetencionPago,
      optionSelected,
      setOptionSelected,
    },
  };
};

export default useVerificacionAplicacionRetenciones;
