import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { clearCompra } from "../../Redux/Actions/compraActions";
import FormCompra from "./Formulario/FormCompra";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { obtenerEmpleadoPorDefecto, useStyles } from "./Formulario/utils";
import request from "../../requests/request";
import { postCompra, validateCompra } from "../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import useVatTypes from "../../customHooks/useVatTypes";
import { validateVat } from "../../components/ait-reusable/validateNotaDeCredito";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSearchEmpleado } from "../../customHooks/useSearchEmpleado";
import { getIdPagos } from "./utils";
import useConversionNumeroMiles from "../../customHooks/useConversionNumeroMiles";

export default function Compras() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loadingCompra, setLoadingCompra] = useState(false);
  const {
    proveedor,
    dataForm,
    tipoComprobante,
    detalles,
    compra,
    generarNotaCredito,
    notaCreditoAsociadas,
    compra_importacion_afip,
    provincia,
  } = useSelector((state) => state.compra);
  const dispatch = useDispatch();
  const classes = useStyles();
  const vattTypes = useVatTypes();
  const { useEmpleado } = useSearchEmpleado();
  const { convertNumeroSinMiles } = useConversionNumeroMiles();
  const [openModalPlazos, setOpenModalPlazos] = useState(false);

  const handlePostCompra = async (
    values,
    resetForm,
    setFieldValue,
    comprobantes,
    plazosSelected,
  ) => {
    if (
      values.comprobante !== "Notas de Crédito Descuento" &&
      detalles.length === 0
    ) {
      setOpenBackdrop(false);
      setLoadingCompra(false);
      errorNotification("El carrito no puede estar vacío");
    } else {
      let copyComprobante = values.comprobante;
      let monto =
        values.comprobante === "Notas de Crédito Descuento"
          ? await calcularTotalPagos()
          : await calcularTotal();
      let newDetalle = await getNewDetalle();
      let newPercepciones = await getNewPer();
      let idPagos = getIdPagos(values.pagosAsociados);
      let dataPlazos = dataForm.activar_plazos_pago
        ? plazosSelected.map((plazo) => {
            return {
              dias: plazo.dias,
              monto: Number(convertNumeroSinMiles(plazo.monto.toString())),
            };
          })
        : null;

      try {
        const response = await request({
          method: "POST",
          url: postCompra,
          data: {
            cae: dataForm.cae,
            detalles: newDetalle,
            descuento: dataForm.descuento,
            documento: proveedor.CUIT,
            fecha_facturacion: dataForm.fechaFacturacion,
            fecha_imputacion: dataForm.fechaImputacion,
            fecha_vencimiento: dataForm.fechaVencimiento,
            medio_pago:
              dataForm.medio_pago && dataForm.medio_pago !== ""
                ? dataForm.medio_pago
                : null,
            monto_total: monto,
            nro_factura: dataForm.nroFactura,
            pagada:
              tipoComprobante.idTipoComprobante === 3 ? false : dataForm.pagada,
            percepciones: newPercepciones,
            proveedor: proveedor.idProveedor,
            punto_venta: dataForm.puntoVenta,
            tipo_comprobante: tipoComprobante.idTipoComprobante,
            tipo_factura: tipoComprobante.idTipoFactura,
            vats: null,
            rapida: false,
            compra_asociada: compra ? compra.id : null,
            responsable: dataForm.responsable
              ? dataForm.responsable.idEmpleado
              : null,
            observacion:
              dataForm.observacion === "" ? null : dataForm.observacion,
            es_descuento:
              values.compra || !values.pagosAsociados.length ? false : true,
            ids_pagos: idPagos,
            compra_importacion_afip: compra_importacion_afip,
            provincia: provincia,
            plazos_pago: dataPlazos,
          },
        });
        const facturaTipoA = comprobantes.filter(
          (c) => c.nombre === "Factura A",
        );
        setOpenBackdrop(false);
        resetForm();
        successNotification(
          `${
            copyComprobante.includes("Notas de Crédito")
              ? "Nota de Crédito"
              : "Compra"
          } realizada con éxito`,
        );
        setFieldValue("comprobante", "Factura A");
        let respSelected = obtenerEmpleadoPorDefecto(useEmpleado);
        setFieldValue("responsable", respSelected);
        setOpenModalPlazos(false);
        dispatch(clearCompra(facturaTipoA[0], respSelected));
        setLoadingCompra(false);
      } catch (error) {
        console.error(error);
        setOpenBackdrop(false);
        setLoadingCompra(false);
        errorNotification(
          `Error al realizar la ${
            copyComprobante.includes("Notas de Crédito")
              ? "nota de Crédito"
              : "compra"
          }`,
        );
      }
    }
  };
  const calcularTotal = async () => {
    const includeIva = ![
      "Factura C",
      "Factura B",
      "Comprobante interno",
      "Notas de Crédito X",
      "Notas de Crédito C",
      "Notas de Crédito B",
    ].includes(tipoComprobante ? tipoComprobante.nombre : "");
    const hasValidDescuento =
      dataForm.descuento !== "" && !isNaN(dataForm.descuento);
    let total = detalles.reduce((a, detalle) => {
      {
        const ivaItem =
          includeIva &&
          Number(
            (
              Number(vattTypes.find((v) => v.id === detalle.vat).porcentaje) /
              100
            ).toFixed(4),
          );
        return (
          a +
          detalle.subtotal *
            (1 -
              (hasValidDescuento ? parseFloat(dataForm.descuento) / 100 : 0)) *
            (includeIva ? ivaItem + 1 : 1)
        );
      }
    }, 0);
    let perc = dataForm.percepciones;
    let totPercepciones = perc.reduce(
      (a, per) => a + (per.valor === "" ? 0 : parseFloat(per.valor)),
      0,
    );

    return parseFloat(total + totPercepciones).toFixed(2);
  };

  const calcularTotalPagos = async () => {
    let total = dataForm.pagosAsociados.reduce((a, pago) => {
      return a + Number(pago.monto_descuento) * -1;
    }, 0);

    return Number(total.toFixed(2));
  };

  const getNewDetalle = async () => {
    let newDet = [];
    detalles.forEach((det) => {
      newDet.push({
        articulo: det.idRepuestoProveedor,
        descripcion: det.descripcionProveedor,
        cantidad: det.cantidad,
        precio_unitario: parseFloat(det.precios.costo).toFixed(2),
        vat: validateVat(tipoComprobante) ? det.vat : null,
      });
    });
    return newDet;
  };

  const getNewPer = async () => {
    let newDet = [];
    dataForm.percepciones.forEach((det) => {
      if (det.valor !== "") {
        newDet.push({
          id: det.id,
          monto: det.valor,
        });
      }
    });
    return newDet;
  };

  const existeLaCompra = async (
    values,
    resetForm,
    setFieldValue,
    comprobantes,
    plazosSelected,
  ) => {
    setOpenBackdrop(true);
    try {
      const { data } = await request({
        method: "GET",
        url: validateCompra(proveedor.idProveedor),
        params: {
          punto_venta: dataForm.puntoVenta,
          nro_factura: dataForm.nroFactura,
          tipo_comprobante: tipoComprobante.idTipoComprobante,
          tipo_factura: tipoComprobante.idTipoFactura,
        },
      });
      postValidation(
        data,
        values,
        resetForm,
        setFieldValue,
        comprobantes,
        plazosSelected,
      );
    } catch (error) {
      setOpenBackdrop(false);
      setLoadingCompra(false);
      console.error(error);
    }
  };

  const postValidation = (
    data,
    values,
    resetForm,
    setFieldValue,
    comprobantes,
    plazosSelected,
  ) => {
    if (data === true) {
      setOpenBackdrop(false);
      setLoadingCompra(false);
      return errorNotification("La compra ya existe.");
    } else {
      handlePostCompra(
        values,
        resetForm,
        setFieldValue,
        comprobantes,
        plazosSelected,
      );
    }
  };

  useEffect(() => {
    setOpenBackdrop(true);
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 400);
  }, []);

  return (
    <>
      {generarNotaCredito && (
        <Alert severity="error">
          <AlertTitle>GENERAR NOTA DE CRÉDITO DE COMPRA</AlertTitle>
          {notaCreditoAsociadas && (
            <React.Fragment>
              ESTA COMPRA{" "}
              <strong style={{ fontWeight: "bolder" }}>
                POSEE NOTAS DE CRÉDITO ASOCIADAS
              </strong>
              . NO SE DESCUENTAN DE LA COMPRA, LAS CANTIDADES DE LAS NOTA DE
              CRÉDITO EXISTENTES.
            </React.Fragment>
          )}
        </Alert>
      )}
      {compra_importacion_afip && (
        <Alert severity="info">
          <AlertTitle>COMPRA IMPORTADA DESDE AFIP</AlertTitle>

          <React.Fragment>
            Si desea generar una compra que no este vinculada con una compra de
            afip presione{" "}
            <Button
              color="primary"
              variant={"outlined"}
              size={"small"}
              onClick={() => window.location.reload()}
            >
              AQUÍ
            </Button>{" "}
          </React.Fragment>
        </Alert>
      )}
      <FormCompra
        handlePostCompra={existeLaCompra}
        vattTypes={vattTypes}
        loadingCompra={loadingCompra}
        setLoadingCompra={setLoadingCompra}
        openBackdrop={openBackdrop}
        openModalPlazos={openModalPlazos}
        setOpenModalPlazos={setOpenModalPlazos}
      />

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
