import React, { useState, useEffect } from "react";
import { Grid, Container, Card, Button, makeStyles } from "@material-ui/core";
import PageTitle from "../../../../components/common/PageTitle";
import useCalculoComprasNC from "./Hooks/useCalculoComprasNC";
import { useParams } from "react-router-dom";
import ComponentsCompras from "./ComponentsCompras";
import moment from "moment";
import useCalculoMontosTotales from "./Hooks/useCalculoMontosTotales";
import request from "../../../../requests/request";
import { formatDataPago, getTotalesSelection, getIsParcial } from "./utils";
import {
  successNotification,
  errorNotification,
} from "../../../../components/Notifications";
import { updateCompras, getModalidadCheques } from "../../../../requests/urls";
import MontosTotales from "./ComponentsCompras/MontosTotales";
import MediosDePago from "./ComponentsCompras/MediosDePago";
import { Formik, Form } from "formik";
import { validationYup, initialValues, validationExtra } from "./utilsFormik";
import OtrosCampos from "./ComponentsCompras/OtrosCampos";
import { useSearchEmpleado } from "../../../../customHooks/useSearchEmpleado";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import TabsPago from "./TabsPago";
import ComponentsPagosRetenciones from "./ComponentsPagosRetenciones";
import ComponentsComprobantes from "./ComponentsComprobantes";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import CampoRetencion from "./ComponentsCompras/CampoRetencion";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../../components/ait-reusable/HandlePdf/handlePdf";
import useCalculoDescUnico from "./Hooks/useCalculoDescUnico";
import { UltimaObservacion } from "./ComponentsCompras/ultimaObservacion";
import ObservacionesProveedor from "../../../../components/ait-reusable/ObservacionesProveedor";
import { useSelector } from "react-redux";
import { useGetSucursal } from "../../../../customHooks/useGetSucursal";
import useVerificacionAplicacionRetenciones from "./Hooks/useVerificacionAplicacionRetenciones";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";
import ModalReutilizable from "../../../../components/ait-reusable/Modal";
import ContentModalInfo from "./ModalInfoRetenciones/ContentModalInfo";
import ModalConfirm from "../../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import { formatCurrency } from "../../../../utils/parsers";

const useStyles = makeStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

export default function PagoDeCompra({
  selection,
  setIsPago,
  setSelection,
  handleGetFacturas,
  handleGetInfoProveedor,
  saldoCC,
  proveedor,
  loadingPago,
  setLoadingPago,
  observacionesFiltradas,
  history,
}) {
  const classes = useStyles();
  const { idProveedor } = useParams();
  const [dataGral, setDataGral] = useState({
    pagoCompleto: true,
    fechaReal: moment(new Date())
      .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DD HH:mm"),
    descuento: "",
    observacion: "",
    pagoNegativo: false,
  });
  const [errorResponsable, setErrorResponsable] = useState(false);
  const [loadingEmpelado, setLoadingEmpleado] = useState(false);
  const [modalidadCheques, setModalidadCheques] = useState([]);
  const [tab, setTab] = useState(0);
  const [disabledButton, setDisabledButton] = useState(false);
  const [descuentoMonto, setDescuentoMonto] = useState("");
  const [modalObservacion, setModalObservacion] = useState(false);
  const [openModalPagoMayor, setOpenModalPagoMayor] = useState(false);
  const [pendingValues, setPendingValues] = useState({});

  const { useDescUnico } = useCalculoDescUnico();
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { sucursal } = useGetSucursal();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const tieneAoM = selection.some(
    (s) =>
      s.tipo_factura &&
      (s.tipo_factura.nombre === "A" || s.tipo_factura.nombre === "M"),
  );
  const { useComprasNC } = useCalculoComprasNC(
    idProveedor,
    selection,
    dataGral,
  );
  const { useDataBajas } = useVerificacionAplicacionRetenciones(
    idProveedor,
    selection,
    dataGral,
    setIsPago,
    useComprasNC,
    setSelection,
    history,
  );
  const { useTotales } = useCalculoMontosTotales(
    useComprasNC,
    dataGral,
    proveedor,
    tieneAoM,
    useDataBajas.aplicarRetencion,
  );
  const { montoAPagarFinal, montoMediosPago, sobrante } = useTotales;

  useEffect(() => {
    getModalidadCheque();
    actionsPagoNegativo();
  }, []);

  const handlePrintComprobantePago = async (pago) => {
    const blobPdf = await getBlobPdf(
      "pagoCompras",
      pago,
      configGeneral,
      null,
      null,
      sucursal,
    );

    imprimirPdf(blobPdf);
  };

  const handlePagarCompra = async (values) => {
    const dataFinal = formatDataPago(
      selection,
      useTotales,
      useComprasNC,
      idProveedor,
      dataGral,
      useEmpleado.empleado,
      values.medios,
    );

    try {
      const response = await request({
        method: "POST",
        url: updateCompras,
        data: dataFinal,
      });
      handleGetFacturas();
      handleGetInfoProveedor();
      setSelection([]);
      setLoadingPago(false);
      setIsPago(false);
      successNotification("Pago realizado con éxito.");
      await handlePrintComprobantePago(response.data);
    } catch (err) {
      console.error(err);
      setDisabledButton(false);
      setLoadingPago(false);
      errorNotification(
        "Ocurrió un error al registrar el pago, intente nuevamente.",
      );
    }
  };

  const getModalidadCheque = async () => {
    try {
      const response = await request({
        method: "GET",
        url: getModalidadCheques,
      });
      if (response.status === 200) {
        setModalidadCheques(response.data);
      } else {
        setModalidadCheques([]);
      }
    } catch (error) {
      console.error(error);
      setModalidadCheques([]);
    }
  };

  const actionsPagoNegativo = () => {
    let total = getTotalesSelection(selection);

    //Si este total es negativo o cero significa que las compras se estan saldando con todas las nc y no se deben seleccionar mas medios de pago,
    //cambiar el switch de pago completo ni cambiar descuento
    if (total <= 0) {
      setDataGral((prev) => ({
        ...prev,
        pagoNegativo: true,
      }));
    }
  };

  const validateButtonPagar = () => {
    let errValidate = false;
    //Primero se valida si el monto pendiente es mayor a cero
    if (Number(useTotales.pendiente) > 0) {
      //No importa si el pago es total o parcial se debe validar el monto pendiente
      errValidate = true;
    } else {
      //Si no tengo un monto pendiente se debe validar si es un pago parcial y si tiene sobrante
      let isParcial = getIsParcial(useComprasNC, dataGral);
      if (isParcial && Number(useTotales.sobrante) > 0) {
        errValidate = true;
      }
    }
    return errValidate;
  };

  const handleModalConfirmSubmit = () => {
    setOpenModalPagoMayor(false);
    if (pendingValues) {
      handlePagarCompra(pendingValues); // Procesa el pago con los valores guardados
      setLoadingPago(false);
      setDisabledButton(false);
    }
  };

  const handleModalCancelSubmit = () => {
    setOpenModalPagoMayor(false);
    setDisabledButton(false);
  };

  const modalMsg = (
    <>
      {`Estás por registrar un pago de `}
      <strong>{formatCurrency(montoMediosPago)}</strong>,
      {` que supera el total de la deuda `}
      <strong>{formatCurrency(montoAPagarFinal)}</strong>.
      <br />
      {`Si confirmás la operación, se generará automáticamente una carga de saldo por el monto sobrante `}
      <strong>{formatCurrency(sobrante)}</strong>
      {` en la billetera del proveedor que podrá usarse para saldar otros pagos en un futuro.`}
    </>
  );

  return (
    <Container maxWidth="xl" style={{ marginBottom: "10px" }}>
      <Grid
        item
        spacing={2}
        className={"d-flex my-auto"}
        style={{ justifyContent: "space-between" }}
      >
        <PageTitle
          sm={4}
          title={proveedor && proveedor.razonSocial}
          subtitle="Pago"
          className="text-sm-left"
        />
        <Button
          outline
          title="ATRAS"
          variant="outlined"
          className={classes.root}
          color="primary"
          style={{
            height: "50%",
            cursor: "pointer",
            padding: "2px 0px",
            margin: "24px 0px 0px 0px",
          }}
          onClick={() => setIsPago(false)}
        >
          <span
            className="material-icons my-auto"
            style={{ cursor: "pointer", fontSize: 32 }}
          >
            reply
          </span>
        </Button>
      </Grid>
      {useDataBajas.certificadoVencido && (
        <Grid container spacing={2} style={{ paddingTop: 8 }}>
          <Grid item xs={7}>
            <AlertReusable
              severity={"error"}
              text={useDataBajas.certificadoVencido.mensaje}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <TabsPago
            tab={tab}
            setTab={setTab}
            proveedor={proveedor}
            tieneAoM={tieneAoM}
            aplicarRetencion={useDataBajas.aplicarRetencion}
          />
        </Grid>
        <Formik
          initialValues={initialValues(modalidadCheques)}
          enableReinitialize={false}
          onSubmit={async (values) => {
            setDisabledButton(true);
            setLoadingPago(true);
            //Validamos cosas extras. Si esto retorna true significa que hay errores
            const errorPost = validationExtra(
              useComprasNC,
              dataGral,
              setErrorResponsable,
              useEmpleado,
            );

            if (errorPost) {
              setLoadingPago(false);
              setDisabledButton(false);
              return;
            }

            if (sobrante > 0) {
              setLoadingPago(false);
              setOpenModalPagoMayor(true);
              setPendingValues(values); // Guardamos temporalmente los valores para usarlos si confirma el cliente
            } else {
              handlePagarCompra(values);
            }
          }}
          validationSchema={validationYup(saldoCC)}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
            errors,
            touched,
            setFieldError,
          }) => (
            <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
              {tab === 0 && (
                <Grid container spacing={2}>
                  {observacionesFiltradas.length ? (
                    <Grid item xs={12} md={8}>
                      <UltimaObservacion
                        proveedor={proveedor}
                        setModalObservacion={setModalObservacion}
                        observacionesFiltradas={observacionesFiltradas}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <MontosTotales
                      useTotales={useTotales}
                      useComprasNC={useComprasNC}
                      dataGral={dataGral}
                    />
                  </Grid>
                  <Grid item xs={7} style={{ margin: 0 }}>
                    <Card style={{ padding: 8 }}>
                      <ComponentsCompras
                        useComprasNC={useComprasNC}
                        dataGral={dataGral}
                        setDataGral={setDataGral}
                        proveedor={proveedor}
                        tieneAoM={tieneAoM}
                        useTotales={useTotales}
                        setTab={setTab}
                        dataDescuentos={{
                          descuentoMonto,
                          setDescuentoMonto,
                          useDescUnico,
                        }}
                        useDataBajas={useDataBajas}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={5} style={{ margin: 0 }}>
                    <Grid container spacing={2}>
                      {useDataBajas &&
                        useDataBajas.aplicarRetencion &&
                        tieneAoM && (
                          <Grid item xs={12}>
                            <CampoRetencion
                              useTotales={useTotales}
                              useComprasNC={useComprasNC}
                              setTab={setTab}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <MediosDePago
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          saldoCC={saldoCC}
                          modalidadCheques={modalidadCheques}
                          useTotales={useTotales}
                          dataGral={dataGral}
                          setFieldError={setFieldError}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <OtrosCampos
                          useEmpleado={useEmpleado}
                          useQueryEmpleado={useQueryEmpleado}
                          setLoadingEmpleado={setLoadingEmpleado}
                          errorResponsable={errorResponsable}
                          setErrorResponsable={setErrorResponsable}
                          dataGral={dataGral}
                          setDataGral={setDataGral}
                          useTotales={useTotales}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {validateButtonPagar() ? (
                          <TooltipWithoutIcon
                            placement={"top"}
                            body={
                              <h6
                                style={{
                                  fontSize: 15,
                                  marginBottom: 0,
                                  marginTop: 0,
                                }}
                              >
                                {Number(useTotales.sobrante) > 0
                                  ? "El monto ingresado es mayor al monto a pagar. Para pagos parciales no debe existir un sobrante."
                                  : "Debe completar el monto pendiente"}
                              </h6>
                            }
                          >
                            <span>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                outline="none"
                                fullWidth
                                disabled={true}
                              >
                                REGISTRAR PAGO
                              </Button>
                            </span>
                          </TooltipWithoutIcon>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            outline="none"
                            fullWidth
                            disabled={disabledButton}
                          >
                            REGISTRAR PAGO
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {tab === 1 && (
                <ComponentsPagosRetenciones
                  useComprasNC={useComprasNC}
                  dataGral={dataGral}
                  useTotales={useTotales}
                />
              )}

              {tab === 2 && (
                <ComponentsComprobantes
                  useComprasNC={useComprasNC}
                  selection={selection}
                />
              )}
            </Form>
          )}
        </Formik>
      </Grid>
      {modalObservacion && (
        <ObservacionesProveedor
          open={modalObservacion}
          proveedor={proveedor}
          tittle={"Observaciones del proveedor"}
          ocultarAgregarObservacion={true}
          idProveedor={idProveedor}
          setOpen={setModalObservacion}
        />
      )}

      {useDataBajas.openModal && (
        <ModalReutilizable
          open={useDataBajas.openModal}
          handleClose={useDataBajas.handleCloseModal}
          title="Información de retenciones"
          content={
            <ContentModalInfo
              dataEstadoRetencionPago={useDataBajas.dataEstadoRetencionPago}
              dataGral={dataGral}
              setDataGral={setDataGral}
              optionSelected={useDataBajas.optionSelected}
              setOptionSelected={useDataBajas.setOptionSelected}
            />
          }
          handleSubmit={useDataBajas.handleConfirmacion}
          messageAceptar={
            useDataBajas.optionSelected &&
            useDataBajas.optionSelected.value === 5
              ? "EDITAR DATOS"
              : "ACEPTAR"
          }
        />
      )}
      <ModalConfirm
        open={openModalPagoMayor}
        handleClose={handleModalCancelSubmit}
        title="Pago mayor al monto de deuda"
        body={modalMsg}
        handleSubmit={handleModalConfirmSubmit}
        buttons={{ cancel: "Cancelar", submit: "Aceptar" }}
      />
      <CircularBackdrop
        openBackdrop={
          loadingPago ||
          useComprasNC.loadingComprobantes ||
          useDataBajas.loadingDataRetenciones
            ? true
            : false
        }
      />
    </Container>
  );
}
