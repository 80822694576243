import React from "react";
import {
  Grid,
  Divider,
  FormControlLabel,
  Switch,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TableCompras from "./TableCompras";
import TooltipMoreInfoMaterial from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import moment from "moment";
import Descuentos from "./Descuentos";

export default function ComponentsCompras({
  useComprasNC,
  dataGral,
  setDataGral,
  proveedor,
  tieneAoM,
  useTotales,
  setTab,
  dataDescuentos,
  useDataBajas,
}) {
  const handleChange = (name, value) => {
    setDataGral((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setComprasTotal = () => {
    let copyCompras = useComprasNC.newCompras.slice();

    copyCompras.forEach((c) => {
      c.errorMontoPagar = false;
      c.msgErrorMontoPagar = "";
      c.montoAPagar = c.newMontos.total;
      c.porcPago = 100;
    });

    useComprasNC.setNewCompras(copyCompras);
  };

  const setDescuentoCero = () => {
    setDataGral((prev) => ({
      ...prev,
      descuento: "",
    }));
  };

  const tienePagosPorPlazo = () => {
    let tienePagos = useComprasNC.newCompras.some((c) => c.isPlazoPago);
    return tienePagos;
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <FormControlLabel
            control={
              <Switch
                checked={dataGral.pagoCompleto}
                onChange={(e) => {
                  handleChange("pagoCompleto", e.target.checked);
                  !e.target.checked && dataDescuentos.setDescuentoMonto("");
                  !e.target.checked && dataDescuentos.useDescUnico.resetData();
                  e.target.checked && setComprasTotal();
                }}
                color="primary"
                disabled={dataGral.pagoNegativo || tienePagosPorPlazo()}
              />
            }
            label="Pago completo"
            style={{ marginRight: 0 }}
          />
          {tienePagosPorPlazo() && (
            <div style={{ paddingBottom: 5 }}>
              <TooltipMoreInfoMaterial
                titleTooltip={
                  <h6
                    style={{
                      fontSize: 15,
                      marginBottom: 0,
                      marginTop: 0,
                    }}
                  >
                    No se permiten pagos parciales al pagar un plazo de una
                    compra principal
                  </h6>
                }
                color="#000"
                position={"top"}
              />
            </div>
          )}
        </div>
      </Grid>

      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <FormControl fullWidth>
            <DateTimePicker
              autoOk
              value={dataGral.fechaReal || ""}
              onChange={(date) => {
                setDescuentoCero();
                handleChange("fechaReal", date);
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              style={{ background: "white" }}
              inputVariant="outlined"
              size="small"
              label="Fecha de pago real"
              name="fechaReal"
              minDate={new Date(2023, 6, 1)}
              disabled={useComprasNC.loadingComprobantes}
              InputProps={{
                endAdornment: (
                  <TooltipMoreInfoMaterial
                    titleTooltip={
                      <h6
                        style={{
                          fontSize: 15,
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        {useDataBajas &&
                        useDataBajas.aplicarRetencion &&
                        tieneAoM
                          ? "Si modifica esta fecha, se pueden aplicar cambios en los pagos y calculos de retenciones. También se volverán a realizar los calculos iniciales"
                          : "Si modifica esta fecha, se volverán a realizar los calculos iniciales"}
                      </h6>
                    }
                    color="#000"
                    position={"top"}
                  />
                ),
              }}
            />
          </FormControl>
        </MuiPickersUtilsProvider>
      </Grid>
      <Descuentos
        dataGral={dataGral}
        useComprasNC={useComprasNC}
        handleChange={handleChange}
        useTotales={useTotales}
        descuentoMonto={dataDescuentos.descuentoMonto}
        setDescuentoMonto={dataDescuentos.setDescuentoMonto}
        useDescUnico={dataDescuentos.useDescUnico}
      />

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "calc(100vh - 480px)",
          overflow: "auto",
        }}
      >
        <TableCompras useComprasNC={useComprasNC} dataGral={dataGral} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
