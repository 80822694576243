import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Grid, Tab, Tabs, Box, Divider } from "@material-ui/core";
import Retenciones from "./Retenciones";
import { validateFormRetencion } from "./utils";
import { listEstadoTributario } from "../../../requests/urls";
import request from "../../../requests/request";
import FormBaseCliProv from "../../../components/ait-reusable/FormBaseCliProv";
import CamposAdicionales from "./CamposAdicionales";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import { useProvincias } from "../../../customHooks/useProvincias";

export default function FormProveedor({
  proveedor,
  isEdicion,
  ivas,
  isSubmittingForm,
  arrayObservaciones,
  setArrayObservaciones,
  ...props
}) {
  const [estados, setEstados] = useState([]);
  const { provincias } = useProvincias();
  const [tabSelected, setTabSelected] = useState("datos_generales");
  let tabs = [
    { value: "datos_generales", label: "Datos generales" },
    { value: "retenciones", label: "Retenciones" },
  ];

  const handleOnChangeTab = (_event, value) => {
    setTabSelected(value);
  };

  const getEstadosTributarios = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listEstadoTributario,
      });
      setEstados(response.data);
    } catch (error) {
      console.error(error);
      setEstados([]);
    }
  };

  useEffect(() => {
    getEstadosTributarios();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...proveedor }}
      validationSchema={Yup.object().shape({
        razonSocial: Yup.string().required("Ingrese nombre o razón social."),
        alias: Yup.string().max(
          10,
          "El alias debe tener como máximo 10 caracteres.",
        ),
        CUIT: Yup.number()
          .min(9999999999, "El CUIT ingresado no es válido.")
          .max(99999999999, "El CUIT ingresado no es válido.")
          .when("idEstadoTributario", {
            is: (value) => value && (value === 1 || value === 2 || value === 3),
            then: Yup.number().required("Ingrese un nro. CUIT válido."),
          }),
        numeroDocumento: Yup.number()
          .min(9999999, "El nro de documento no es válido.")
          .max(99999999, "El nro de documento no es válido.")
          .when("idEstadoTributario", {
            is: (value) => value && (value === 4 || value === 5),
            then: Yup.number().required("Ingrese un nro. de documento válido."),
          })
          .nullable(),
        responsable_cuenta: Yup.string()
          .max(
            50,
            "El nombre del responsable no puede superar los 50 caracteres.",
          )
          .nullable(),
        sitioWeb: Yup.string()
          .max(100, "El sitio web no puede superar los 100 caracteres.")
          .nullable(),
        correoElectronico: Yup.string()
          .email("El correo electrónico no es válido.")
          .nullable(),
        provincia: Yup.number()
          .required("Seleccione una provincia.")
          .nullable(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          let error = validateFormRetencion(values, setErrors);
          !error && props.handleSubmit(values);
        } catch (err) {
          setErrors({ submit: err.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form
          style={{ width: "100%", paddingTop: "10px" }}
          onSubmit={handleSubmit}
          {...props}
        >
          <Tabs
            indicatorColor="primary"
            value={tabSelected}
            textColor="primary"
            onChange={handleOnChangeTab}
            aria-label="simple tabs example"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Box pb={1}>
            <Divider />
          </Box>
          {tabSelected === "datos_generales" && (
            <FormBaseCliProv
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              estados={estados}
              isCliente={false}
              provincias={provincias}
              proveedor={proveedor}
              arrayObservaciones={arrayObservaciones}
              setArrayObservaciones={setArrayObservaciones}
              setFieldValue={setFieldValue}
            >
              <CamposAdicionales
                touched={touched}
                errors={errors}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </FormBaseCliProv>
          )}
          {tabSelected === "retenciones" && (
            <Retenciones
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            />
          )}

          <Grid container className="justify-content-center mt-3">
            <Grid item>
              <ButtonAceptar
                disabled={isSubmittingForm}
                click={handleSubmit}
                message={(isEdicion && "GUARDAR CAMBIOS") || "REGISTRAR"}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
