import React from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function TableEtiquetas({ data, setTableData }) {
  const columns = [
    "Código de proveedor",
    "Código original",
    "Descripción del artículo",
    "Proveedor del artículo",
    "Cantidad de etiquetas",
    "Acciones",
  ];

  const handleDeleteRow = (rowIndex) => {
    setTableData((prevData) =>
      prevData.filter((_, index) => index !== rowIndex),
    );
  };

  const handleEditCantidadEtiquetas = (rowIndex, value) => {
    setTableData((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, cantidadEtiquetas: value } : row,
      ),
    );
  };

  return (
    <TableContainer style={{ display: "inline-table" }}>
      <Table name="table" stickyHeader size="small" />
      <TableHead style={{ maxHeight: 15 }}>
        <TableRow>
          {columns.map((column, index) => {
            return (
              <TableCell
                align="center"
                style={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  fontWeight: "bolder",
                }}
                key={index}
              >
                {column}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map((item, index) => (
          <TableRow
            style={{ cursor: "pointer" }}
            hover
            onClick={(event) => console.log(event)}
            key={index}
          >
            <TableCell align="center">
              {item.codProveedor ? item.codProveedor : "---"}
            </TableCell>
            <TableCell align="center">
              {item.codOriginal ? item.codOriginal : "---"}
            </TableCell>
            <TableCell align="center">
              {item.descripcionProveedor
                ? item.descripcionProveedor.toUpperCase()
                : "---"}
            </TableCell>
            <TableCell align="center">
              {item.proveedor ? item.proveedor : "---"}
            </TableCell>
            <TableCell align="center">
              <TextField
                type="number"
                variant="outlined"
                inputProps={{
                  min: 1,
                  "aria-label": "Cantidad de etiquetas",
                  style: { textAlign: "center" },
                }}
                value={item.cantidadEtiquetas}
                onChange={(e) =>
                  handleEditCantidadEtiquetas(index, e.target.value)
                }
                size="small"
                style={{ width: "80px" }}
              />
            </TableCell>
            <TableCell align="center">
              <IconButton
                onClick={() => handleDeleteRow(index)}
                color="secondary"
                size="small"
                style={{ padding: 6 }}
                aria-label="Eliminar"
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
}
