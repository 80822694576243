export const useSeleccionCompras = () => {
  const handleSelectOne = (
    factura,
    facturas,
    selection,
    setSelection,
    setNewFacturas,
  ) => {
    const exist = selection.some((x) => x.idSelection === factura.idSelection);

    let esConPlazoPago = factura.plazos_pago;
    if (exist) {
      // Caso: La factura ya está seleccionada

      /**
       * En esta selección pueden pasar 3 cosas:
       * 1. Que el comprobante sea con plazos de pago: Se deben quitar todos sus plazos
       * 2. Que el comprobante sea un plazo específico: Si el comprobante principal esta seleccionado entonces se debe quitar el comprobante principal
       * 3. Que el comprobante sea un comprobante normal: Se quita normalmente
       */
      if (esConPlazoPago) {
        if (factura.plazo) {
          // Caso 2: Es un plazo específico
          const idsToRemove = [`${factura.id}-0`, factura.idSelection];
          const newSelection = selection.filter(
            (x) => !idsToRemove.includes(x.idSelection),
          );
          setSelection(newSelection);

          deselectDueDateInvoice(facturas, factura, setNewFacturas);
        } else {
          // Caso 1: Es el comprobante principal con plazos de pago
          const newSelection = selection.filter((x) => x.id !== factura.id);
          setSelection(newSelection);

          deselectMainInvoice(facturas, factura, setNewFacturas);
        }
      } else {
        // Caso 3: Es un comprobante normal
        setSelection(
          selection.filter((x) => x.idSelection !== factura.idSelection),
        );
      }
    } else {
      // Caso: La factura no está seleccionada

      /**
       * En esta selección pueden pasar 3 cosas:
       * 1. Que el comprobante sea con plazos de pago: Se deben seleccionar todos sus plazos
       * 2. Que el comprobante sea un plazo específico: Se debe validar si seleccionado ese comprobante se completa la seleccion de todos los plazos y si es asi entonces seleccionar el comprob. principal
       * 3. Que el comprobante sea un comprobante normal: Se selecciona normalmente
       */
      if (esConPlazoPago) {
        if (factura.plazo) {
          // Caso 2: Es un plazo específico
          const plazosSeleccionados = selection.filter(
            (x) => x.id === factura.id && x.isPlazoPago,
          );
          const allPlazosSeleccionados =
            plazosSeleccionados.length + 1 === factura.plazos_pago.length;

          if (allPlazosSeleccionados) {
            // Seleccionar todos los plazos y el comprobante principal
            const newSelection = facturas.filter((x) => x.id === factura.id);
            const updatedSelection = selection
              .filter((x) => x.id !== factura.id)
              .concat(newSelection);
            setSelection(updatedSelection);
          } else {
            // Agregar solo el plazo actual
            const newSelection = facturas.filter(
              (x) => x.idSelection === factura.idSelection,
            );
            setSelection(selection.concat(newSelection));
          }

          selectDueDateInvoice(facturas, factura, setNewFacturas);
        } else {
          // Caso 1: Seleccionar todos los plazos y el comprobante principal
          const newSelection = facturas.filter((x) => x.id === factura.id);
          const updatedSelection = selection
            .filter((x) => x.id !== factura.id)
            .concat(newSelection);
          setSelection(updatedSelection);

          selectMainInvoice(facturas, factura, setNewFacturas);
        }
      } else {
        // Caso 3: Es un comprobante normal
        const newSelection = facturas.filter(
          (x) => x.idSelection === factura.idSelection,
        );
        setSelection(selection.concat(newSelection));
      }
    }
  };

  const selectMainInvoice = (facturas, factura, setNewFacturas) => {
    /**
     * Función que se llama cuando se hace click en el checkbox del comprobante principal que tiene plazos de pago.
     * Al seleccionar este comprobante, se deben deshabilitar todos los plazos menos el último.
     */
    let copyFacturas = [...facturas];
    let cantidadPlazosNoPagados = getLengthPlazos(factura);
    let cantidadVistos = 0;
    copyFacturas.forEach((f) => {
      if (f.id === factura.id && f.isPlazoPago && !f.plazo.pagado) {
        cantidadVistos++;
        f.disabled = cantidadVistos === cantidadPlazosNoPagados ? false : true;
      }
    });
    setNewFacturas(copyFacturas);
  };

  const deselectMainInvoice = (facturas, factura, setNewFacturas) => {
    /**
     * Función que se llama cuando se deselecciona el comprobante principal que tiene plazos de pago.
     * Al deseleccionar este comprobante, se debe habilitar solo el primero
     */
    let copyFacturas = [...facturas];
    let cantidadVistos = 1;
    copyFacturas.forEach((f) => {
      if (f.id === factura.id && f.isPlazoPago && !f.plazo.pagado) {
        f.disabled = cantidadVistos === 1 ? false : true;
        cantidadVistos++;
      }
    });
    setNewFacturas(copyFacturas);
  };

  const selectDueDateInvoice = (facturas, factura, setNewFacturas) => {
    /**
     * Función que se llama cuando se selecciona un plazo de pago de un comprobante principal.
     * Al seleccionar un plazo, se debe ralizar lo siguiente:
     * 1. Si existe un plazo siguiente, se debe habilitar
     * 2. Todos los plazos anteriores se deben deshabilitar
     */
    let copyFacturas = [...facturas];
    let plazoEncontrado = false; //Sirve como bandera para saber que encontró el plazo y que el siguiente plazo se habilite
    copyFacturas.forEach((f) => {
      if (f.id === factura.id && f.isPlazoPago && !f.plazo.pagado) {
        if (f.idSelection !== factura.idSelection) {
          f.disabled = plazoEncontrado ? false : true;
          plazoEncontrado = false;
        } else {
          plazoEncontrado = true;
        }
      }
    });
    setNewFacturas(copyFacturas);
  };

  const deselectDueDateInvoice = (facturas, factura, setNewFacturas) => {
    /**
     * Función que se llama cuando se deselecciona un plazo de pago de un comprobante principal.
     * Al deseleccionar un plazo, se debe realizar lo siguiente:
     * 1. Todos los plazos siguientes se deben deshabilitar.
     * 2. Si existe un plazo anterior, se debe habilitar.
     */
    let copyFacturas = [...facturas];
    let deshabilitarPlazos = false; // Bandera para deshabilitar los plazos siguientes
    let ultimoPlazoHabilitado = null; // Para habilitar el último plazo anterior

    copyFacturas.forEach((f) => {
      if (f.id === factura.id && f.isPlazoPago && !f.plazo.pagado) {
        if (deshabilitarPlazos) {
          // Deshabilita todos los plazos siguientes
          f.disabled = true;
        } else if (f.idSelection === factura.idSelection) {
          // Encuentra el plazo actual y activa la bandera para deshabilitar los siguientes
          deshabilitarPlazos = true;
        } else {
          // Registra el último plazo anterior como habilitable
          ultimoPlazoHabilitado = f;
        }
      }
    });

    // Habilita el último plazo anterior si existe
    if (ultimoPlazoHabilitado) {
      let index = copyFacturas.indexOf(ultimoPlazoHabilitado);
      copyFacturas[index].disabled = false;
    }

    setNewFacturas(copyFacturas);
  };

  const getLengthPlazos = (factura) => {
    if (factura.plazos_pago) {
      return factura.plazos_pago.filter((p) => !p.pagado).length;
    }
    return 0;
  };

  return {
    useSeleccion: {
      handleSelectOne,
    },
  };
};
