import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { isInteger } from "formik";

export default function ResumenPagos({ data, styles }) {
  function MontoSinInteres(item) {
    return Number(item.monto);
  }

  function Interes(item) {
    return Number(
      Number(item.monto) -
        Number(item.monto) / (1 + Number(item.pagos_tarjeta.porcentaje)),
    );
  }

  return data.map(
    (item, i) =>
      item.medio_pago && (
        <View key={i} style={{ width: 200 }}>
          <Text style={styles}>{`- ${
            item.pagos_tarjeta
              ? item.pagos_tarjeta.id_tarjeta.nombre
              : item.medio_pago.nombre
          } ${
            item.pagos_tarjeta
              ? " (" + item.pagos_tarjeta.cantidad_cuotas + " cuotas)"
              : ""
          }: $${Number(
            item.pagos_tarjeta
              ? isInteger(MontoSinInteres(item))
                ? MontoSinInteres(item)
                : MontoSinInteres(item).toFixed(2)
              : isInteger(Number(item.monto))
                ? Number(item.monto)
                : Number(item.monto).toFixed(2),
          ).toLocaleString("es-AR")}`}</Text>
          {item.pagos_tarjeta && (
            <View>
              <Text
                style={{
                  marginLeft: 5,
                  fontSize: 8,
                  paddingLeft: 2,
                  paddingBottom: 2,
                }}
              >
                {`*Interés: $${Number(
                  isInteger(Interes(item))
                    ? Interes(item)
                    : Interes(item).toFixed(2),
                ).toLocaleString("es-AR")}`}
              </Text>
              {Interes(item) > 0 && (
                <Text
                  style={{
                    marginLeft: 5,
                    fontSize: 8,
                    paddingLeft: 2,
                    paddingBottom: 2,
                  }}
                >
                  {`*Total tarjeta: $${Number(
                    Interes(item) + MontoSinInteres(item),
                  ).toLocaleString("es-AR")}`}
                </Text>
              )}
            </View>
          )}
          {item.pago_cheque && (
            <Text
              style={{
                marginLeft: 5,
                fontSize: 8,
                paddingLeft: 2,
                paddingBottom: 2,
              }}
            >
              {`*Nro: ${item.pago_cheque.numero_cheque}`}
            </Text>
          )}
        </View>
      ),
  );
}
