import axios from "axios";
import store from "../Redux/Store/index";
import { showModal } from "../Redux/Actions";

const axiosInstance = axios.create({
  baseUrl: "/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export function setupAxiosInterceptors() {
  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(showModal(true));
      }
      return Promise.reject(error);
    },
  );
}

export default axiosInstance;
