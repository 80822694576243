/**--------------------------CLIENTES---------------------------------------- */
export const getPagosCliente = (idCliente) =>
  `/api/pagos/cliente/${idCliente}/list/`;
export const getClienteSucursal = (idCliente) => `/api/cliente/${idCliente}/`;
export const getListClienteSucursal = "/api/cliente/list/";
export const createClienteSucursal = "/api/cliente/create/";
export const createClienteSucursalCuitApi = "/api/cliente/create-cuit/";
export const updateClienteSucursal = (idCliente) =>
  `/api/cliente/${idCliente}/update/`;
export const readClientesVenta = `/api/sucursal/clienteDeSucursal/1`;
export const anulacionPagoCliente = (id) => `/api/pagos/anular-pagos/${id}/`;
export const anulacionOrdenPago = "/api/pagos/anular-orden-carga-saldo/";
export const anulacionOrdenRetiro = "/api/pagos/anular-orden-retiro-saldo/";
export const deleteClient = (id) => `/api/cliente/delete/${id}/`;
export const excelAgendaClientesURL = "/api/cliente/agenda-clientes/excel/";
export const informeCtaCte = (id) => `/api/cliente/movimiento-cta-cte/${id}/`;
export const informeVtasAdeudas = (id) =>
  `/api/cliente/ventas-adeudadas/${id}/`;
export const sendMailReporteDeuda = (idCliente) =>
  `/api/informes/reporte-deuda-cliente/${idCliente}/`;
export const getRecibosComprobantes = `/api/pagos/obtener-recibos/`;
export const modificarCuentaCorriente = (idCliente) =>
  `/api/cliente/activar-desactivar/${idCliente}/`;
export const createObservacion = (idCliente) =>
  `/api/cliente/create-observacion/${idCliente || ""}/`;
export const getObservaciones = (idCliente) =>
  `/api/cliente/observacion-list/${idCliente}/`;
export const deleteObservacion = (
  idObservacion,
) => `/api/cliente/delete-observacion/${idObservacion}/
`;
export const downloadReporteFacturasAdeudadas = (idCliente) =>
  `/api/venta/ventas-pdf/${idCliente}/`;
export const movimientosBilleteraEnPdf = (idCliente) =>
  `/api/pagos/movimiento-billetera/pdf/${idCliente}/`;
export const cuentaCorrientePdf = () =>
  `/api/cliente/informe-cuenta-corriente/`;
export const getMontoDeudaClientesPeriod = `/api/cliente/deuda-total-period/`;

/**------------------------------------------------------------------------------*/

/**--------------------------------BILLETERA------------------------------------ */
export const postCargarSaldo = (idCliente) =>
  `/api/pagos/cargar-saldo/${idCliente}/`; /*** si monto postivo es ingreso si es negativo es egreso */
export const postRetirarSaldo = (idCliente) =>
  `/api/pagos/retiro-saldo/${idCliente}/`;
export const obtenerIngresos = (idCliente) =>
  `/api/pagos/ingreso/${idCliente}/`;
export const obtenerEgresos = (idCliente) => `/api/pagos/egreso/${idCliente}/`;
export const getTipoMovCtaCte = "/api/pagos/tipo-movimiento-cta-cte/";
export const obtenerPagos = (idCliente) => `/api/pagos/informe-pagos/`;
/**------------------------------------------------------------------------------*/
export const obtenerTotales = (idCliente) =>
  `/api/pagos/totales-movimientos/${idCliente}/`;
/**--------------------------------VENTAS-------------------------------------- */
export const postVenta = "/api/venta/";
export const sendMailVenta = (idVenta) => `/api/venta/enviar-email/${idVenta}/`;
export const getTicket = (idVenta) => `/api/afip/ticket/${idVenta}/`;
export const listFacturasAdeudadas = (idCliente) =>
  `/api/venta/mayorista/deuda/${idCliente}`;
export const saldoVentas = "/api/venta/consultar-saldos/";
export const saldarVentas = (idCliente) =>
  `/api/venta/saldar-facturas/${idCliente}/`;
export const conciliacionVentas = "/api/venta/generar-conciliacion-ventas/";
export const postPagoParcial = "/api/venta/cobroParcial/";
export const consultarDetalleVenta = `/api/venta/datos/`;
export const comprobanteAFactura = (idVenta) =>
  `/api/venta/comprobante-a-factura/${idVenta}/`;
export const readDescuentos = `/api/venta/descuento/True/`;
export const readMedioPago = `/api/venta/mediosPago/`;
export const getInformeMisVentas = "/api/venta/lista/";
export const getTotalesMedioPago = "/api/venta/total/medio-pago/";
export const getTotalesTipoComprobante = "/api/venta/total/tipo-comprobante/";
export const getTotalesRecaudados = "/api/venta/totales/";
export const listTipoComprobante = "/api/venta/tipos-comprobante/";
export const listMedioPago = "/api/venta/mediosPago/";
export const validarComprobante = "/api/venta/validate/";
export const deudaTotalCliente = (idCliente) =>
  `/api/venta/total-adeudadas/${idCliente}/`;
export const readVentaReVenta = (idVenta) =>
  `/api/venta/nueva-venta/${idVenta}/get/`;
export const actualizarDetalleVenta = (idVenta) =>
  `/api/venta/detalles-updated/${idVenta}/list/?update_price=1`;
export const facturarATerceros = "/api/venta/facturar/a-terceros/";
export const facturaAgrupada = "/api/venta/facturar/factura-agrupada/";

export const ultimaVenta = (idClienteSucursal) =>
  `/api/venta/ultima-venta/${idClienteSucursal}/`;
export const readTiposDeVenta = "/api/venta/tipo-venta/list/";
export const createTipoDeVenta = "/api/venta/tipo-venta/create/";
export const deleteTipoDeVenta = "/api/venta/tipo-venta/anular/";
export const putDefaultTipoDeVenta = "/api/venta/tipo-venta/default/";
export const descargarExcelVentas = "/api/venta/export/excel/ventas/";
export const getVentasInFactAgrupada = (idVenta) =>
  `/api/venta/ventas-in-factura-agrupada/${idVenta}/`;
export const anularVentasAgrupadas = () =>
  `/api/venta/anular-ventas-agrupadas/`;
export const descargarExcelVentasDeVentas =
  "/api/venta/export/excel/ventas-de-ventas/";
export const getDataRotuloVenta = (idVenta) =>
  `/api/venta/data-rotulo/${idVenta}/`;
/**------------------------------------------------------------------------------*/

/**--------------------------------REMITO-------------------------------------- */
export const postRemito = "/api/remito/remito/create/";
export const getRemito = (idRemito) => `/api/remito/remito/${idRemito}/`;
export const getDataRemitosInforme = "/api/remito/remito/informe/";
export const eliminarRemito = "/api/remito/remito/anular/";
export const actualizarPreciosRemito = (idRemito) =>
  `/api/remito/update-precios/${idRemito}/`;
export const sendEmailRemito = (idRemito) =>
  `/api/remito/enviar-email/${idRemito}/`;
export const addObservacionesRemito = (id) =>
  `/api/remito/create-observacion/${id}/`;
export const getObservacionesRemito = (id) =>
  `/api/remito/list-observaciones/${id}/`;
export const deleteObservacionRemito = (id) =>
  `/api/remito/delete-observacion/${id}/`;
/**------------------------------------------------------------------------------*/

/**-----------------------MOIVIMIENTOS DE STOCK--------------------------------- */
export const getInformeMovimientoStock = "/api/stock/detalles/list/";
export const getDetallesMovimientoStock = (idMovimiento) =>
  `/api/stock/detalles/${idMovimiento}/list/`;
export const templateExcelStockMasivoURL =
  "/api/stock/plantilla-excel-carga-masiva/";
export const uploadExcelStockMasivoURL =
  "/api/stock/upload-excel-carga-masiva/";
export const downloadExcelStockNoProcesados =
  "/api/stock/download-excel-stock-no-procesados/";
export const getMotivosMovStock = "/api/stock/list/motivos-mov-stock";
export const getOrigenesMovStock = "/api/stock/list/origenes-mov-stock";

/**---------------------------------------------------------------------------- */

/**-----------------------------PROVEEDORES------------------------------------ */
export const getProveedorSucursal = (idProveedor) =>
  `/api/proveedor/${idProveedor}/`;
export const createProveedor = "/api/proveedor/";
export const updateProveedor = (idProveedor) =>
  `/api/proveedor/${idProveedor}/update/`;
export const getParametrosProveedor = (idProveedor) =>
  `/api/proveedor/parametros/${idProveedor}/`;
export const listProveedores = "/api/proveedor/list/all/";
export const listProveedoresPaginated = "/api/proveedor/lista/";
export const getParametrosListaPrecios = (idProveedor) =>
  `/api/proveedor/parametros-lista-precios/${idProveedor}/`;
export const postParametrosPrecios = `/api/proveedor/parametros-lista-precios/create/`;
export const putParametrosPrecios = (idProveedor) =>
  `/api/proveedor/parametros-lista-precios/${idProveedor}/update/`;
export const updateListas = (idProveedor) =>
  `/api/listas/actualizar-proveedor/${idProveedor}/`;
export const updateListStock = "/api/stock/actualizar-stock-lista/";
export const deleteProveedor = (id) => `/api/proveedor/delete/${id}/`;
export const putPreciocosto = (id) => `/api/articulos/variacion-precio/${id}/`;
export const listArticulosxID = (id) => `/api/articulos/filter/${id}/`;
export const historialPrecios = "/api/articulos/historial-variacion/";
export const updateCompras = "/api/pagos/registar-pago-compra/";
export const exportarExcel = (id, tipoComprobante) =>
  `/api/proveedor/cuenta-corriente/reporte-excel/${id}/${tipoComprobante()}`;

export const pagoDeCompras = "/api/pagos/pagocompra/list/";
export const anulacionDeCompra = (id) => `/api/pagos/anular/${id}/`;
export const pagoDeCompra = (id) => `/api/pagos/pagocompra/${id}/`;
export const deudaProveedores = "/api/proveedor/deuda-total-week-month/";
export const deudaHistoricaProveedores =
  "/api/proveedor/deuda-total-historica/";

export const urlDeudaTotalPorProveedorExcel =
  "/api/proveedor/deuda-total-historica/excel/";

export const dataComprasNCs = (dataIds) =>
  `/api/pagos/data-compras-ncs/?${dataIds}`;
export const dataPagosConDescuento = "/api/pagos/pago-data/NCD/";
export const addObservacionPagoCompra = (id) =>
  `/api/pagos/add-observacion-pago/${id}/`;
export const addObservacionesProveedor = (id) =>
  `/api/proveedor/create-observacion/${id}/`;
export const getObservacionesProveedor = (id) =>
  `/api/proveedor/list-observaciones/${id}/`;
export const deleteObservacionProveedor = (id) =>
  `/api/proveedor/delete-observacion/${id}/`;

export const getTotalDevolucionesProveedor = (idProveedor) =>
  `/api/proveedor/total-devoluciones/${idProveedor}/`;
export const getMotivosBajaRetencion = () =>
  `/api/proveedor/motivos-baja-retencion/list/`;
export const getDataBajasRetencionesProveedor = (dataIds) =>
  `/api/pagos/data-baja-retenciones/?${dataIds}`;
/**------------------------------------------------------------------------------*/

/**-------------------------CAJA----------------------------------------------- */
export const postMotivoMovCaja = "/api/movimientoDeCaja/motivos/";
export const getInformeMovimientoCaja =
  "/api/movimientoDeCaja/obtener-movimiento-periodo/";
export const getTotalesCaja = "/api/movimientoDeCaja/totales/";
export const anularMov = (idMovimiento) =>
  `/api/movimientoDeCaja/anular-movimiento/${idMovimiento}/`;
export const getMotivosMovimientosCaja = ({ entrada, all }) =>
  `/api/movimientoDeCaja/motivos/?entrada=${entrada || ""}&all=${all || ""}`;
/**------------------------------------------------------------------------------*/

/**------------------------------ARTICULOS------------------------------------ */
export const actualizarPrecio = "/api/articulos/precio-venta/";
export const postPreciosArticulo = "/api/articulos/precios/";
export const postArticulo = "/api/articulos/create/";
export const putArticulo = (id) => `/api/articulos/${id}/update/`;
export const getArticuloPorID = (id) => `/api/articulos/${id}/get/`;
// export const putArticulo = (idArticulo)  => `/api/articulos/${idArticulo}/update/`;
export const getArticulosCatalogo = (idProveedor, actualPage) =>
  `/api/repuestos/proveedor/${idProveedor}/lista/${actualPage}/`;
export const getMisArticulos = (idProveedor) =>
  `/api/articulos/list/${idProveedor}/`;
export const getExcelArticulos = (idProveedor) =>
  `/api/articulos/filtro-excel/${idProveedor}/`;
export const exportStock = "/api/stock/exportar-stock/";
export const putDescripcionMasiva = "/api/articulos/update-descripcion/";
export const postNewThridCode = "/api/articulos/update-auxiliar/";
export const exportarListaPrecios = "/api/articulos/lista-precios/";
export const updateIntegracionMeliBoxer =
  "/api/articulos/update-vinculacion-meli/";
export const ajusteStock = (idRepuestoSucursal) =>
  `/api/stock/ajuste-stock/${idRepuestoSucursal}`;
export const observacionArticulo = "/api/articulos/update-observaciones/";
export const readArticulosSucursales = "/api/sucursales_boxer/buscador/";
export const vincularRepuestoSucursales =
  "/api/sucursales_boxer/vincular-repuesto/";
export const editarVinculacionRepuestoSucursales =
  "/api/sucursales_boxer/editar-vinculacion/";
export const desvincularArticuloSucursal = "/api/sucursales_boxer/desvincular/";
export const desvincularGrupoSucursal =
  "/api/sucursales_boxer/desvincular/grupo/";
export const getDataArticulosRepetidos = "/api/articulos/repetidos/";
export const getDataDuplicados = "/api/articulos/data-repetidos/";
export const updateStockDuplicados = "/api/articulos/update-stock-repetidos/";
export const getPrevisualizacionDePrecios =
  "/api/articulos/previsualizacion-precios/";
export const getDescuentoProducto = (idProveedor, idMarca) =>
  `/api/articulos/descuentos/${idProveedor}/marca/${idMarca || 0}/`;
/**---------------------------------------------------------------------------- */

/**-----------------------------------UBICACIONES---------------------------- */
export const addUbicacion = "/api/ubicaciones/";
export const removeUbicacion = (id) => `/api/ubicaciones/${id}`;
export const listUbicaciones = (ubicacion) =>
  `/api/ubicaciones/?padre=${ubicacion}`;
/**------------------------------------------------------------------------------*/

/*----------------------------DESCRIPCIÓN---------------------- -----*/
export const readDescripcion = `/api/repuestos/descripcion/list/`;
export const postDescripcion = `/api/repuestos/descripcion/create/`;
export const deleteDescripcion = `/api/repuestos/anular-descripcion/`;
/**---------------------------------------------------------------------------- */

/**-----------------------------FACTURACIÓN--------------------- ----- */
export const tiposIva = "/api/facturacion/tipoIVA/";
export const readComprobantes = `/api/facturacion/tipos-comprobante/`;
export const readComprobantesRemito = `/api/facturacion/comprobante-remito/`;
export const listEstadoTributario = "/api/facturacion/estadoTributario/";
export const saldarFactura = (idCliente) =>
  `/api/venta/saldar-facturas/${idCliente}/`;
/**---------------------------------------------------------------------- */

/**-----------------------------PRESUPUESTO--------------------- */
export const sendMailPresupuesto = (idPresupuesto) =>
  `/api/presupuestos/enviar-email/${idPresupuesto}/`;
export const postPresupuesto = `/api/presupuestos/`;
export const readPresupuesto = (idPresupuesto) =>
  `/api/presupuestos/${idPresupuesto}/get/`;
export const getInformeMisPresupuestos = `/api/presupuestos/list/`;
export const actualizarDetallePresupuesto = (id) =>
  `/api/presupuestos/detalles/${id}/list/?update_price=1`;
export const readEstadosPresupuesto = "/api/presupuestos/estados/";
export const readTotalesPorEstado = "/api/presupuestos/totales-por-estado/";
export const addObservacionesPresupuesto = (id) =>
  `/api/presupuestos/create-observacion/${id}/`;
export const getObservacionesPresupuesto = (id) =>
  `/api/presupuestos/list-observaciones/${id}/`;
export const deleteObservacionPresupuesto = (id) =>
  `/api/presupuestos/delete-observacion/${id}/`;
/**-------------------------------------------------------------------- */

/**------------------------- AFIP -------------------------------- */
export const getIVAVentas = "/api/iva-informes/ventas/";
export const getIVACompras = "/api/iva-informes/compras/";
export const getIvaVentasDigital = "/api/iva-informes/ventas-digital/";
export const getIvaVentasAlicuotasDigital =
  "/api/iva-informes/ventas-alicuotas-digital/";
export const enableAfip = "/api/afip/cargarTaxPayer/";
export const consultaPadron = `/api/cliente/afip-ws/`;
export const readTiposIVA = `/api/genericos/tipos-vat/`;
export const getIIBBVentas = "/api/iva-informes/iibb/";
export const getMunicipalesVentas = "/api/iva-informes/municipales/";
export const libroIvaComprasDigitalTxt =
  "/api/iva-informes/compras-iva-digital-txt/";
export const libroIvaComprasDigitalAlicuotasTxt =
  "/api/iva-informes/compras-iva-digital-alicuotas-txt/";
export const getMontoMaximoAFIP = "/api/sucursal/get-monto-fact-afip/";
export const getInfoResultadoFiscal = "/api/iva-informes/resultado-fiscal/";
export const getInformacionFiscalUrl = "/api/afip/informacion-fiscal/";
export const getImpuestosLista = "/api/afip/impuestos/list/";
export const actualizarImpuestoArchivo = "/api/afip/impuesto/update/archivo/";
export const actualizarImpuesto = "/api/afip/impuesto/update/";
export const actualizarImpuestoVencido = "/api/afip/impuesto/update/vencido/";

/**-------------------------------------------------------------------- */

/**-----------------------------TARJETAS----------------------------- */
export const postTarjeta = "/api/tarjetas/create/";
export const getTarjetasPorMedioPago = (id) => `/api/tarjetas/mediopago/${id}/`;
export const getCoeficientesTarjeta = (id) =>
  `/api/tarjetas/coeficientes/${id}/`;
export const deleteTarjeta = (id) => `/api/tarjetas/${id}/delete/`;
export const deleteCoeficienteDeTarjeta = (id) =>
  `/api/tarjetas/coeficiente/${id}/delete/`;
export const postCoeficiente = "/api/tarjetas/coeficiente/create/";
export const readTarjetas = (idMedioPago) =>
  `/api/tarjetas/mediopago/${idMedioPago}/`;
export const readCoeficientes = (idTarjeta) =>
  `/api/tarjetas/coeficientes/${idTarjeta}/`;
/**-------------------------------------------------------------------- */

/**-------------TIPOS DE CHEQUES--------------- */
export const getTipoCheques = "/api/genericos/cheques/tipos/";
export const getModalidadCheques = "/api/genericos/cheques/modalidad/";
export const getPagosChequesCliente = (idCliente) =>
  `/api/pagos/cliente/${idCliente}/cheques/list/`;
export const putEstadoCheque = "/api/pagos/estado/";
export const postObservacionCheque = "/api/pagos/observacion-cheque/";
export const getChequesACobrar = "/api/pagos/cliente/cheques-a-cobrar/";
/**-------------------------------------------------------------------- */

/**-----------------RECIBOS--------------------*/
export const get_recibos = "/api/pagos/obtener-recibos/";
export const getRecibosOrdenes = "/api/pagos/obenter-recibo-orden/";
/**------------------------------------------ */

/**-----------------PROVINCIAS--------------------*/
export const getProvinciasApi = "/api/genericos/provincias/";
export const getProvinciaSucursalApi = "/api/sucursal/provincia/";
/**------------------------------------------ */

/**-------------COMPRA--------------- */
export const postCompra = "/api/compras/create/";
export const getMisCompras = "/api/compras/list/";
export const getComprasProveedor = "/api/compras/get-compra/";
export const getExcelCompras = (idCompra) =>
  `/api/compras/export-detalles/${idCompra}/`;
export const uploadExcel = "/api/compras/upload-excel/";
export const downloadExcel = "/api/compras/download-template/";
export const validateCompra = (idProveedor) =>
  `/api/compras/validar-compra/${idProveedor}`;
export const listTipoComprobanteCompras = "/api/compras/tipos-comprobante/";
export const excelCompras = "/api/compras/export-excel/";
export const readPlazosPagoCompra = "/api/pagos/plazos-pago-compra/";
export const createPlazoPagoCompra = "/api/pagos/plazos-pago-compra/create/";
export const deletePlazoPagoCompra = "/api/pagos/plazos-pago-compra/delete/";
/**------------------------------------------ */

/**-------------PERCEPCIÓN--------------- */
export const getPercepciones = "/api/genericos/tipos-percepcion/";
/**------------------------------------------ */
/**-------------INFORME PAGOS--------------- */
export const getRecaudadoPagos = "/api/pagos/total-recaudado/";
export const getTotalesPagoPorMedioPago = "/api/pagos/total-medio-pago/";
export const getInformePagos = "/api/pagos/informes-pagos/";
/**------------------------------------------ */

/**-------------INFORME PEDIDOS--------------- */
export const getEstados = "/api/pedidos/estados/list/";
export const postPedido = "/api/pedidos/create/";
export const getPedidos = `/api/pedidos/list/`;
export const getExcelPedidos = (idPedido) =>
  `/api/pedidos/export-detalles/${idPedido}/`;
export const putPedido = "/api/pedidos/update/";
export const readPedido = (idPedido) =>
  `/api/pedidos/read/?pedido_id=${idPedido}`;
export const readTotalesPedidos = "/api/pedidos/totales/";
export const createPedidoDesdeRepuesto = "/api/pedidos/create/desde-repuesto/";
export const postPutPedidoDesdeRepuesto = "/api/pedidos/create-update/";
/**------------------------------------------ */
/**----------------------Notas de credito---------------- */
export const getEstadosDeUnPedido = "/api/pedidos/estados/list/";
export const updateEstadoDeUnPedido = "/api/pedidos/update/estado/";
/**------------------------------------------ */
/**--------------Informes---------------------- */
export const getAniosValidos = `/api/venta/active-years/`;
export const listMarcas = `/api/marcas/list/`;
export const listResponsable = "/api/empleado/";
export const listVentasPorMarca = `/api/venta/totales/marca/`;
export const listVentasAnual = (dataIds) =>
  `/api/venta/informe/promedioVentas/?${dataIds}`;
export const listVentasMensual = "/api/venta/informe/diario/"; //params:{month:<nro mes>}
/**--------------Informes Parciales---------------------- */
export const getClientesDeudasSucursal =
  "/api/venta/cliente-sucursal-deuda/informe/";
export const getClientesPorComisionMecanico =
  "/api/cliente/comision-mecanico/informe/";
export const getProveedorUltimaActualizacion =
  "/api/proveedor/informe/lastupdate/";
export const getInformeTotalesMedioPago =
  "/api/venta/total-medio-by-fecha/informe/";
/**-------------------------------------------- */

/**--------Configuración/Ubicación---------------- */
export const crearUbicacion = `/api/ubicaciones/`;
export const getUbicaciones = `/api/ubicaciones/?padre=`;

/**--------Configuración General---------------- */
export const get_ConfigGeneral = `/api/sucursal/config-general/1/`;
export const put_ConfigGeneral = `/api/sucursal/put-config-general/1/`;
/**-------------------------------------------- */

/**----------------------Notas de credito---------------- */
export const calculoNotaCredito = (idVenta) =>
  `/api/notas-credito/previsualizar-detalle-nc/${idVenta}/`;
export const detalleNotaCredito = (idVenta) =>
  `/api/notas-credito/detalle-nc/${idVenta}`;
export const historialnotaCredito = "/api/notas-credito/informe-notas/";
export const getComprobanteNC = (id) =>
  `/api/notas-credito/recibo-nota-credito/${id}/`;
export const addObservacionNC = (id) =>
  `/api/notas-credito/add-observacion-nota-credito/${id}/`;
export const validateNotasCredito = `/api/notas-credito/validate-notas-credito/`;
export const generateNotaCredito = `/api/notas-credito/devolucion-to-nota-credito/`;
/**-------------------------------------------- */
/**----------------------Panel---------------- */
export const obtenerEmpleados = "/api/empleado/empleado-list/";
export const obtenerRoles = "/api/empleado/get-roles/";
export const crearEmpleado = "/api/empleado/register-employee/";
export const editarEmpleado = (idEmpleado) =>
  `/api/empleado/empleado-update/${idEmpleado}`;
export const cambiarContraseñaEmpleado = `/api/usuario/change-password/`;
export const eliminarEmpleado = (idEmpleado) =>
  `/api/empleado/empleado-baja/${idEmpleado}`;
export const getPermisos = "/api/empleado/get-permisos/";
/**-------------------------------------------- */

/**--------Elastic Search---------------- */
export const post_ES_conf = `/api/es/config/`;
export const put_ES_conf = (idConfig) => `/api/es/config/${idConfig}/`;
export const get_ES_conf = "/api/es/config/list/";
export const get_itme_ES_conf = (id) => `/api/es/config/get/${id}/`;
/**-------------------------------------------- */

/**--------Dashboard---------------- */
export const getMovimientoCajaDashboard = "movimientoDeCaja/totales-es/";
export const getVentasSemanales = "/api/venta/comparacion-ventas-semanales/";
export const getTopProductos = "/api/venta/top-totales-articulos/";
export const getProductosPorMediosPago = "/api/venta/total/medio-pago/";
export const getVentasHorariosPopulares =
  "/api/venta/horarios-populares-ventas/";
export const getDeudasTopProveedor = "/api/proveedor/deuda-proveedor/";
export const getTotalDeudaProveedor = "/api/proveedor/deuda-total-proveedor/";
export const getTopDeudasClientesPorAntiguedad = "/api/cliente/por-antiguedad/";
export const getTopDeudasClientesPorMontos = "/api/cliente/por-montos/";
export const getVencimientosCompras = "/api/compras/reporte-vencimientos/";
export const getVencimientosPresupuestos =
  "/api/presupuestos/reporte-vencimientos/";
export const getVencimientosCheques = "/api/pagos/reporte-vencimientos/";
export const getResponsables = "/api/venta/totales-responsable/";
export const getMontos = "/api/pagos/totales-deudas/";

/**--------Mercado Libre---------------- */
export const getPublicacionesMELI = (
  cliente_url,
  query,
  offset,
  linked,
  listing_type_id,
  status,
) =>
  `/api/mercado-libre/publicaciones/?cliente_url=${cliente_url}&query=${query}&offset=${offset}&linked=${linked}&listing_type_id=${listing_type_id}&status=${status}`;
export const getPublicacionMELI = (idPublicacion) =>
  `/api/mercado-libre/publicacion/tiene-productos-vinculados/?meli_publication_id=${idPublicacion}`;
export const vincularPublicacion = `/api/mercado-libre/publicacion/vincular-publicacion/`;
export const editarPublicacion = `/api/mercado-libre/publicacion/editar-publiacion/`;
export const desvincularPublicacion =
  "/api/mercado-libre/publicacion/desvincular-publiacion/";
export const getOrdenesMeli = "/api/mercado-libre/ordenes/";
export const getOrdenMeli =
  "/api/mercado-libre/ordenes/orden-tiene-vinculacion/";
export const facturarOrden = "/api/mercado-libre/ordenes/facturacion-meli/";

/**----------Cuenta Corriente Proveedores---------- */
export const postMotivoCCP = "/api/pagos/crear/motivo/";
export const deleteMotivoCCP = "/api/pagos/motivos/anular/";
export const getMotivosMovimientosCC = "/api/pagos/motivos/all/";
export const getTotalComprobantesAdeudados =
  "/api/compras/cuenta-corriente/cantidad-comprobantes/";

/**--------Integraciones---------------- */
export const getIntegrations = () => `/api/integraciones/`;

/**--------Woocommerce---------------- */
export const postIntegracionWoocommerce = (idIntegracion) =>
  `/api/integraciones/crear-integracion/?integracion_id=${idIntegracion}`;
export const getPublicacionesWoo = (offset, query) =>
  `/api/woocommerce/productos/?offset=${offset}&search=${query}`;
export const getPublicacionWoo = (idProducto) =>
  `/api/woocommerce/vinculacion/read/?woo_product_id=${idProducto}`;
export const vincularPublicacionWoo = "/api/woocommerce/vinculacion/create/";
export const editarPublicacionWoo = "/api/woocommerce/vinculacion/update/";
export const desvincularPublicacionWoo = "/api/woocommerce/desvinculacion/";

/**--------Compras Afip---------------- */
export const getPaymentsImportados = "/api/compras/afip/payments-read/";
export const getPaymentsManual = "/api/compras/afip/importacion-manual/";
export const lastUpdateCompras = "/api/compras/afip/ultima-actualizacion/";

/**-------------------------RETENCIONES----------------------------------------------- */
export const downloadTxtRetenciones = "/api/iva-informes/retenciones/";
/**------------------------------------------------------------------------------*/

/**-------------------------TRANSPORTE----------------------------------------------- */
export const listaTransportes = "/api/transporte/list/";
export const newTransporte = "/api/transporte/create/";
export const deleteTransporte = (idTransporte) =>
  `/api/transporte/delete/${idTransporte}/`;
export const updateTransporte = (idTransporte) =>
  `/api/transporte/update/${idTransporte}/`;
