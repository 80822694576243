import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";

const BodyTable = ({
  items,
  handleClickRow,
  colorRow,
  mostrarFilasPlazo,
  getStyleCursor,
  applyBorder,
}) => {
  return (
    <TableBody>
      {items.map((item, index) => {
        if (mostrarFilasPlazo(index)) {
          return (
            <TableRow
              hover
              onClick={(event) =>
                handleClickRow && handleClickRow(index, event)
              }
              key={index}
              style={{
                backgroundColor: colorRow.isCollapse(index)
                  ? colorRow.colorCollapse
                  : colorRow.colorNotCollapse,
                cursor: getStyleCursor(index),
              }}
            >
              {Object.entries(item).map(([key, value], i) => {
                return (
                  <TableCell
                    key={i}
                    align={"center"}
                    style={
                      applyBorder(index)
                        ? { borderBottom: "2px solid #a4a4a4" }
                        : {}
                    }
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        }
      })}
    </TableBody>
  );
};

export default BodyTable;
