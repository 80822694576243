export function getDataPlazos(plazos, montoTotal) {
  let newPlazos = plazos.sort(function (a, b) {
    return Number(b.porcentaje) - Number(a.porcentaje);
  });
  let newAmount = montoTotal / (1 + Number(newPlazos[0].porcentaje));

  return { newPlazos, newAmount };
}

export function getPriceNetoGravado(subtotal, descuento, recargo) {
  const newDescuento = descuento ? Number(descuento.toFixed(2)) : 0;
  const newRecargo = recargo ? Number(recargo.toFixed(2)) : 0;

  const neto = Number(subtotal.toFixed(2)) - newDescuento + newRecargo;

  return Number(neto.toFixed(2));
}

/**
 * @description Función que obtiene el cliente del comprobante. Se utiliza en ventas y en rotulo
 * Casos:
 * 1- Si tiene factura:
 *  a) Si es factura agrupada:
 *    a.1) Si es remito devuelve el cliente de la VENTA
 *    a.2) Si no es remito devuelve el cliente de la FACTURA
 *  b) Si es factura normal: (No importa si es remito o no ya que siempre debe devolver el cliente de la factura)
 *   b.1) Si tiene cliente tercero devuelve el cliente tercero de la factura
 *   b.2) Si no tiene cliente tercero y tiene cliente de sucursal devuelve el cliente real de la factura
 *   b.3) Si no tiene cliente tercero ni cliente de sucursal devuelve null
 * 2- Si no tiene factura devuelve null
 * @returns {Object} Retorna el cliente del comprobante
 */
export function getClienteComprobante(factura, venta, remito) {
  let cliente = null;
  if (factura) {
    if (factura.is_agrupada) {
      if (remito) {
        cliente = venta.cliente
          ? {
              razonSocial: venta.cliente.razonSocial
                ? venta.cliente.razonSocial
                : "Consumidor Final",
              domicilio: venta.cliente.domicilio
                ? venta.cliente.domicilio
                : null,
              telefono: venta.cliente.telefonoContacto
                ? venta.cliente.telefonoContacto
                : null,
              cuit: venta.cliente.CUIT ? venta.cliente.CUIT : null,
              dni: venta.cliente.numeroDocumento
                ? venta.cliente.numeroDocumento
                : null,
              estadoTributario: venta.cliente.estadoTributario
                ? venta.cliente.estadoTributario.descripcion
                : "Consumidor Final",
            }
          : null;
      } else {
        cliente = factura.idClienteDeSucursal
          ? {
              razonSocial: factura.idClienteDeSucursal.idCliente.razonSocial,
              domicilio: factura.idClienteDeSucursal.idCliente.domicilio,
              telefono: factura.idClienteDeSucursal.idCliente.telefonoContacto,
              cuit: factura.idClienteDeSucursal.idCliente.CUIT
                ? factura.idClienteDeSucursal.idCliente.CUIT
                : null,
              dni: factura.idClienteDeSucursal.idCliente.numeroDocumento
                ? factura.idClienteDeSucursal.idCliente.numeroDocumento
                : null,
              estadoTributario:
                factura.idClienteDeSucursal.idCliente.idEstadoTributario
                  .descripcion,
            }
          : null;
      }
    } else {
      if (factura.cliente_terceros) {
        cliente = {
          razonSocial: factura.cliente_terceros.razon_social,
          domicilio: factura.cliente_terceros.domicilio,
          telefono: factura.cliente_terceros.telefono_contacto,
          cuit: factura.cliente_terceros.cuit
            ? factura.cliente_terceros.cuit
            : null,
          dni: factura.cliente_terceros.numero_documento
            ? factura.cliente_terceros.numero_documento
            : null,
          estadoTributario:
            factura.cliente_terceros.estado_tributario.descripcion,
        };
      } else {
        cliente = factura.idClienteDeSucursal
          ? {
              razonSocial: factura.idClienteDeSucursal.idCliente.razonSocial,
              domicilio: factura.idClienteDeSucursal.idCliente.domicilio,
              telefono: factura.idClienteDeSucursal.idCliente.telefonoContacto,
              cuit: factura.idClienteDeSucursal.idCliente.CUIT
                ? factura.idClienteDeSucursal.idCliente.CUIT
                : null,
              dni: factura.idClienteDeSucursal.idCliente.numeroDocumento
                ? factura.idClienteDeSucursal.idCliente.numeroDocumento
                : null,
              estadoTributario:
                factura.idClienteDeSucursal.idCliente.idEstadoTributario
                  .descripcion,
            }
          : null;
      }
    }
  }
  return cliente;
}

/**
 * @description Función que retorna si debe mostrar CONTADO en el medio de pago de la venta en funcion del cliente. Se utiliza solo en ventas
 * Casos:
 * 1- Si tiene factura:
 *  a) Si es factura agrupada devuelve lo que tiene configurado el cliente de la factura agrupada
 *  b) Si es factura normal:
 *   b.1) Si tiene cliente tercero devuelve lo que tiene configurado el cliente de la venta
 *   b.2) Si no tiene cliente tercero y tiene cliente de sucursal devuelve lo que tiene configurado el cliente real de la factura
 *   b.3) Si no tiene cliente tercero ni cliente de sucursal devuelve false ya que es a un CF
 * 2- Si no tiene factura:
 *  a) Si la venta tiene un cliente, devuelve lo que tiene configurado ese cliente
 *  b) Si la venta no tiene cliente devuelve false ya que es a un CF
 * @returns {Object} Retorna si debe mostrar contado o no en el medio de pago
 */
export function mostrarMedioPagoContado(factura, venta) {
  let contado = false;
  if (factura) {
    if (factura.is_agrupada) {
      let cliente = factura.idClienteDeSucursal
        ? factura.idClienteDeSucursal.idCliente
        : null;
      contado = cliente ? cliente.forzar_medio_pago_contado_pdf : false;
    } else {
      if (factura.cliente_terceros) {
        contado = venta.cliente
          ? venta.cliente.forzar_medio_pago_contado_pdf
          : false;
      } else {
        contado = factura.idClienteDeSucursal
          ? factura.idClienteDeSucursal.idCliente.forzar_medio_pago_contado_pdf
          : false;
      }
    }
  } else {
    contado = venta.cliente
      ? venta.cliente.forzar_medio_pago_contado_pdf
      : false;
  }
  return contado;
}

export function textAbbreviation(text) {
  // Reemplaza 'percepciones' o 'percepción' e 'impuestos' o 'impuesto' por 'percep' e 'imp'
  const modifiedText = text
    .toLowerCase()
    .replace("percepciones", "percep")
    .replace("percepción", "percep")
    .replace("impuestos", "imp")
    .replace("impuesto", "imp");

  return modifiedText;
}
