import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { errorNotification } from "../../../../../../components/Notifications";
import Spinner from "../../../../../../components/Spinner";
import ImagenArticulo from "../../../../../../components/ait-reusable/ImagenArticulo/ImagenArticulo";
import LongTextWithHover from "../../../../../../components/ait-reusable/LongTextWithHover";
import ModalImagenesArticulo from "../../../../../../components/ait-reusable/ModalImagenesArticulo/ModalImagenesArticulo";
import NoResults from "../../../../../../components/ait-reusable/NoResults";
import SearchError from "../../../../../../components/ait-reusable/SearchError";
import TooltipMoreInfoMaterial from "../../../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import meliLogo from "../../../../../../images/meli.png";
import request from "../../../../../../requests/request";
import { actualizarPrecio } from "../../../../../../requests/urls";
import { esVisible } from "../../../../../Proveedores/utils";
import OpcionesCatalogo from "../../../OpcionesCatalogo";
import { StockDeseado, StockDisponible, StockSucursales } from "./utils";

function ItemArticulo({
  config,
  handleClickMenu,
  setFilterOriginal,
  history,
  handleOpenVincularStock,
  sucursales,
  setArticuloClicked,
  setDetallesProducto,
  handleOpenModalPedido,
  dataSelection,
  permisoParaEditarYVerTooltip,
  item,
  index,
  seteoFilter,
  handleOpenModalImagenArticulo,
  openAjustarStock,
  redirectToML,
}) {
  const { handleChangeSelection, isSelectedItem } = dataSelection;
  const [listaDePrecios, setListaDePrecios] = useState(null);
  const [precioDeVenta, setPrecioDeVenta] = useState(0);
  const [precioDeCosto, setPrecioDeCosto] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initListaDePrecios();
  }, []);

  useEffect(() => {
    initListaDePrecios();
  }, [item]);

  const initListaDePrecios = () => {
    if (item.lista_precios.length > 0) {
      let default_item = item.lista_precios.find(
        (i) => i.es_default && item.is_precio_lista == i.is_precio_lista,
      );
      if (default_item) {
        setListaDePrecios(default_item.id);
      }
    }
    setPrecioDeVenta(item.precios.venta);
    setPrecioDeCosto(item.precios.costo);
  };

  const changeListaPrecio = (event) => {
    const { value } = event.target;
    setLoading(true);
    setListaDePrecios(value);
    obtenerPrecioArticulo(value);
  };

  const obtenerPrecioArticulo = async (lista_de_precios) => {
    const response = await request({
      method: "GET",
      url: actualizarPrecio,
      params: {
        idArticuloProveedor: item.idRepuestoProveedor,
        listaPrecios: lista_de_precios,
        fraccionar_precio: Number(true),
      },
    });
    setPrecioDeVenta(response.data.data.venta);
    setPrecioDeCosto(response.data.data.costo);
    setLoading(false);
  };

  return (
    <TableRow
      style={{ cursor: "pointer" }}
      hover
      onClick={(_event) => setArticuloClicked(item)}
      key={index}
    >
      {loading ? (
        <TableCell colSpan={100} align="center">
          <CircularProgress
            size={20}
            style={{ marginRight: "10px", flex: 1, alignSelf: "center" }}
          />
        </TableCell>
      ) : (
        <>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isSelectedItem(item)}
              onChange={() => {
                handleChangeSelection(item);
              }}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>

          {esVisible(config, "Imagen") ? (
            <TableCell padding="checkbox">
              <ImagenArticulo articulo={item} />
            </TableCell>
          ) : null}

          {esVisible(config, "Artículo") && (
            <TableCell
              align={"center"}
              onDoubleClick={() => handleOpenModalImagenArticulo(item)}
            >
              {item.repuesto_sucursal
                ? openAjustarStock(
                    handleClickMenu,
                    item,
                    item.codProveedor ? item.codProveedor.toUpperCase() : "---",
                  )
                : item.codProveedor
                  ? item.codProveedor.toUpperCase()
                  : "---"}
            </TableCell>
          )}

          {esVisible(config, "Original") && (
            <TableCell align="center">
              {seteoFilter(
                item.codOriginal ? item.codOriginal.toUpperCase() : "---",
                setFilterOriginal,
              )}
            </TableCell>
          )}

          {esVisible(config, "Auxiliar") && (
            <TableCell align="center">
              {item.codAuxiliar
                ? seteoFilter(item.codAuxiliar.toUpperCase(), setFilterOriginal)
                : "---"}
            </TableCell>
          )}

          <TableCell align="center">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Tooltip */}
              {permisoParaEditarYVerTooltip &&
                item.tipo_descuento &&
                item.tipo_descuento !==
                  "No posee ningun tipo de descuento ni recargo." && (
                  <div style={{ marginRight: "4px" }}>
                    <TooltipMoreInfoMaterial
                      titleTooltip={item.tipo_descuento}
                    />
                  </div>
                )}

              {/* Imagen MELI */}
              {item.meli_publicacion && (
                <div style={{ marginRight: "2px" }}>
                  <img
                    width={25}
                    src={meliLogo}
                    alt="meliLogo"
                    title="Vinculado con mercadolibre"
                  />
                </div>
              )}

              {/* Descripción */}
              {redirectToML(item.descripcionProveedor || "---")}
            </div>
          </TableCell>

          {esVisible(config, "Marca") && (
            <TableCell align="center">
              {item.marca_nombre ? item.marca_nombre : "---"}
            </TableCell>
          )}

          {esVisible(config, "Rubro") && (
            <TableCell align="center">
              {(item.rubro && item.rubro.nombre) || "---"}
            </TableCell>
          )}

          {esVisible(config, "Subrubro") && (
            <TableCell align="center">
              {(item.sub_rubro && item.sub_rubro.nombre) || "---"}
            </TableCell>
          )}

          {esVisible(config, "Observaciones") && (
            <TableCell align="center">
              {item.observaciones.length
                ? item.observaciones[0].substr(0, 45).trim() +
                  (item.observaciones[0].length > 45 ? "..." : "")
                : "---"}
            </TableCell>
          )}

          {esVisible(config, "L. de Precios") && (
            <TableCell align="center">
              {item.is_precio_lista ? (
                <TextField
                  style={{ backgroundColor: "white" }}
                  select
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={listaDePrecios}
                  onChange={changeListaPrecio}
                  variant="outlined"
                  name="lista_precios"
                  disabled={item.lista_precios.length === 0}
                >
                  {item.lista_precios &&
                    item.lista_precios.map((lp) => {
                      if (lp.is_precio_lista === item.is_precio_lista) {
                        return (
                          <MenuItem selected value={lp.id}>
                            {lp.nombre}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                </TextField>
              ) : (
                "---"
              )}
            </TableCell>
          )}

          {esVisible(config, "P. Costo") && (
            <TableCell align="center">
              <Box>
                <Typography>
                  {parseFloat(precioDeCosto).toLocaleString("es-AR")}
                </Typography>
                <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                  Costo
                </Typography>
              </Box>
            </TableCell>
          )}

          {esVisible(config, "P. Lista") && (
            <TableCell align="center">
              <Box>
                <Typography>
                  {parseFloat(item.precios.lista).toLocaleString("es-AR")}
                </Typography>
                <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                  Lista
                </Typography>
              </Box>
            </TableCell>
          )}

          {esVisible(config, "P. Venta") && (
            <TableCell align="center">
              <Box>
                <Typography>
                  {parseFloat(precioDeVenta).toLocaleString("es-AR")}
                </Typography>
                <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                  Venta
                </Typography>
              </Box>
            </TableCell>
          )}

          {esVisible(config, "Stock") && (
            <TableCell align="center">
              <StockDisponible articulo={item.repuesto_sucursal} />
            </TableCell>
          )}

          {esVisible(config, "Stock deseado") && (
            <TableCell align="center">
              <StockDeseado articulo={item.repuesto_sucursal} />
            </TableCell>
          )}

          {esVisible(config, "Stock") && (
            <StockSucursales sucursales={sucursales} item={item} />
          )}

          {esVisible(config, "Proveedor") && (
            <TableCell align="center">
              {item.proveedor.toUpperCase() || "---"}
            </TableCell>
          )}

          {esVisible(config, "Ubicación") && (
            <TableCell align="center">
              {!item.repuesto_sucursal || !item.repuesto_sucursal.deposito ? (
                "---"
              ) : (
                <LongTextWithHover
                  text={`${
                    item.repuesto_sucursal.estante.descripcion +
                    " - " +
                    item.repuesto_sucursal.fila.descripcion +
                    " - " +
                    item.repuesto_sucursal.zona.descripcion +
                    " - " +
                    item.repuesto_sucursal.deposito.descripcion
                  }`}
                />
              )}
            </TableCell>
          )}

          {esVisible(config, "Fecha ultima actualizacion") && (
            <TableCell align="center">
              {moment(item.fecha_ultima_actualizacion).format(
                "DD-MM-YYYY HH:mm",
              ) || "---"}
            </TableCell>
          )}

          <TableCell align="center">
            <OpcionesCatalogo
              articulo={item}
              listaPrecio={listaDePrecios}
              permisoParaEditarYVerTooltip={permisoParaEditarYVerTooltip}
              handleClickMenu={handleClickMenu}
              setDetallesProducto={setDetallesProducto}
              handleOpenModalPedido={handleOpenModalPedido}
              history={history}
              handleOpenVincularStock={handleOpenVincularStock}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

export default function TableArticulos(props) {
  const {
    columns,
    loading,
    items,
    dataSelection,
    handleShowSelectedFilter,
    handleChangeArt,
    handleTieneFiltroSeleccionado,
    error,
  } = props;

  const [articuloSelected, setArticuloSelected] = useState(null);
  const [modalImagenesArticulo, setModalImagenesArticulo] = useState(false);
  const { all, setAll, handleSelectAll } = dataSelection;

  const openAjustarStock = (handleClickMenu, articulo, code) => {
    return (
      <p onDoubleClick={() => handleClickMenu(articulo, "Stock")}>{code}</p>
    );
  };

  const seteoFilter = (code, setFilterOriginal) => {
    return <p onDoubleClick={() => setFilterOriginal(code)}>{code}</p>;
  };

  const redirectToML = (descripcion) => {
    return (
      <p
        onDoubleClick={() =>
          window.open(
            `https://listado.mercadolibre.com.ar/${descripcion.replace(
              " ",
              "-",
            )}`,
          )
        }
      >
        {descripcion}
      </p>
    );
  };

  const handleOpenModalImagenArticulo = (articulo) => {
    if (!articulo.identificador_proveedor) {
      return errorNotification(
        "El proveedor de este articulo no se encuentra validado",
      );
    }
    setArticuloSelected(articulo);
    setModalImagenesArticulo(true);
  };

  const handleCloseModalImagenArticulo = () => {
    setModalImagenesArticulo(false);
    setArticuloSelected(null);
  };

  return (
    <>
      <TableContainer style={{ display: "inline-table" }}>
        <Table
          name="report-table"
          stickyHeader
          size="small"
          aria-label="a dense table"
        />
        <TableHead style={{ maxHeight: 15 }}>
          <TableRow>
            <TableCell
              padding="checkbox"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              <Checkbox
                color="primary"
                style={{
                  color: "#fff",
                }}
                checked={all}
                onChange={() => {
                  handleSelectAll(!all);
                  setAll(!all);
                }}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </TableCell>

            {columns.map((column, index) => {
              return (
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                  key={index}
                >
                  {column}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        {!loading ? (
          <TableBody>
            {items.map((item, index) => (
              <ItemArticulo
                {...props}
                item={item}
                index={index}
                key={index}
                seteoFilter={seteoFilter}
                handleOpenModalImagenArticulo={handleOpenModalImagenArticulo}
                openAjustarStock={openAjustarStock}
                redirectToML={redirectToML}
              />
            ))}
          </TableBody>
        ) : null}

        {modalImagenesArticulo ? (
          <ModalImagenesArticulo
            articulo={articuloSelected}
            open={modalImagenesArticulo}
            close={handleCloseModalImagenArticulo}
          />
        ) : null}
      </TableContainer>

      {loading ? (
        <div className={"mt-5 mb-2"}>
          <Spinner color="#66b3ff" type="spin" />
        </div>
      ) : error ? (
        <SearchError />
      ) : items.length === 0 ? (
        <NoResults
          noResult={
            <Box>
              <Typography
                style={{
                  fontSize: 25,
                  fontWeight: 500,
                }}
              >
                No se han encontrado resultados
              </Typography>
              {handleTieneFiltroSeleccionado() ? (
                <Box>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      marginBottom: 10,
                    }}
                  >
                    Estas buscando articulos con el filtro{" "}
                    {handleShowSelectedFilter()}
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleChangeArt(1)}
                  >
                    Cambiar filtro a "Todos"
                  </Button>
                </Box>
              ) : null}
            </Box>
          }
        />
      ) : null}
    </>
  );
}
