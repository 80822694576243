import { Grid, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfigGeneral } from "../../../../../Redux/Actions/actionConfigGeneral";

export default function ConfigSideBar() {
  const dispatch = useDispatch();
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const [state, setState] = useState(
    configGeneral.sidebar_menu_config || false,
  );

  const handleChageConfigSideBar = () => {
    const newState = !state;
    setState(newState);
    const updatedConfig = {
      ...configGeneral,
      sidebar_menu_config: newState,
    };
    dispatch(updateConfigGeneral(updatedConfig));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info">
          Configura cómo se comportará el menú lateral (barra de navegación) en
          el sistema. Elegí si preferís que se mantenga fijo o que se repliegue
          automáticamente para aprovechar más espacio en pantalla.
        </Alert>
      </Grid>
      <Grid container item spacing={2} xs={12} alignItems="flex-start">
        <Grid
          item
          xs={4}
          sm={4}
          style={{ textAlign: "right", alignSelf: "center" }}
        >
          Menú Fijo
        </Grid>
        <Grid
          item
          xs={4}
          sm={2}
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <Switch
            checked={state}
            color="primary"
            onChange={handleChageConfigSideBar}
            name="configSideBar"
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={6}
          style={{ textAlign: "left", alignSelf: "center" }}
        >
          Menú Automático
        </Grid>
      </Grid>
    </Grid>
  );
}
