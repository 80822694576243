import {
  SET_SEARCH,
  SET_SEARCH_METHOD,
  SET_SELECTED_CLIENT_DATA,
  RESET_SELECTED_CLIENT_DATA,
} from "../../Actions/Clientes/clientSearchActions";

const initialState = {
  search: "",
  searchMethod: null,
  selectedClientData: null,
};

const clientSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
        searchMethod: action.payload ? "search" : null,
      };
    case SET_SEARCH_METHOD:
      return {
        ...state,
        searchMethod: action.payload,
      };
    case SET_SELECTED_CLIENT_DATA:
      return {
        ...state,
        selectedClientData: action.payload,
      };
    case RESET_SELECTED_CLIENT_DATA:
      return {
        ...state,
        selectedClientData: null,
      };
    default:
      return state;
  }
};

export default clientSearchReducer;
