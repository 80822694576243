import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "shards-react";
import request from "../../../requests/request";
import { postPresupuesto } from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../../components/Notifications";
import { Button } from "@material-ui/core";
import {
  clearPresupuesto,
  updateValidacionImpuestosPresupuesto,
} from "../../../Redux/Actions/presupuestoActions";
import ModalPresupuesto from "../ModalPresupuesto";
import { useHistory, useParams } from "react-router-dom";
import {
  calcularComision,
  sendMailPresupuestoOnPost,
  validateImpuestos,
} from "./utils";
import { parseCurrency } from "../../../utils/parsers";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { superaMontoAFIP } from "../../Venta/utils";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import useAmountCalculatorVenta from "../../Venta/Importes/useAmountCalculatorVenta";
import ModalImpuestosPresupuesto from "../../../components/ait-reusable/ModalImpuestosPresupuesto";

export default function Importes({
  generatePdf,
  errorCheque,
  setErrorCheque,
  errorTransferencia,
  setErrorTransferencia,
  setOpenModalWhatsapp,
  urlPdf,
  setUrlPdf,
  generatePdfConPrevisualizacion,
  montoMaximo,
  isVenta,
  configCCC,
  confirmacionPrecios,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);

  const { presupuesto, configGeneral } = useSelector((state) => state);
  const {
    detalles,
    montos,
    cliente,
    tipoComprobante,
    medioPago,
    impuestosPresupuestoCreado,
    impuestosValidados,
  } = presupuesto;

  const history = useHistory();
  const dispatch = useDispatch();
  const { idPresupuesto } = useParams();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const { useAmount } = useAmountCalculatorVenta(presupuesto, false, null);
  const {
    montosCalculados,
    aplicaIva,
    totalesPorImpuesto,
    mediosPagoProcesadosConMontosEImpuestos,
  } = useAmount;
  const [openModalImpuestos, setOpenModalImpuestos] = useState(false);
  const [impuestosCalculados, setImpuestosCalculados] = useState(null);

  useEffect(() => {
    if (
      idPresupuesto &&
      !impuestosValidados &&
      montosCalculados.montoTotalConIvaEImpuestos > 0 &&
      confirmacionPrecios
    ) {
      setImpuestosCalculados(totalesPorImpuesto);
    }
  }, [totalesPorImpuesto, confirmacionPrecios]);

  useEffect(() => {
    if (impuestosCalculados) {
      let error = validateImpuestos(
        impuestosPresupuestoCreado,
        impuestosCalculados,
      );
      setOpenModalImpuestos(error);
    }
  }, [impuestosCalculados]);

  const handlePostPresupuesto = async (
    action,
    newData,
    formatVenta,
    isFCEMA,
    formato,
    correoCliente,
  ) => {
    setOpenBackdrop(true);

    try {
      const response = await request({
        method: "POST",
        url: postPresupuesto,
        data: newData,
      });
      handleResponsePostPresupuesto(
        response,
        formato,
        action,
        formatVenta,
        correoCliente,
      );
    } catch (error) {
      console.error(error);
      setOpenBackdrop(false);
      errorNotification("Error al crear el presupuesto");
    }
  };

  const handleResponsePostPresupuesto = (
    response,
    formato,
    action,
    formatVenta,
    correoCliente,
  ) => {
    // Si el formato es diferente de correo se cierra el backdrop (loading)
    if (formato !== "Email") {
      setOpenBackdrop(false);
    }
    successNotification("Presupuesto creado con éxito");
    if (configGeneral.configGeneral.previsualizacion) {
      action && generatePdf(action, response);
    } else {
      action && generatePdfConPrevisualizacion(action, response);
    }
    dispatch(clearPresupuesto(configGeneral.configGeneral, true));
    localStorage.setItem("formatoComprobante", formatVenta);
    idPresupuesto && history.replace("/presupuesto");

    // Si el formato es email se cierra el backdrop y el modal una vez que se haya enviado el mail
    if (formato === "Email") {
      sendMailPresupuestoOnPost(
        response.data.data.id_presupuesto,
        correoCliente,
        setOpenBackdrop,
        () => {
          setOpenModal(false);
        },
      );
    } else {
      setOpenModal(false);
    }
  };

  const handleValidacionPresupuesto = () => {
    let errors = false;
    let newError = errorCheque;

    if (detalles.length === 0) {
      errorNotification("El carrito no puede estar vacío");
      errors = true;
    } else {
      let zeroPrice = false;
      detalles.forEach((item) => {
        if (item.precio === 0) {
          zeroPrice = true;
        } else if (
          item.lista_precios &&
          !item.lista_precios.some((i) => i.id == item.idListaPrecio)
        ) {
          errorNotification("Por favor, seleccione una lista de precios");
          errors = true;
        }
      });
      if (zeroPrice) {
        errorNotification("Artículos con precio igual a cero en el carrito");
        errors = true;
      } else {
        if (!tipoComprobante || !tipoComprobante.idTipoComprobante) {
          errorNotification("Seleccione el tipo de comprobante para la venta");
          errors = true;
        } else {
          if (
            cliente === null &&
            tipoComprobante.idTipoFactura !== null &&
            montos.montoTotal >= montoMaximo &&
            isVenta
          ) {
            warningNotification(
              `Seleccione un cliente para montos mayores a $${montoMaximo}`,
            );

            errors = true;
          }
        }
      }
    }
    !errors && setOpenModal(true);
  };

  const handleOpenModalPdf = async (action, newData, formatVenta) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "POST",
        url: postPresupuesto,
        data: newData,
      });
      setOpenBackdrop(false);
      successNotification("Presupuesto creado con éxito");
      useQueryEmpleado.setQuery("");
      localStorage.setItem("formatoComprobante", formatVenta);
      setDataPdfFetched(response.data.data);
      dispatch(clearPresupuesto(configGeneral.configGeneral, true));
      idPresupuesto && history.replace("/presupuesto");
    } catch (error) {
      console.error(error);
      setOpenBackdrop(false);
      errorNotification("Error al crear el presupuesto");
    }
    setOpenModal(false);
  };

  useEffect(() => {
    if (urlPdf) {
      setOpenModalWhatsapp(true);
    } else if (dataPdfFetched) {
      getPdfBlob(
        "presupuestoA4",
        dataPdfFetched,
        configGeneral.configGeneral,
      ).then((res) => {
        if (res) {
          setUrlPdf(res.secure_url);
        }
      });
      setDataPdfFetched(null);
    }
  }, [urlPdf, dataPdfFetched]);

  return (
    <React.Fragment>
      <Card className="card-gray mt-3">
        <CardTitle
          className="text-center divder-card-gray"
          style={{ fontSize: "19px" }}
        >
          Importes
        </CardTitle>
        <CardBody className="p-0 pb-0">
          <Container fluid>
            <Row>
              <Col xs="12" className="d-flex justify-content-between">
                <span className="fontBold">IMPORTE:</span>{" "}
                <span className="fontBold">
                  ${montosCalculados.subtotal.toLocaleString("es-AR")}
                </span>
              </Col>

              {montosCalculados.montoDescuento !== 0 && (
                <Col xs="12" className=" d-flex justify-content-between">
                  <span>DESCUENTO:</span>{" "}
                  <span>
                    - ${montosCalculados.montoDescuento.toLocaleString("es-AR")}
                  </span>
                </Col>
              )}

              {montosCalculados.montoInteres !== 0 && (
                <Col xs="12" className=" d-flex justify-content-between">
                  <span>INTERES:</span>{" "}
                  <span>
                    + ${montosCalculados.montoInteres.toLocaleString("es-AR")}
                  </span>
                </Col>
              )}

              {aplicaIva && (
                <>
                  <Col xs="12" className=" d-flex justify-content-between">
                    <span className="fontBold">SUBTOTAL:</span>
                    <span className="fontBold">
                      $
                      {montosCalculados.montoConDescuentoEInteresSinIvaEImpuestos.toLocaleString(
                        "es-AR",
                      )}
                    </span>
                  </Col>

                  <Col xs="12" className=" d-flex justify-content-between">
                    <span>IVA - 21%:</span>
                    <span>
                      {montosCalculados.montoIVA.toLocaleString("es-AR")}
                    </span>
                  </Col>
                </>
              )}

              {totalesPorImpuesto.map((imp) => (
                <Col
                  key={imp.id}
                  xs="12"
                  className=" d-flex justify-content-between"
                >
                  <span>{`${imp.description} - ${parseFloat(
                    (imp.alicuota * 100).toFixed(2),
                  )}%:`}</span>
                  <span>{imp.monto.toLocaleString("es-AR")}</span>
                </Col>
              ))}

              <Col xs="12" className=" d-flex justify-content-between mt-2">
                <h5 className="fontBold">TOTAL:</h5>{" "}
                <h5 className="fontBold">
                  $
                  {montosCalculados.montoTotalConIvaEImpuestos.toLocaleString(
                    "es-AR",
                  )}
                </h5>
              </Col>
              {cliente && cliente.es_comision && (
                <Col xs={12} className=" d-flex justify-content-between">
                  <span className="font-weight-bold">Comisión mecanico:</span>
                  <span>
                    {parseCurrency(
                      calcularComision(
                        montosCalculados.montoTotalConIvaEImpuestos,
                        presupuesto.cliente,
                      ),
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Container fluid>
        <Row className="mt-3">
          <Col xs={12} lg={6} className="justify-content-center d-flex">
            <Button
              variant="outlined"
              fullWidth
              onClick={() =>
                dispatch(clearPresupuesto(configGeneral.configGeneral, true))
              }
              style={{
                borderColor: "#c4183c",
                color: "#c4183c",
              }}
            >
              LIMPIAR
            </Button>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="justify-content-center d-flex mt-3 mt-lg-0"
          >
            {superaMontoAFIP(cliente, tipoComprobante, montos, montoMaximo) &&
            isVenta ? (
              <TooltipWithoutIcon
                placement={"left"}
                body={
                  <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                    {`Para guardar el presupuesto debes seleccionar un cliente ya que el monto supera los $${Number(
                      montoMaximo,
                    ).toLocaleString("es-AR")}`}
                  </h6>
                }
              >
                <span>
                  <Button
                    style={{
                      borderColor: "#17c671",
                      color: "#17c671",
                    }}
                    fullWidth
                    variant="outlined"
                    type="submit"
                    onClick={() => handleValidacionPresupuesto()}
                    disabled={true}
                  >
                    GUARDAR
                  </Button>
                </span>
              </TooltipWithoutIcon>
            ) : (
              <Button
                style={{
                  borderColor: "#17c671",
                  color: "#17c671",
                }}
                fullWidth
                variant="outlined"
                type="submit"
                onClick={() => handleValidacionPresupuesto()}
              >
                GUARDAR
              </Button>
            )}
          </Col>
        </Row>
      </Container>
      {openModal && (
        <ModalPresupuesto
          open={openModal}
          presupuesto={presupuesto}
          toggle={() => {
            useQueryEmpleado.setQuery("");
            setOpenModal(false);
          }}
          submit={handlePostPresupuesto}
          openBackdrop={openBackdrop}
          handleOpenModalPdf={handleOpenModalPdf}
          dataEmpleado={{ useEmpleado, useQueryEmpleado }}
          setLoading={setLoading}
          configCCC={configCCC}
          montosCalculados={montosCalculados}
          totalesPorImpuesto={totalesPorImpuesto}
          mediosPagoProcesadosConMontosEImpuestos={
            mediosPagoProcesadosConMontosEImpuestos
          }
        />
      )}

      {openModalImpuestos && (
        <ModalImpuestosPresupuesto
          open={openModalImpuestos}
          handleClose={() => {
            setOpenModalImpuestos(false);
            setImpuestosCalculados(null);
            dispatch(updateValidacionImpuestosPresupuesto(true));
          }}
          data={{ impuestosPresupuestoCreado, impuestosCalculados }}
          isPresupuesto={true}
        />
      )}
    </React.Fragment>
  );
}
