import axios from "axios";

const validarToken = async (cb) => {
  const token = localStorage.getItem("token");

  try {
    const res = await axios.get(`/api/usuario/validateToken/${token}`);

    if (!res.data) {
      if (typeof cb === "function") {
        cb(true);
      }

      localStorage.clear();

      return;
    }

    if (typeof cb === "function") {
      cb(false);
    }
  } catch (error) {
    if (typeof cb === "function") {
      cb(false);
    }

    console.error(error);
  }
};

export default validarToken;
