import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { formatoMoneda } from "../../../../../utils/parsers";
import { LineA4Table } from "../../Components/raya";

export default function BodyTableConciliacionVentas({ venta }) {
  const tipoComprobante = (venta) => {
    if (venta.tipo_comprobante === "Factura") {
      return "FA";
    } else if (venta.tipo_comprobante === "Comprobante interno") {
      return "CI";
    } else {
      return venta.tipo_comprobante;
    }
  };

  return (
    <View style={{ paddingTop: 10 }}>
      <View style={styles.containerRowTable}>
        <View style={(styles.containerColumn, { width: 102 })}>
          <Text
            style={styles.textLeftTable}
          >{`${tipoComprobante(venta)} N°${venta.nro_factura}`}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 102 })}>
          <Text style={styles.textCenterTable}>{venta.pago_cliente_id}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 102 })}>
          <Text style={styles.textCenterTable}>
            {venta.venta_observaciones}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 102 })}>
          <Text style={styles.textCenterTable}>{venta.venta_monto_total}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 102 })}>
          <Text style={styles.textRightTable}>
            {formatoMoneda({ value: venta.monto_saldado })}
          </Text>
        </View>
      </View>
      <LineA4Table />
    </View>
  );
}
