import {
  SET_OPEN_FILTERS,
  SET_FILTER,
  RESET_FILTERS,
} from "../../Actions/Clientes/clientFilterActions";

const initialFilters = {
  orderBy: "sin_orden", // ordenFiltro
  ctaCte: "todos",
  tipoCliente: "todos",
  tipoDeCliente: "todos",
  activos: 1,
  transporte: null,
};

const initialState = {
  openFilters: false,
  filters: initialFilters,
};

const clientFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_FILTERS:
      return {
        ...state,
        openFilters: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters,
      };
    default:
      return state;
  }
};

export default clientFilterReducer;
