import moment from "moment";
import * as Yup from "yup";

const date = moment(new Date()).format("YYYY-MM-DD");

export const estadoInicialForm = ({ medioPagoInicial, modalidadCheques }) => {
  return {
    medios: [
      {
        medioPago: medioPagoInicial,
        monto: 0,
        nroLote: "",
        nroAut: "",
        medioSelected: medioPagoInicial.idMedioPago,
        tarjetaSelected: null,
        coeficienteSelected: null,
        referenciaTarjeta: null,
        porcentajeSelected: 0,
        nroCheque: null,
        tipoCheque: 1,
        modalidadCheque: 1,
        referenciaCheque: null,
        fechaEmisionCheque: date,
        fechaVencimientoCheque: date,
        propioCheque: false,
        bancoTransf: null,
        fecha_transferencia: date,
        referenciaTransf: null,
      },
    ],
    motivo: null,
    concepto: "",
  };
};

export const validationYup = ({ montoBilletera }) =>
  Yup.object().shape({
    motivo: Yup.number().required("Campo obligatorio"),
    concepto: Yup.string().max(
      150,
      "El campo no puede tener más de 150 caracteres",
    ),
    medios: Yup.array().of(
      Yup.object().shape({
        monto: Yup.number()
          .transform((value, originalValue) => {
            if (typeof originalValue === "string") {
              // Reemplazar '.' por '' y ',' por '.'
              const parsedValue = originalValue
                .replace(/\./g, "")
                .replace(",", ".");
              return parseFloat(parsedValue); // Convertir a número flotante
            }
            return value; // Si ya es un número, no se transforma
          })
          .typeError("Debe ser un número válido")
          .positive("El número debe ser mayor que cero")
          .required("Este campo es obligatorio")
          .when("medioPago.nombre", {
            is: "Saldo en billetera",
            then: Yup.number()
              .transform((value, originalValue) => {
                if (typeof originalValue === "string") {
                  const parsedValue = originalValue
                    .replace(/\./g, "")
                    .replace(",", ".");
                  return parseFloat(parsedValue);
                }
                return value;
              })
              .max(
                montoBilletera < 0 ? 0 : montoBilletera,
                `El monto no puede ser mayor a ${montoBilletera < 0 ? 0 : montoBilletera}`,
              )
              .positive("El monto debe ser mayor que cero")
              .required("Este campo es obligatorio"),
          }),
        medioSelected: Yup.string().required("Seleccione un medio de pago"),
        tarjetaSelected: Yup.string().when("medioPago.tipo", {
          is: "Tarjeta",
          then: Yup.string().required("Seleccione una tarjeta"),
          otherwise: Yup.string().nullable(),
        }),
        nroCheque: Yup.number().when("medioPago.tipo", {
          is: "Cheque",
          then: Yup.number()
            .required("Ingrese el número de cheque")
            .positive("No se admiten valores negativos")
            .max(99999999, "Máximo 8 digitos")
            .typeError("Valor ingresado no válido"),
          otherwise: Yup.number().nullable(),
        }),
        tipoCheque: Yup.number().when("medioPago.tipo", {
          is: "Cheque",
          then: Yup.number()
            .required("Seleccione el tipo de cheque")
            .nullable(),
          otherwise: Yup.number().nullable(),
        }),
        fechaEmisionCheque: Yup.date()
          .when("medioPago.tipo", {
            is: "Cheque",
            then: Yup.date()
              .required("Ingrese fecha de emision")
              .max(moment(new Date()).format("YYYY-MM-DD"), "Fecha inválida"),
            otherwise: Yup.date().nullable(),
          })
          .typeError("Formato inválido"),
        fechaVencimientoCheque: Yup.date()
          .when("medioPago.tipo", {
            is: "Cheque",
            then: Yup.date().required("Ingrese fecha de vencimiento"),
            otherwise: Yup.date().nullable(),
          })
          .typeError("Formato inválido"),
      }),
    ),
  });

const parseMedioPagoOrdenCargaSalda = (formikMedioPago) => ({
  monto: Number(
    String(formikMedioPago.monto).replace(/\./g, "").replace(",", "."),
  ).toFixed(2),
  medio_pago: formikMedioPago.medioPago.idMedioPago,
  coeficiente: {
    id_coeficiente_interes: formikMedioPago.coeficienteSelected,
    nroLote: formikMedioPago.nroLote,
    nroAut: formikMedioPago.nroAut,
    referencia: formikMedioPago.referenciaTarjeta,
  },
  transferencia: {
    banco: formikMedioPago.bancoTransf,
    referencia: formikMedioPago.referenciaTransf,
    fecha_transferencia: formikMedioPago.fecha_transferencia,
  },
  cheque: {
    tipo: formikMedioPago.tipoCheque,
    emision: formikMedioPago.fechaEmisionCheque,
    vencimiento: formikMedioPago.fechaVencimientoCheque,
    cobro: moment(new Date()).format("YYYY-MM-DD"),
    monto: Number(
      String(formikMedioPago.monto).replace(/\./g, "").replace(",", "."),
    ).toFixed(2),
    numero_cheque: formikMedioPago.nroCheque,
    propio: formikMedioPago.propioCheque,
    referencia: formikMedioPago.referenciaCheque,
    modalidad: formikMedioPago.modalidadCheque,
  },
});

const parseOrdenCargaDeSaldo = (informacion_medios_pago) => {
  return {
    motivo: informacion_medios_pago.motivo,
    concepto: informacion_medios_pago.concepto,
    medios: informacion_medios_pago.medios.map(parseMedioPagoOrdenCargaSalda),
  };
};

export const parseConciliarVentas = (data) => {
  return {
    cliente_sucursal_id: data.cliente_sucursal_id,
    descuento_porcentaje_conciliacion: data.descuentoPorcentaje,
    monto_conciliacion: data.informacion_medios_pago.medios
      .reduce(
        (acc, medio) =>
          acc +
          Number(String(medio.monto).replace(/\./g, "").replace(",", ".")),
        0,
      )
      .toFixed(2),
    ventas: data.ventas,
    responsable_id: data.responsable,
    is_pago_con_descuento: data.is_pago_con_descuento,
    observacion: data.observacion,
    informacion_medios_pago: parseOrdenCargaDeSaldo(
      data.informacion_medios_pago,
    ),
  };
};
