import React from "react";
import { useDispatch } from "react-redux";
import { Navbar, NavbarBrand } from "shards-react";
import { closeSidebar } from "../../../Redux/Actions/sidebarActions";

export default function SidebarMainNavbar() {
  const dispatch = useDispatch();

  const handleCloseSidebar = () => {
    dispatch(closeSidebar());
  };

  return (
    <div className="main-navbar">
      <Navbar
        className={
          "align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
        }
        type="light"
      >
        <NavbarBrand
          className="w-100 mr-0"
          href="#"
          style={{ lineHeight: "35px" }}
        >
          <div
            className="m-auto"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              id="main-logo"
              className="d-inline-block align-top"
              style={{ maxWidth: "52%" }}
              src={require("../../../images/logo.svg")}
              alt="Shards Dashboard"
            />
          </div>
        </NavbarBrand>

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
          onClick={handleCloseSidebar}
        >
          <i className="material-icons">&#xE5C4;</i>
        </a>
      </Navbar>
    </div>
  );
}
