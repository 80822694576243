import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import putConfiguracionGeneral from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: 15,
    marginRight: 15,
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

export default function ColsTablaArticulos({ config, getConfig }) {
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setState(config.columnas_articulos);
  }, []);

  const handleChange = async (event) => {
    setError(null);
    const copyState = state.slice();
    const index = state.findIndex((x) => event.target.name === x.nombre);
    if (index !== -1)
      copyState[index] = {
        ...copyState[index],
        es_visible: !copyState[index].es_visible,
      };
    setState(copyState);
  };

  const handledSaveChanges = async () => {
    let validation = validateSelectionCodigos();

    if (validation) {
      putConfiguracionGeneral({
        ...config,
        columnas_articulos: state,
      }).then((res) => getConfig(res));
    } else {
      setError("Debe elegir por lo menos Artículo u Original");
    }
  };

  const validateSelectionCodigos = () => {
    let codArticulo = state.filter((x) => x.nombre === "Artículo");
    let codOriginal = state.filter((x) => x.nombre === "Original");

    if (codArticulo[0].es_visible || codOriginal[0].es_visible) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <Typography variant="body2">
          Seleccione las columnas que desea ver en la tabla de artículos.
        </Typography>
        <br />
        {state.map(
          (x) =>
            x.nombre !== "Descripción" && (
              <FormControlLabel
                key={x.nombre}
                style={{ marginBottom: 0 }}
                control={
                  <Checkbox
                    name={x.nombre}
                    color="primary"
                    // disabled={
                    //   x.nombre === "P. Lista" && !config.precio_mayorista
                    // }
                    checked={x.es_visible}
                    onChange={handleChange}
                  />
                }
                label={x.nombre}
              />
            ),
        )}
        {error && (
          <>
            <Typography variant="body2" style={{ color: "#f44336" }}>
              {error}
            </Typography>
            <br />
          </>
        )}

        <Button
          style={{ outline: "none" }}
          variant="contained"
          color="primary"
          onClick={handledSaveChanges}
          disabled={error ? true : false}
        >
          Guardar cambios
        </Button>
      </FormControl>
    </div>
  );
}
