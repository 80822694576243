import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";

export default function FilterCtaCte({ filter, setFilter }) {
  const dispatch = useDispatch();

  const handleFilter = (value) => {
    dispatch(setFilter("ctaCte", value));
  };

  const optionsCtaCte = [
    { value: "todos", label: "Todos" },
    { value: 0, label: "Desactivada" },
    { value: 1, label: "Activada" },
  ];

  return (
    <Grid item xs={12} sm={12} md={2} lg={2}>
      <TextField
        style={{ marginTop: 5 }}
        size="small"
        fullWidth
        select
        label="Cuenta corriente"
        name="ctaCte"
        variant="outlined"
        value={filter}
        onChange={(e) => handleFilter(e.target.value)}
      >
        {optionsCtaCte.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
}
