export const SET_OPEN_FILTERS = "SET_OPEN_FILTERS";
export const SET_FILTER = "SET_FILTER";
export const RESET_FILTERS = "RESET_FILTERS";

export const setOpenFilters = (open) => ({
  type: SET_OPEN_FILTERS,
  payload: open,
});

export const setFilter = (key, value) => ({
  type: SET_FILTER,
  payload: { key, value },
});

export const resetFilters = (key) => ({
  type: RESET_FILTERS,
  payload: key,
});
