import { combineReducers } from "redux";
import clientFilterReducer from "./Clientes/clientFilterReducer";
import clientSearchReducer from "./Clientes/clientSearchReducer";
import PdfReducer from "./PdfReducer";
import clienteHistoryReducer from "./clienteHistoryReducer";
import compraRapidaReducer from "./compraRapidaReducer";
import compraReducer from "./compraReducer";
import configGeneralReducer from "./configGeneralReducer";
import pedidoReducer from "./pedidoReducer";
import presupuestoReducer from "./presupuestoReducer";
import loginReducer from "./reducersLogin";
import UltimasVisitas from "./reducersUltimasVisitas";
import VentaMayorista from "./reducersVentasMayorista";
import remitoReducer from "./remitoReducer";
import sidebarReducer from "./sidebarReducer";
import vinculacionesMELIReducer from "./vinculacionesMELIReducer";
import vinculacionesWooReducer from "./vinculacionesWooReducer";

export default combineReducers({
  loginReducer,
  VentaMayorista,
  UltimasVisitas,
  presupuesto: presupuestoReducer,
  pDF: PdfReducer,
  compra: compraReducer,
  configGeneral: configGeneralReducer,
  pedido: pedidoReducer,
  compraRapida: compraRapidaReducer,
  clienteHistory: clienteHistoryReducer,
  vinculacionesMELIReducer,
  vinculacionesWooReducer,
  remitoReducer,
  sidebar: sidebarReducer,
  clientSearch: clientSearchReducer,
  clientFilter: clientFilterReducer,
});
