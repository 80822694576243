import React, { useState } from "react";
import request from "../../../../../../requests/request";
import {
  errorNotification,
  successNotification,
} from "../../../../../../components/Notifications";
import { getPaymentsManual } from "../../../../../../requests/urls";

export const useValuesAfip = (integracion, closeModal, history) => {
  const [values, setValues] = useState({
    cuitCuil: "",
    cuitCuilOpcional: "",
    clave: "",
    nombre: "",
  });
  const [errors, setErrors] = useState({
    cuitCuil: "",
    clave: "",
    nombre: "",
  });
  const [loadingModal, setLoadingModal] = useState(false);

  const getImportacionesManual = async () => {
    setLoadingModal(true);
    try {
      const response = await request({
        method: "POST",
        url: getPaymentsManual,
      });
      setLoadingModal(false);
    } catch (error) {
      console.error(error);
      setLoadingModal(false);
    }
  };

  const handleSubmit = async () => {
    const newErrors = { ...errors };
    let formIsValid = true;
    if (!values.cuitCuil) {
      newErrors.cuitCuil = "Campo obligatorio";
      formIsValid = false;
    } else {
      newErrors.cuitCuil = "";
    }

    if (!values.clave) {
      newErrors.clave = "Campo obligatorio";
      formIsValid = false;
    } else {
      newErrors.clave = "";
    }

    if (!values.nombre) {
      newErrors.nombre = "Campo obligatorio";
      formIsValid = false;
    } else {
      newErrors.nombre = "";
    }

    setErrors(newErrors);
    if (formIsValid) {
      try {
        const respone = await request({
          method: "POST",
          url: "/api/integraciones/crear-integracion/",
          params: {
            integracion_id: integracion.id,
          },
          data: {
            document: values.cuitCuil,
            document_choice: values.cuitCuilOpcional
              ? values.cuitCuilOpcional
              : null,
            password: values.clave,
            denomination: values.nombre,
          },
        });

        await getImportacionesManual();
        setValues({
          ...values,
          cuitCuil: "",
          cuitCuilOpcional: "",
          clave: "",
          nombre: "",
        });
        successNotification("La vinculación se ha completado exitosamente.");
        window.location.reload();

        closeModal();
      } catch (error) {
        errorNotification(
          "No se pudo realizar la vinculación, corrobore los datos.",
        );
        console.error(error);
      }
    }
  };

  return {
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
    loadingModal,
  };
};
