import React from "react";
import { Container, Divider, Tab, Tabs, Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router";
import Compras from "./index";
import ComprasRapidas from "../ComprasRapidas/index";
import PageTitle from "../../components/common/PageTitle";

export default function JoinComponentsTabs() {
  const history = useHistory();
  const { tab } = useParams();

  const handleOnChangeTab = (_event, value) => {
    history.push(`/compras/${value}`);
  };

  const tabs = [
    { value: "rapida", label: "Compra rápida" },
    { value: "normal", label: "Compra normal" },
  ];

  if (!tabs.map((e) => e.value).includes(tab)) {
    history.replace("/error-404/");
    return null;
  }

  return (
    <Container maxWidth>
      <PageTitle
        title={tab === "normal" ? "Compra normal" : "Compra rápida"}
        className="text-center  text-lg-left page-header py-2"
      />
      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={handleOnChangeTab}
        aria-label="simple tabs example"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Box pb={1}>
        <Divider />
      </Box>
      {tab === "rapida" && <ComprasRapidas />}
      {tab === "normal" && <Compras />}
    </Container>
  );
}
