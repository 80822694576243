import { HeaderToken } from "../../utils/HeaderToken";

/** Types */
export const AGREGAR_DESCRIPCION = "@remito/agregar-descripcion";
export const AGREGAR_ITEM = "@remito/agregar-item";
export const ELIMINAR_ITEM = "@remito/eliminar-item";
export const CAMBIAR_CANTIDAD = "@remito/cambiar-cantidad";
export const ACTUALIZAR_CLIENTE = "@remito/actualizar-cliente";
export const ACTUALIZAR_COMPROBANTE = "@remito/actualizar-comprobante";
export const ACTUALIZAR_PROVINCIA = "@remito/actualizar-provincia";
export const ACTUALIZAR_OBSERVACION = "@remito/actualizar-observacion";
export const LIMPIAR_REMITO = "@remito/limpiar-remito";
export const ACTUALIZAR_OBSERVACIONES = "@remito/actualizar-observaciones";

/**Funcion que agregar una descripcion al carrito del remito */
export const agregarDescripcion = (detalles, nuevaDescripcion) => {
  let copiaDetalle = [...detalles];
  let atributosFijos = {
    codProveedor: null,
    idRepuestoSucursal: null,
    idRepuestoProveedor: null,
    cantidadValida: true,
  };
  let descripcion = {
    ...nuevaDescripcion,
    ...atributosFijos,
  };
  copiaDetalle.push(descripcion);

  return {
    type: AGREGAR_DESCRIPCION,
    payload: { copiaDetalle },
  };
};

/** Funcion que agreagar un nuevo item o articulo al carrito del remito,
 * busca el item en el array, si lo encuentra actualiza la cantidad
 * Si el item no existe, lo agrega al array
 */
export const agregarItem = (detalles, item) => {
  let copiaDetalle = [...detalles];
  // Buscar el index del objecto, segun su idRepuestoProveedor, retorna -1 si no existe
  let index = copiaDetalle.findIndex(
    (element) => element.idRepuestoProveedor === item.idRepuestoProveedor,
  );

  if (index >= 0) {
    copiaDetalle[index].cantidad++;
  }

  if (index === -1) {
    copiaDetalle.push({
      idRepuestoProveedor: item.idRepuestoProveedor,
      descripcion: item.descripcionProveedor,
      codProveedor: item.codProveedor,
      codAuxiliar: item.codAuxiliar,
      codOriginal: item.codOriginal,
      cantidad: 1,
      cantidadValida: true,
      repuesto_sucursal: item.repuesto_sucursal || null,
      observaciones: item.observaciones || [],
      precio: item.precios && item.precios.venta,
    });
  }

  return {
    type: AGREGAR_ITEM,
    payload: { copiaDetalle },
  };
};

/**Funcion encargada de eliminar un objeto del array de detalles del remito,
 * realiza un filtro de todos aquellos objetos que no tengan el index pasado por parámetro
 */
export const eliminarItem = (detalles, index) => {
  let copiaDetalle = [...detalles].filter((value, i, array) => index !== i);

  return {
    type: ELIMINAR_ITEM,
    payload: { copiaDetalle },
  };
};

/**Funcion encargada de reliza el cambio de cantidad en el item,*
 */
export const cambiarCantidad = (detalles, index, event) => {
  const copiaDetalle = [...detalles];
  let repuesto = copiaDetalle[index];
  repuesto.cantidad =
    event.target.value !== "" ? Number(event.target.value) : 1;
  repuesto.cantidadValida = repuesto.cantidad > 0 ? true : false;
  copiaDetalle[index] = repuesto;

  return {
    type: CAMBIAR_CANTIDAD,
    payload: { copiaDetalle },
  };
};

/** Funcion encargada de actualizar el cliente del remito */
export const updateCliente = (cliente) => {
  return {
    type: ACTUALIZAR_CLIENTE,
    payload: cliente,
  };
};

/**Funcion encargada de actualizar el comprobrante seleciconado */
export const updateComprobante = (comprobante) => {
  let newComprob = {
    idTipoComprobante: comprobante ? comprobante.id_tipo_comprobante : null,
    nombre: comprobante ? comprobante.nombre : "",
  };
  return {
    type: ACTUALIZAR_COMPROBANTE,
    payload: newComprob,
  };
};

/**Funcion encargada de actualizar la provincia del remito */
export const updateProvincia = (provincia) => {
  return {
    type: ACTUALIZAR_PROVINCIA,
    payload: provincia,
  };
};

/**Funcion encargada de actualizar la observacion del remito */
export const updateObservacion = (obs) => {
  return {
    type: ACTUALIZAR_OBSERVACION,
    payload: obs,
  };
};

export const clearRemito = (comprobantes) => {
  const provincia = localStorage.getItem("provinciaDefault");
  const comprobSelected = comprobantes.filter((c) => c.nombre === "Remito")[0];

  return {
    type: LIMPIAR_REMITO,
    payload: {
      detalles: [],
      cliente: null,
      provincia: provincia || null,
      tipoComprobante: {
        idTipoComprobante: comprobSelected
          ? comprobSelected.id_tipo_comprobante
          : null,
        nombre: comprobSelected ? comprobSelected.nombre : "",
      },
      observacion: "",
    },
  };
};

export const actualizarObservaciones = (detalles, articulo, observaciones) => {
  const copiaDetalle = [...detalles];

  let index = copiaDetalle.findIndex(
    (element) => element.idRepuestoProveedor === articulo.idRepuestoProveedor,
  );

  copiaDetalle[index].observaciones = observaciones;

  return {
    type: ACTUALIZAR_OBSERVACIONES,
    payload: copiaDetalle,
  };
};
