import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";

export default function DataClientA4({ conciliacion }) {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 50 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
              <Text style={styles.text2Title}>Domicilio:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text2}>
                {conciliacion.cliente_sucursal.razon_social || "---"}
              </Text>
              <Text style={styles.text2}>
                {conciliacion.cliente_sucursal.domicilio || "---"}
              </Text>
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 80 })}>
              <Text style={styles.text2Title}>DNI/CUIT:</Text>
              <Text style={styles.text2Title}>Condición de IVA:</Text>
              <Text style={styles.text2Title}>Condición de pago:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 120 })}>
              <Text style={styles.text2}>
                {conciliacion.cliente_sucursal.cuit_dni || "---"}
              </Text>
              <Text style={styles.text2}>
                {conciliacion.cliente_sucursal.estado_tributario || "---"}
              </Text>
              <Text style={styles.text2}>Cuenta corriente</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
