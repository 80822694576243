import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";

export default function FilterButtons({
  getClients,
  resetFilters,
  resetClient,
}) {
  const dispatch = useDispatch();

  const handleReset = async () => {
    await dispatch(resetFilters());
    await dispatch(resetClient());
    getClients();
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} spacing={2}>
      <Grid container spacing={2} className="justify-xs-center">
        <Grid item>
          <Button
            onClick={getClients}
            variant="contained"
            color="primary"
            disableElevation
          >
            CONSULTAR
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleReset}
            variant="contained"
            color="tertiary"
            disableElevation
          >
            LIMPIAR FILTROS
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
