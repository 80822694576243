import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
import "./assets/shards-dashboards.1.1.0.min.css";
import "./styles.css";

import ReactNotification from "react-notifications-component";
import { connect } from "react-redux";
import { MeliContextProvider } from "./context/MeliContext";
import { isVendedor } from "./data/perfil";
import validarToken from "./hooks/validarToken";
import {
  getPerfilUsuario,
  setSesionByToken,
  setTokenToState,
} from "./Redux/Actions";
import { getConfigGeneral } from "./Redux/Actions/actionConfigGeneral";
import { activateLogRocket } from "./services/logRocket";
import Errors from "./views/Errors";
import Login from "./views/Login";

const mapStateToProps = (state) => ({
  tokenRedux: state.loginReducer.token,
  perfil: state.loginReducer.perfil,
  meli_integracion: state.configGeneral.configGeneral.meli_integracion,
  woocommerce_integracion:
    state.configGeneral.configGeneral.woocommerce_integracion,
  correctSession: state.loginReducer.correctSession,
  integracion_afip: state.configGeneral.configGeneral.integracion_afip,
});

const mapDispatchToProps = (dispatch) => {
  return {
    isAuth: (value) => dispatch(setTokenToState(value)),
    setConfiguracionGeneral: () => dispatch(getConfigGeneral()),
    setPerfil: () => dispatch(getPerfilUsuario()),
    setSesionByToken: (value) => dispatch(setSesionByToken(value)),
  };
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      enabled_logRocket: false,
    };
  }

  async componentDidMount() {
    //Obtenemos el token almacenado en local storage
    const tokenLocal = localStorage.getItem("token");
    //Si existe el token en local storage, lo seteamos en redux
    if (tokenLocal != null) {
      //Funcion que setea el token en redux
      this.props.isAuth(tokenLocal);
    }
  }

  render() {
    const tokenLocal = localStorage.getItem("token");

    const empleado = JSON.parse(localStorage.getItem("empleado"));

    const { tokenRedux, correctSession, perfil } = this.props;
    tokenLocal &&
      tokenRedux &&
      correctSession &&
      this.props.setConfiguracionGeneral();
    tokenLocal && tokenRedux && correctSession && this.props.setPerfil();
    /**Funcion encargada de detectar modificaciones en el local storage
     * realizadas por el usuario del navegador, en caso de detectar un modificacion
     * borra todo el local storage.
     */
    // window.addEventListener("storage", function(e) {
    //   localStorage.removeItem("token");
    //   localStorage.removeItem("empleado");
    //   window.location.reload("/");
    // })

    if (perfil && tokenLocal && tokenRedux && !this.state.enabled_logRocket) {
      activateLogRocket(perfil, this.state.enabled_logRocket).then(
        (response) => {
          if (response) {
            this.setState({
              enabled_logRocket: true,
            });
          }
        },
      );
    }

    const handleLogin = (tokenRedux) => {
      //Si no hay token en el navegador o en el redux o la sesion es incorrecta, redireccionamos al login
      if (tokenRedux === null || tokenLocal === null) {
        return <Login />;
      } else {
        return (
          <React.Fragment>
            <MeliContextProvider>
              <Router basename={process.env.REACT_APP_BASENAME || ""}>
                <div>
                  {ReactDOM.createPortal(
                    <ReactNotification />,
                    document.getElementById("notificaciones"),
                  )}
                  <Switch>
                    {routes(
                      isVendedor(this.props.perfil),
                      this.props.meli_integracion,
                      this.props.woocommerce_integracion,
                      this.props.integracion_afip,
                      empleado.permisos,
                    ).map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                          component={withTracker((props) => {
                            return (
                              <route.layout {...props}>
                                <route.component
                                  {...props}
                                  validarToken={validarToken}
                                />
                              </route.layout>
                            );
                          })}
                        />
                      );
                    })}
                    <Route component={Errors} />
                  </Switch>
                </div>
              </Router>
            </MeliContextProvider>
          </React.Fragment>
        );
      }
    };
    return <div>{handleLogin(tokenRedux)}</div>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
