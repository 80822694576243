import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";
import { textAbbreviation } from "../../DeVentas/Components/utils";

export default function TotalesTicket({ data }) {
  const isGreaterThanOrEqualToOneMillion = Number(data.montoTotal) > 1000000;

  const tipoComprobante = data.tipoComprobante
    ? data.tipoComprobante.nombre
    : "";

  const getPorcentajeDescuento = () => {
    if (data.descuento) {
      return " (" + data.descuento.porcentaje * 100 + "%)";
    }
    return "";
  };

  const addTextEmpty = (text) => {
    let value = false;
    let newText = textAbbreviation(text);

    if (
      (isGreaterThanOrEqualToOneMillion && newText.length > 19) ||
      (!isGreaterThanOrEqualToOneMillion && newText.length > 22)
    ) {
      value = true;
    }
    return value;
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
      }}
    >
      <View
        style={[
          styles.containerColumn,
          { width: isGreaterThanOrEqualToOneMillion ? "75%" : "82%" },
        ]}
      >
        <View>
          <Text style={styles.textTotalesFactura}>{"SUBTOTAL"}</Text>
          <Text style={styles.textTotalesFactura}>
            {"DESCUENTO" + getPorcentajeDescuento()}
          </Text>

          <Text style={styles.textTotalesFactura}>{"RECARGO"}</Text>

          {(tipoComprobante === "A" ||
            tipoComprobante === "M" ||
            tipoComprobante === "FCEM A") && (
            <Text style={styles.textTotalesFactura}>{`NETO GRAVADO`}</Text>
          )}
          {(tipoComprobante === "A" ||
            tipoComprobante === "M" ||
            tipoComprobante === "FCEM A") && (
            <Text style={styles.textTotalesFactura}>{`IVA 21 %`}</Text>
          )}
          {data.impuestos_aplicados &&
            (tipoComprobante === "A" ||
              tipoComprobante === "M" ||
              tipoComprobante === "FCEM A") &&
            data.impuestos_aplicados.map((tax, index) => (
              <Text key={index} style={styles.textTotalesFacturaAlignLeft}>
                {`${textAbbreviation(tax.description).toUpperCase()} - ${Number(
                  tax.aliquot_percentage,
                )}%`}
              </Text>
            ))}
        </View>

        <Text style={styles.textTotalesFactura}>TOTAL</Text>
      </View>
      <View style={styles.containerColumn2}>
        <View>
          <Text style={styles.textTotalesFactura}>
            {parseCurrency(data.montoImporte)}
          </Text>
          <Text style={styles.textTotalesFactura}>
            {parseCurrency(
              data.descuento && data.montoDescuento > 0
                ? data.montoDescuento
                : 0,
            )}
          </Text>
          <Text style={styles.textTotalesFactura}>
            {parseCurrency(data.montoInteres > 0 ? data.montoInteres : 0)}
          </Text>
          {(tipoComprobante === "A" ||
            tipoComprobante === "M" ||
            tipoComprobante === "FCEM A") && (
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(data.montoSubtotal)}
            </Text>
          )}

          {(tipoComprobante === "A" ||
            tipoComprobante === "M" ||
            tipoComprobante === "FCEM A") && (
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(data.montoIVA)}
            </Text>
          )}

          {data.impuestos_aplicados &&
            (tipoComprobante === "A" ||
              tipoComprobante === "M" ||
              tipoComprobante === "FCEM A") &&
            data.impuestos_aplicados.map((tax, index) => (
              <Fragment key={index}>
                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(Number(tax.amount))}
                </Text>
                {addTextEmpty(tax.description) ? (
                  <Text
                    style={[styles.textTotalesFactura, { paddingTop: 6 }]}
                  ></Text>
                ) : null}
              </Fragment>
            ))}
        </View>

        <Text style={styles.textTotalesFactura}>
          {parseCurrency(data.montoTotal)}
        </Text>
      </View>
    </View>
  );
}
