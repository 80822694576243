export const SET_SEARCH = "SET_SEARCH";
export const SET_SEARCH_METHOD = "SET_SEARCH_METHOD";
export const SET_SELECTED_CLIENT_DATA = "SET_SELECTED_CLIENT_DATA";
export const RESET_SELECTED_CLIENT_DATA = "RESET_SELECTED_CLIENT_DATA";

export const setSearch = (value) => ({
  type: SET_SEARCH,
  payload: value,
});

export const setSearchMethod = (value) => ({
  type: SET_SEARCH_METHOD,
  payload: value,
});

export const setSelectedClientData = (clientData) => ({
  type: SET_SELECTED_CLIENT_DATA,
  payload: clientData,
});

export const resetSelectedClientData = () => ({
  type: RESET_SELECTED_CLIENT_DATA,
});
