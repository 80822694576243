import {
  ACTUALIZAR_CLIENTE,
  ACTUALIZAR_COMPROBANTE,
  ACTUALIZAR_PROVINCIA,
  AGREGAR_ITEM,
  AGREGAR_DESCRIPCION,
  CAMBIAR_CANTIDAD,
  ELIMINAR_ITEM,
  ACTUALIZAR_OBSERVACION,
  LIMPIAR_REMITO,
  ACTUALIZAR_OBSERVACIONES,
} from "../Actions/remitoActions";
import { updateObject } from "../utility";

const initialState = {
  detalles: [],
  cliente: null,
  provincia: null,
  tipoComprobante: null,
  observacion: "",
};

const updateDetalles = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.copiaDetalle,
  });
};

const updateCliente = (state, action) => {
  return updateObject(state, { cliente: action.payload });
};

const updateComprobante = (state, action) => {
  return updateObject(state, { tipoComprobante: action.payload });
};

const updateProvincia = (state, action) => {
  return updateObject(state, { provincia: action.payload });
};

const updateObservacion = (state, action) => {
  return updateObject(state, { observacion: action.payload });
};

const clearRemito = (state, action) => {
  return updateObject(state, {
    detalles: action.payload.detalles,
    cliente: action.payload.cliente,
    provincia: action.payload.provincia,
    tipoComprobante: action.payload.tipoComprobante,
    observacion: action.payload.observacion,
  });
};

const updateObservacionesDetalle = (state, action) => {
  return updateObject(state, {
    detalles: action.payload,
  });
};

const remitoReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGREGAR_DESCRIPCION:
      return updateDetalles(state, action);
    case AGREGAR_ITEM:
      return updateDetalles(state, action);
    case ELIMINAR_ITEM:
      return updateDetalles(state, action);
    case CAMBIAR_CANTIDAD:
      return updateDetalles(state, action);
    case ACTUALIZAR_CLIENTE:
      return updateCliente(state, action);
    case ACTUALIZAR_COMPROBANTE:
      return updateComprobante(state, action);
    case ACTUALIZAR_PROVINCIA:
      return updateProvincia(state, action);
    case ACTUALIZAR_OBSERVACION:
      return updateObservacion(state, action);
    case LIMPIAR_REMITO:
      return clearRemito(state, action);
    case ACTUALIZAR_OBSERVACIONES:
      return updateObservacionesDetalle(state, action);
    default:
      return state;
  }
};

export default remitoReducer;
