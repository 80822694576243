import React from "react";
import { List, ListItemText, ListItem, Card, Grid } from "@material-ui/core";

export default function MontosTotales({ newMontos }) {
  const data = [
    {
      title: "SUBTOTAL",
      value: Number(
        (Number(newMontos.subtotal) - Number(newMontos.descuento)).toFixed(2),
      ),
      size: 2,
      color: "#1465bb",
      show: true,
    },
    {
      title: "DESCUENTO",
      value: Number(Number(newMontos.descuentoUnico).toFixed(2)),
      size: 2,
      color: "#ff6c3e",
      show: true,
    },
    {
      title: "TOTAL",
      value: Number(
        Number.isInteger(
          newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico,
        )
          ? newMontos.subtotal - newMontos.descuento - newMontos.descuentoUnico
          : (
              newMontos.subtotal -
              newMontos.descuento -
              newMontos.descuentoUnico
            ).toFixed(2),
      ),
      size: 2,
      color: "#1465bb",
      show: true,
    },
  ];

  return (
    <Grid container spacing={2} style={{ justifyContent: "center" }}>
      {data.map(
        (d) =>
          d.show && (
            <Grid item xs={d.size}>
              <Card style={{ height: "100%" }}>
                <List
                  className="p-0"
                  style={{
                    textAlign: "center",
                    color: "#5e5f61",
                  }}
                >
                  <ListItem
                    className="p-0"
                    style={{ borderBottom: "solid 1px #dcdcdc" }}
                  >
                    <ListItemText
                      className="m-0"
                      style={{ textAlign: "center" }}
                    >
                      <span style={{ fontSize: 14, fontWeight: "bold" }}>
                        {d.title}
                      </span>
                    </ListItemText>
                  </ListItem>
                  <ListItem className="p-0">
                    <ListItemText
                      className="m-0"
                      style={{
                        borderRadius: ".30rem",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 20,
                          color: d.color,
                          fontWeight: "bold",
                        }}
                      >
                        {`$ ${parseFloat(d.value).toLocaleString("es-AR")}`}
                      </span>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
            </Grid>
          ),
      )}
    </Grid>
  );
}
