import { Collapse, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import useDisclosure from "../../../customHooks/useDisclosure";
import CloseIcon from "@material-ui/icons/Close";
import { VIDEO_EXPLICACION_CONCILIACIONES } from "./utils";

export default function AlertPasosConciliacion() {
  const [opened, { toggle, open, close }] = useDisclosure(true);
  return (
    <Collapse in={opened}>
      <Alert
        severity="success"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={close}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        icon={`🎉`}
      >
        <span style={{ fontWeight: "bolder" }}>
          Ahora podés cargar saldo y conciliar ventas en un solo paso. Conocé
          como haciendo click{" "}
          <a
            style={{
              fontWeight: "bolder",
              textDecoration: "underline",
              cursor: "pointer",
              color: "inherit",
            }}
            href={VIDEO_EXPLICACION_CONCILIACIONES}
            target="_blank"
          >
            acá
          </a>
        </span>
        <br />
        Paso 1: Seleccioná los comprobantes que querés conciliar.
        <br />
        Paso 2: Hacé click en el botón "Conciliar Facturas".
      </Alert>
    </Collapse>
  );
}
