import React, { useEffect, useState } from "react";
import { makeStyles, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import MaskedInput from "react-text-mask";
import {
  eliminarItem,
  cambiarCantidad,
  actualizarObservaciones,
} from "../../../../Redux/Actions/remitoActions";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import ModalObservacionarticulo from "../../../Proveedores/Catalogo/Modales/ModalObservacionArticulo";
import ModalPedido from "../../../Presupuesto/ListaArticulos/Components/ModalPedido";
import LocationIcon from "../../../../components/ait-reusable/LocationIcon";
import { handleSubmitObservaciones, handlePostPedido } from "./utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  fontSizeOptions: {
    fontSize: 18,
  },
}));

export default function BodyTable({}) {
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [modalObservacion, setModalObservacion] = useState(false);
  const [openModalPedido, setOpenModlaPedido] = useState(false);
  const [articuloModal, setArticuloModal] = useState(null);
  const [pedidosAbiertos, setPedidosAbiertos] = useState([]);
  const { detalles } = useSelector((store) => store.remitoReducer);
  const { configGeneral } = useSelector((state) => state.configGeneral);

  const dispatch = useDispatch();

  const handleOpenObservaciones = (articulo) => {
    setArticuloModal(articulo);
    setModalObservacion(true);
  };

  const handleShowCodigo = (articulo) => {
    if (configGeneral.codigo_comprobante === "proveedor") {
      return articulo.codProveedor
        ? articulo.codProveedor.toUpperCase() + " " + "-" + " "
        : "";
    } else if (configGeneral.codigo_comprobante === "original") {
      return articulo.codOriginal
        ? articulo.codOriginal.toUpperCase() + " " + "-" + " "
        : "";
    } else if (configGeneral.codigo_comprobante === "auxiliar") {
      return articulo.codAuxiliar
        ? articulo.codAuxiliar.toUpperCase() + " " + "-" + " "
        : "";
    }
  };

  const handleOpenModalPedido = (articulo) => {
    handlePostPedido(
      articulo,
      setOpenBackdrop,
      setPedidosAbiertos,
      setOpenModlaPedido,
    );
    setArticuloModal(articulo);
  };

  const handleCloseModalPedido = () => {
    setOpenModlaPedido(false);
    setArticuloModal(null);
  };

  return (
    <TableBody>
      {detalles.map((articulo, index) => (
        <TableRow key={index} index={index}>
          <TableCell
            align="center"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <i
              className="material-icons borrar-producto"
              style={{ margin: 0, cursor: "pointer", fontSize: 20 }}
              title="Eliminar"
              onClick={() => dispatch(eliminarItem(detalles, index))}
            >
              delete
            </i>
            {articulo.idRepuestoProveedor && (
              <i
                className="material-icons borrar-producto"
                style={{
                  margin: 0,
                  cursor: "pointer",
                  fontSize: 20,
                  opacity:
                    articulo.observaciones && articulo.observaciones.length
                      ? "1"
                      : "0.5",
                }}
                title="Eliminar"
                onClick={() => handleOpenObservaciones(articulo)}
              >
                visibility
              </i>
            )}
            {articulo.idRepuestoProveedor && (
              <i
                title="Agregar a pedido"
                onClick={() => handleOpenModalPedido(articulo)}
                className="material-icons"
                style={{ margin: "0 5px", cursor: "pointer", fontSize: 20 }}
              >
                local_shipping
              </i>
            )}
            <LocationIcon articulo={articulo} styles={{ fontSize: 20 }} />
          </TableCell>
          <TableCell
            style={{
              maxWidth: 300,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={articulo.descripcion.toUpperCase()}
          >
            <span className={classes.fontSizeOptions}>
              {handleShowCodigo(articulo)}
              {articulo.descripcion.toUpperCase()}
              {articulo.idRepuestoProveedor &&
              articulo.observaciones.length > 0 ? (
                <p style={{ fontSize: "0.8rem", opacity: "0.5" }}>
                  {articulo.observaciones[0].substr(0, 40).trim() +
                    (articulo.observaciones[0].length > 40 ? "..." : "")}
                </p>
              ) : null}
            </span>
          </TableCell>
          <TableCell align="center">
            <MaskedInput
              className="text-center form-control"
              guide={false}
              mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
              onFocus={(event) => event.target.select()}
              onChange={(e) => {
                dispatch(cambiarCantidad(detalles, index, e));
              }}
              value={articulo.cantidad}
              invalid={!articulo.cantidadValida}
              name="cantidad"
            />
            {!articulo.cantidadValida && (
              <span style={{ color: "#f44336" }}>Cantidad inválida</span>
            )}
          </TableCell>
        </TableRow>
      ))}

      <CircularBackdrop openBackdrop={openBackdrop} />

      {modalObservacion && articuloModal && (
        <ModalObservacionarticulo
          open={modalObservacion}
          obsArticulo={articuloModal.observaciones}
          handleClose={() => {
            setModalObservacion(false);
            setArticuloModal(null);
          }}
          onSubmitObservaciones={(obs) =>
            handleSubmitObservaciones(
              obs,
              articuloModal,
              setModalObservacion,
              setArticuloModal,
              detalles,
              actualizarObservaciones,
              dispatch,
            )
          }
        />
      )}

      {openModalPedido && (
        <ModalPedido
          open={openModalPedido}
          close={handleCloseModalPedido}
          articuloSeleccionado={articuloModal}
          pedidosAbiertos={pedidosAbiertos}
        />
      )}
    </TableBody>
  );
}
