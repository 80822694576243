import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";

export default function TotalesFA4({ conciliacion }) {
  const totalSaldado = conciliacion.ventas_saldadas.reduce(
    (acc, venta) => acc + Number(venta.monto_saldado),
    0,
  );
  const totalSobrante = Number(
    (conciliacion.importe_total - totalSaldado).toFixed(2),
  );

  return (
    <View>
      <View
        style={{
          marginRight: 0,
          marginLeft: 0,
          color: "#1B71BA",
          fontWeight: "bolder",
        }}
      >
        <View style={styles.containerColumn}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 8,
              marginBottom: 2,
              justifyContent: "flex-end",
            }}
          >
            <View style={(styles.containerColumn, { width: 200 })}>
              <Text style={styles.textTotalesFactura}>{"IMPORTE SALDADO"}</Text>
            </View>
            <View style={(styles.containerColumn, { width: 70 })}>
              <Text style={styles.textTotalesFactura}>
                {`${parseCurrency(Number(totalSaldado))}`}
              </Text>
            </View>
          </View>

          {totalSobrante > 0 && (
            <View
              style={{
                flexDirection: "row",
                paddingTop: 0,
                marginBottom: 2,
                justifyContent: "flex-end",
              }}
            >
              <View style={(styles.containerColumn, { width: 200 })}>
                <Text style={styles.textTotalesFactura}>
                  {"SOBRANTE A BILLETERA"}
                </Text>
              </View>
              <View style={(styles.containerColumn, { width: 70 })}>
                <Text style={styles.textTotalesFactura}>
                  {`${parseCurrency(Number(totalSobrante))}`}
                </Text>
              </View>
            </View>
          )}

          <View
            style={{
              flexDirection: "row",
              paddingTop: 0,
              marginBottom: 2,
              justifyContent: "flex-end",
            }}
          >
            <View style={(styles.containerColumn, { width: 200 })}>
              <Text style={styles.textTotalesFactura}>{"IMPORTE TOTAL"}</Text>
            </View>
            <View style={(styles.containerColumn, { width: 70 })}>
              <Text style={styles.textTotalesFactura}>
                {`${parseCurrency(Number(conciliacion.importe_total))}`}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
