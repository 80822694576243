import React from "react";
import { View, Text } from "@react-pdf/renderer";

export default function NoValidoFactura() {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          flexDirection: "column",
          padding: 10,
          width: 60,
          height: 60,
          backgroundColor: "#EAEAE0",
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 24 }}>{"X"}</Text>
        <Text style={{ textAlign: "center", fontSize: 5 }}>
          NO VÁLIDO COMO FACTURA
        </Text>
      </View>
    </View>
  );
}
