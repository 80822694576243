import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDebounce } from "../../../../customHooks/useDebounce";
import request from "../../../../requests/request";
import { readClientesVenta } from "../../../../requests/urls";

const conFObject = {
  cliente: { razonSocial: "Consumidor Final" },
};

export default function Cliente({ loading, cliente, handleChangeCliente }) {
  const [autocompleteCliente, setAutocompleteCliente] = useState(conFObject);
  const [clientes, setClientes] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const debouncedDescripcion = useDebounce(descripcion, 300);

  useEffect(() => {
    handleGetClientes();
  }, [debouncedDescripcion]);

  /** useEffect encargado de setear el objecto de cliente consumidor final, en caso de que el
   * value del atributo cliente sea null de lo contrario setea el cliente en el hook
   */
  useEffect(() => {
    !cliente && setAutocompleteCliente(conFObject);
    cliente && setAutocompleteCliente(cliente);
  }, [cliente]);

  /**Funcion encagada del GET de los clientes segun la descripcion */
  const handleGetClientes = async () => {
    try {
      const response = await request({
        method: "GET",
        url: readClientesVenta,
        params: {
          q: descripcion,
          blank: descripcion === "" ? 1 : 0,
          cant_items: 15,
        },
      });
      handleResponseGetClientes(response);
    } catch (error) {}
  };

  const handleResponseGetClientes = (response) => {
    const { data, err_code, err_messege, status } = response.data;
    {
      err_code === 204 && status === 200 && setClientes([]);
    }
    {
      err_code === 200 &&
        status === 200 &&
        setClientes(data.clientesDeSucursal);
    }
  };

  return (
    <Grid item xs={5}>
      <Autocomplete
        fullWidth
        loading={loading}
        value={autocompleteCliente}
        options={clientes}
        getOptionSelected={(cliente) => cliente.cliente.razonSocial}
        getOptionLabel={(cliente) =>
          cliente.cliente.CUIT || cliente.cliente.numeroDocumento
            ? cliente.cliente.razonSocial +
              " - " +
              `${cliente.cliente.CUIT || cliente.cliente.numeroDocumento}`
            : cliente.cliente.razonSocial
        }
        onFocus={(event) => event.target.select()}
        noOptionsText={"No se encontraron resultados"}
        onInputChange={(event, value, reason) => setDescripcion(value)}
        onChange={(event, value, reason) => handleChangeCliente(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            style={{ backgroundColor: "white" }}
            label="Nombre o DNI"
            variant="outlined"
          />
        )}
        name="buscador_clientes"
      />
    </Grid>
  );
}
