import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { areAllSameType, formatFacturas } from "../util";
import HeaderTable from "./HeaderTable";
import TablaComprobantes from "./TablaComprobantes";
import { useSeleccionCompras } from "../useSeleccionCompras";

const TableComprobantesAdeudados = (props) => {
  const [newFacturas, setNewFacturas] = useState([]);
  const { useSeleccion } = useSeleccionCompras();
  const { handleSelectOne } = useSeleccion;

  useEffect(() => {
    setNewFacturas(props.facturas);
  }, [props.facturas]);

  const handlePageChange = (e) => {
    props.setPagination({ ...props.pagination, actualPage: e });
  };
  const columns = [
    <div
      style={{
        display: "flex",
        paddingLeft: "1em",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Checkbox
        style={{ color: "white" }}
        onClick={props.handleSelectAll}
        checked={props.handleIsAllChecked()}
        disabled={
          !areAllSameType(
            props.selection,
            newFacturas,
            props.proveedor && props.proveedor.calcular_retencion,
          )
        }
      />
      <TooltipMoreInfoMaterial
        position={"right"}
        titleTooltip={
          props.proveedor && props.proveedor.calcular_retencion
            ? "Solo se pueden seleccionar los comprobantes del mismo tipo."
            : "Seleccionar todos los comprobantes"
        }
      />
    </div>,
    "Tipo de comprobante",
    "Nº de comprobante",
    "Fecha de facturación",
    "Días a vencer",
    "Total",
    "Deuda restante",
    "Saldo abonado",
    "--",
  ];

  const isCollapse = (index) => {
    /**
     * Función que determina si una factura es una fila adicional de un comprobante con plazos de pago.
     * El valor retornado se utiliza para determinar de que color va a ser la fila de la tabla.
     */
    return newFacturas[index].plazos_pago ? true : false;
  };

  const mostrarFilasPlazo = (index) => {
    let fila = newFacturas[index];
    return !fila.isPlazoPago || (fila.isPlazoPago && fila.showPlazoPago);
  };

  const openCloseCollapse = (index, event) => {
    /**
     * Función que se encarga de mostrar u ocultar las filas adicionales de los comprobantes con plazos de pago.
     */
    if (event.target.type !== "checkbox") {
      let copyFacturas = [...newFacturas];
      let item = copyFacturas[index];
      if (!item.isPlazoPago && item.plazos_pago) {
        copyFacturas.forEach((f) => {
          if (f.id === item.id && f.isPlazoPago) {
            f.showPlazoPago = !f.showPlazoPago;
          }
        });
        setNewFacturas(copyFacturas);
      }
    }
  };

  const getStyleCursor = (index) => {
    let item = newFacturas[index];
    return !item.isPlazoPago && item.plazos_pago ? "pointer" : "default";
  };

  const applyBorder = (index) => {
    let item = newFacturas[index];
    return item.showBorder;
  };

  return (
    <Card>
      <CardContent>
        <TablaComprobantes
          columns={columns}
          items={formatFacturas(
            newFacturas,
            props.selection,
            props.setSelection,
            props.toggleModalDetalles,
            props.toggleModalAnulacion,
            props.handleGetExcelCompra,
            props.proveedor,
            props.moduloComprasActivo,
            setNewFacturas,
            handleSelectOne,
          )}
          loading={props.loading}
          noResults={!newFacturas}
          colorRow={{
            colorCollapse: "#ebe9fb",
            colorNotCollapse: "#ffffff",
            isCollapse: isCollapse,
          }}
          mostrarFilasPlazo={mostrarFilasPlazo}
          handleClickRow={openCloseCollapse}
          getStyleCursor={getStyleCursor}
          applyBorder={applyBorder}
        >
          <HeaderTable
            selection={props.selection}
            facturas={newFacturas}
            cantidadComprobantes={props.cantidadComprobantes}
            handleExportarExcel={props.handleExportarExcel}
            dataFilters={props.dataCompras.dataFilters}
          />
        </TablaComprobantes>
        {!props.loading && newFacturas.length !== 0 && (
          <CardActions>
            <Grid
              container
              spacing={2}
              className="d-flex mt-3 mb-0 justify-content-center"
            >
              <Pagination
                activePage={props.pagination.actualPage}
                itemsCountPerPage={10}
                totalItemsCount={props.pagination.num_items}
                pageRangeDisplayed={6}
                onChange={(e) => handlePageChange(e)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Grid>
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
};

export default TableComprobantesAdeudados;
